import { useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'

import { Snackbar } from '@components/Snackbar'

import { ConfigField } from '@gantt/components/GanttCreateOrEdit'
import { getInvolvedPaths } from '@gantt/helpers'

import { getAvailableVariablesObject } from '../../helpers'

import { VariableItem } from './VariableItem'

type TimelineAdditionalFieldsProps = {
  barIndex: number
}

export const TimelineAdditionalFields = ({ barIndex }: TimelineAdditionalFieldsProps) => {
  const { control, watch } = useFormContext()
  const {
    fields,
    append,
    remove: handleRemoveItem,
  } = useFieldArray({
    name: `timeline.${barIndex}.additionalFields`,
    control,
  })
  const { t } = useTranslation()

  const timelineData = watch(`timeline.${barIndex}`)
  const watchVariablesList = watch(`timeline.${barIndex}.additionalFields`)

  const [isShownCopyAlert, setIsShownCopyAlert] = useState(false)

  const availableVariablesObject: Record<string, ConfigField> = useMemo(
    () => getAvailableVariablesObject(timelineData),
    [watch()]
  )

  const watchedObject = watch(`timeline.${barIndex}.data`)
  const watchedBar = watch(`timeline.${barIndex}`)

  const handleAddItem = () => {
    append({})
  }

  async function handleWriteClipboardText(text: string) {
    if (navigator?.clipboard) {
      await navigator.clipboard.writeText(text)
      setIsShownCopyAlert(true)
    }
  }

  const handleCloseAlert = () => {
    setIsShownCopyAlert(false)
  }

  const selectedPaths = useMemo(() => {
    return (watchVariablesList as ConfigField[])
      ?.filter(item => item.pathStr)
      .map(field => field.pathStr)
  }, [watchVariablesList])

  const exisingPaths = useMemo(() => {
    return getInvolvedPaths(watchedBar)
  }, [watchedBar])

  return (
    <>
      <Snackbar
        autoHideDuration={1500}
        message={t('ganttCreate.timelineForm.additionalFieldsForm.copyAlert')}
        open={isShownCopyAlert}
        type='success'
        onClose={handleCloseAlert}
      />
      <Grid container justifyContent={'end'} xs={12}>
        <Grid item>
          <Button
            disabled={!watchedObject?.id}
            startIcon={<Add fontSize='small' />}
            onClick={handleAddItem}
          >
            {t('ganttCreate.common.addVariable')}
          </Button>
        </Grid>
      </Grid>
      <Grid container columnSpacing={4} xs={12}>
        <Grid item xs={3.5}>
          <Typography variant='subtitle1'>
            {t('ganttCreate.timelineForm.additionalFieldsForm.variableHeader')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle1'>
            {t('ganttCreate.timelineForm.additionalFieldsForm.pathHeader')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle1'>
            {t('ganttCreate.timelineForm.additionalFieldsForm.comment')}
          </Typography>
        </Grid>
      </Grid>
      {fields.length === 0 ? (
        <Box
          alignItems='center'
          display='flex'
          height={'100%'}
          justifyContent='center'
          minHeight='100px'
          sx={{ userSelect: 'none' }}
        >
          <Typography>{t('ganttCreate.timelineForm.additionalFieldsForm.noFieldItems')}</Typography>
        </Box>
      ) : (
        fields.map((field, index) => {
          return (
            <VariableItem
              key={field.id}
              hasCopyButton
              controllerNameComment={`timeline.${barIndex}.additionalFields.${index}.comment`}
              controllerNameField={`timeline.${barIndex}.additionalFields.${index}.field`}
              controllerNamePathArray={`timeline.${barIndex}.additionalFields.${index}.pathArray`}
              controllerNamePathStr={`timeline.${barIndex}.additionalFields.${index}.pathStr`}
              existingPaths={exisingPaths}
              field={field}
              index={index}
              selectedPaths={selectedPaths}
              watchValue={watch(`timeline.${barIndex}.additionalFields.${index}`)}
              watchedObject={watchedObject}
              onRemove={handleRemoveItem}
            />
          )
        })
      )}
      {fields.length >= 5 && (
        <Grid container justifyContent={'end'} xs={12}>
          <Grid item display='flex'>
            <Button
              disabled={!watchedObject?.id}
              startIcon={<Add fontSize='small' />}
              onClick={handleAddItem}
            >
              {t('ganttCreate.common.addVariable')}
            </Button>
          </Grid>
        </Grid>
      )}
      <Box mb={1} mr={1}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t('ganttCreate.timelineForm.additionalFieldsForm.availableVariables')}
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(availableVariablesObject).map(([key, value]) => (
              <Grid key={key} container columnSpacing={4} sx={{ cursor: 'copy' }} xs={12}>
                <Grid item xs={5.5} onClick={() => handleWriteClipboardText(key)}>
                  {key}
                </Grid>
                <Grid item xs={6} onClick={() => handleWriteClipboardText(key)}>
                  {value?.pathStr}
                </Grid>
                <Grid item xs={0.5}>
                  <IconButton onClick={() => handleWriteClipboardText(key)}>
                    <ContentCopyIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}
