import { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { ConfigRowsType, CopyModelDataType, DataOfCopiedModelType } from 'src/types'
import { GridSortModel } from '@mui/x-data-grid'

import { useCopyFormMutation } from '@redux/api'

import { useSorting } from '@hooks'
import { ROUTES } from '@constants'

import { deleteFormByCodeType, deleteFormType } from '../types'

type useHandlersParams = {
  deleteForm: deleteFormType
  deleteFormByCode: deleteFormByCodeType
}

export const useHandlers = ({ deleteForm, deleteFormByCode }: useHandlersParams) => {
  const navigate = useNavigate()

  const { sort, setSort } = useSorting()
  const [currentSort, setCurrentSort] = useState<GridSortModel>(sort || [])
  const [objectCode, setObjectCode] = useState<string>()
  const [searchPattern, setSearchPattern] = useState<string>()
  const [currentTab, setCurrentTab] = useState(0)
  const [isDirty, setDirty] = useState(false)
  const [showCopyDialog, setShowCopyDialog] = useState(false)
  const [copyFormData, setCopyFormData] = useState<DataOfCopiedModelType | null>(null)
  const [copyForm, { isLoading: isLoadingCopyForm }] = useCopyFormMutation()

  const handleDeleteForm = (id: string | number) => deleteForm(id)

  // TODO привести удаление к одному методу
  const handleDeleteFormByCode = (code: string) => deleteFormByCode(code)

  const handleEditForm = (code: string) => {
    navigate(`${ROUTES.CONFIG_FORMS_EDIT}/${code}`)
  }

  const handleSetSorting = (value: GridSortModel | null) => {
    setSort(value ? `${value[0].field},${value[0].sort}` : undefined)
    setCurrentSort(value ? value : [])
  }

  const handleSetObjectCode = (code: string) => setObjectCode(code)

  const handleSetSearchPattern = (pattern: string) => {
    setSearchPattern(pattern)
  }

  const handleSetDirty = (value: boolean) => setDirty(value)

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    if (isDirty && confirm(t('notifications.leave'))) {
      setCurrentTab(newValue)
      setDirty(false)

      return
    }

    if (!isDirty) {
      setCurrentTab(newValue)
    }
  }

  const handleSetCurrentSort = (value: GridSortModel) => setCurrentSort(value)

  const handleShowDialog = (data: ConfigRowsType) => {
    const { id, objectCode, title, objectTitle } = data

    setCopyFormData({ id, objectCode, title, objectTitle })
    setShowCopyDialog(true)
  }

  const handleCloseDialog = () => {
    setCopyFormData(null)
    setShowCopyDialog(false)
  }

  const handleCopyForm = (data: CopyModelDataType) => {
    const { code, title, copyId: copyFormId } = data
    copyForm({ code, title, copyFormId })
      .unwrap()
      .then(res => {
        setShowCopyDialog(false)
        navigate(`${ROUTES.CONFIG_FORMS_EDIT}/${res.code}`)
      })
  }

  return {
    state: {
      currentSort,
      objectCode,
      searchPattern,
      currentTab,
      isDirty,
      showCopyDialog,
      copyFormData,
      isLoadingCopyForm,
    },
    data: {
      sort,
    },
    handlers: {
      handleDeleteForm,
      handleDeleteFormByCode,
      handleCopyForm,
      handleEditForm,
      handleSetSorting,
      handleSetObjectCode,
      handleSetSearchPattern,
      handleSetDirty,
      handleTabChange,
      handleSetCurrentSort,
      handleShowDialog,
      handleCloseDialog,
    },
  }
}
