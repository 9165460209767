import { FC } from 'react'
import { Backdrop } from '@mui/material'

import { useAppSelector } from '@hooks'

export const GlobalUIOverlay: FC = () => {
  const isOverlayShown = useAppSelector(state => state.uiOverlay.isOverlayShown)

  return (
    <Backdrop
      open={isOverlayShown}
      sx={{
        zIndex: theme => theme.zIndex.modal + 10,
      }}
    ></Backdrop>
  )
}
