import { FC, MouseEvent, useMemo } from 'react'
import {
  AlignHorizontalLeft as AlignLeftIcon,
  AlignVerticalTop as AlignTopIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  VisibilityOffOutlined as HiddenIcon,
} from '@mui/icons-material'
import { Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'

import { FORM_FIELD_LABEL_POSITION } from '@constants'

import { highlightActiveButton } from './helpers'

export type MenuPositionType = {
  mouseX: number
  mouseY: number
} | null

type EditTextPopoverProps = {
  menuPosition: MenuPositionType
  handleOpenEdit: (event: MouseEvent<HTMLLIElement>) => void
  elementTitleHidden?: boolean
  labelPosition?: FORM_FIELD_LABEL_POSITION
  handleSetLabelPosition?: (value: FORM_FIELD_LABEL_POSITION) => void
  handleSetElementTitleHidden?: (value: boolean) => void
  handleDeleteFieldOnForm?: () => void
  handleFieldMenuClose: () => void
  displayEditOption?: boolean
  displayDeleteOption?: boolean
  displayFieldParamsOption?: boolean
}

export const ElementContextMenu: FC<EditTextPopoverProps> = ({
  menuPosition,
  handleFieldMenuClose,
  handleOpenEdit,
  handleDeleteFieldOnForm,
  elementTitleHidden,
  labelPosition,
  handleSetLabelPosition,
  handleSetElementTitleHidden,
  displayEditOption = true,
  displayDeleteOption = true,
  displayFieldParamsOption = true,
}) => {
  const titleHidden: boolean = useMemo(() => elementTitleHidden as boolean, [elementTitleHidden])

  return (
    <Menu
      anchorReference='anchorPosition'
      open={menuPosition !== null}
      anchorPosition={
        menuPosition !== null ? { top: menuPosition.mouseY, left: menuPosition.mouseX } : undefined
      }
      onClose={handleFieldMenuClose}
    >
      {displayEditOption && (
        <MenuItem onClick={handleOpenEdit}>
          <Grid container gap={0.8} justifyContent='space-between'>
            <Typography variant='body1'>Edit</Typography>
            <EditIcon color='action' fontSize={'medium'} />
          </Grid>
        </MenuItem>
      )}
      {displayFieldParamsOption && (
        <Grid container justifyContent='center' px={1}>
          <IconButton
            sx={{
              ...highlightActiveButton(
                labelPosition === FORM_FIELD_LABEL_POSITION.LEFT && !titleHidden
              ),
            }}
            onClick={() => {
              handleSetElementTitleHidden?.(false)
              handleSetLabelPosition?.(FORM_FIELD_LABEL_POSITION.LEFT)
            }}
          >
            <AlignLeftIcon />
          </IconButton>
          <IconButton
            sx={{
              ...highlightActiveButton(
                labelPosition === FORM_FIELD_LABEL_POSITION.TOP && !titleHidden
              ),
            }}
            onClick={() => {
              handleSetElementTitleHidden?.(false)
              handleSetLabelPosition?.(FORM_FIELD_LABEL_POSITION.TOP)
            }}
          >
            <AlignTopIcon />
          </IconButton>
          <IconButton
            sx={{ ...highlightActiveButton(titleHidden) }}
            onClick={() => {
              handleSetElementTitleHidden?.(!elementTitleHidden)
            }}
          >
            <HiddenIcon />
          </IconButton>
        </Grid>
      )}
      {displayDeleteOption && (
        <>
          <Divider />
          <MenuItem onClick={handleDeleteFieldOnForm}>
            <Grid container gap={0.8} justifyContent='space-between'>
              <Typography variant='body1'>Delete</Typography>
              <DeleteIcon color='action' />
            </Grid>
          </MenuItem>
        </>
      )}
    </Menu>
  )
}
