import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, useTheme } from '@mui/material'

import { PageContext as ConfiguredViewPageContext } from '@pages/ConfiguredEntity'

import { DraggableForm } from './DraggableForm'

/* @deprecated  */
export const DynamicForms: FC = () => {
  const { t } = useTranslation()
  const {
    dynamicFormsData,
    onSetSelectedRowId,
    handleRemoveSamePageForm,
    selectedRowIds,
    onSetAllObjectData,
  } = useContext(ConfiguredViewPageContext)

  const theme = useTheme()

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {dynamicFormsData.map(
        formData =>
          formData.id &&
          formData.rowId && (
            <DraggableForm
              key={formData.id}
              disableDragging={false}
              formDataId={formData.id}
              isSelected={formData.rowId === selectedRowIds[0]}
              passedParams={formData}
              rowId={formData.rowId}
              theme={theme}
              title={`${t('groups.variant.forms.element')} ${formData.id}`}
              onRemoveSamePageForm={handleRemoveSamePageForm}
              onSetAllObjectData={onSetAllObjectData}
              onSetSelectedRowId={onSetSelectedRowId}
            />
          )
      )}
    </Box>
  )
}
