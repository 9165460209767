import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GETEntityFilter } from 'src/types'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { usePrompt } from '@hooks'
import { GENERATOR_INPUT_TYPE, MAX_INPUT_LENGTH } from '@constants'

type FilterDialogProps = {
  isShow: boolean
  onClose: (isDirty: boolean) => void
  onSubmit: (filter: GETEntityFilter) => void
  filter?: GETEntityFilter | null
}

export type FormListControlFilter = GETEntityFilter

export const FilterDialog: FC<FilterDialogProps> = ({ isShow, filter, onClose, onSubmit }) => {
  const { t } = useTranslation()
  const methods = useForm<FormListControlFilter>({
    defaultValues: { value: '', title: '' },
  })
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = methods
  const watchId = watch('id')

  useEffect(() => {
    if (filter) {
      reset(filter)
    }
  }, [filter])

  usePrompt({ when: isDirty })

  return (
    <ModalWrapper
      hideDivider
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={isShow}
      title={t('fieldsConfig.filters.dialog.title', { type: watchId ? 'Edit' : 'Add' })}
      onClose={() => onClose(isDirty)}
      onSave={handleSubmit(onSubmit)}
    >
      <Box mb={0.25} mt={1}>
        <FormProvider {...methods}>
          <FormGenerator
            mainGridSpacing={0}
            inputs={[
              {
                inputType: GENERATOR_INPUT_TYPE.INPUT,
                name: 'title',
                label: t('fieldsConfig.filters.dialog.labels.title'),
                placeholder: t('fieldsConfig.filters.dialog.placeholder.title'),
                labelPlacement: 'top',
                rules: { required: true, validate: value => Boolean(value.trim('')) },
              },
              {
                inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
                name: 'value',
                maxLengthInput: MAX_INPUT_LENGTH,
                label: t('fieldsConfig.filters.dialog.labels.filter'),
                placeholder: t('fieldsConfig.filters.dialog.placeholder.filter'),
                labelPlacement: 'top',
                rules: { required: true, validate: value => Boolean(value.trim('')) },
                formInputContainerSx: { mb: 0 },
              },
            ]}
          />
        </FormProvider>
      </Box>
    </ModalWrapper>
  )
}
