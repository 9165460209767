import { FC } from 'react'

import { hideMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch, useAppSelector } from '@hooks'
import { SNACK_LIFETIME } from '@constants'

import { Snackbar } from '../Snackbar'

export const SnackMessage: FC = () => {
  const snack = useAppSelector(state => state.snackbar)
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(hideMessage())
  }

  return (
    <Snackbar
      autoHideDuration={SNACK_LIFETIME}
      message={snack.text}
      open={snack.isShown}
      statusCode={snack.statusCode}
      type={snack.type}
      onClose={handleClose}
    />
  )
}
