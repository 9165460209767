import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { v4 as uuid } from 'uuid'
import { TreeData } from '@atlaskit/tree'

import {
  useCreateControlPanelMutation,
  useFetchControlPanelByIdQuery,
  useUpdateControlPanelMutation,
} from '@redux/api'

import { usePrompt } from '@hooks'
import { getTree } from '@helpers'
import { ELEMENT_TYPE_PANEL, OPERATION_TYPE, ROUTES } from '@constants'
import { UpdatedElementType } from '@types'

import {
  getFindIndexUpdatedElement,
  transformExpandedTrees,
  transformUpdatedElementsForRequest,
} from '../helpers'
import { ControlPanelForm } from '../types'

export const useHandlers = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { data: currentPanel } = useFetchControlPanelByIdQuery(id, { skip: !id })

  const [skipDirty, setSkipDirty] = useState(false)
  const [currentTab, setCurrentTab] = useState<number>(-1)
  const [updatedElements, setUpdatedElements] = useState<UpdatedElementType[]>([])
  const methods = useForm<ControlPanelForm>({
    defaultValues: { lines: [], lineTitle: '', title: '' },
  })
  const {
    watch,
    resetField,
    trigger,
    setError,
    setValue,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = methods
  const { fields, append, update, remove } = useFieldArray({
    name: 'lines',
    control: methods.control,
  })

  const lines = watch('lines')
  const isEdit = Boolean(id)

  const transformFirstTrees = useMemo(
    () => transformExpandedTrees(currentPanel?.lines.map(line => getTree(line.items)) || []),
    [currentPanel]
  )
  const transformTrees = transformExpandedTrees(lines.map(line => line.items))
  const isDirtyTrees = !isEqual(transformFirstTrees, transformTrees)
  const dirtyLines = useMemo(
    () => updatedElements.some(line => line.type === ELEMENT_TYPE_PANEL.LINE),
    [updatedElements]
  )
  const globalDirty = dirtyFields['title'] || dirtyLines || isDirtyTrees

  const [updateControlPanel] = useUpdateControlPanelMutation()
  const [createControlPanel, { isLoading: isLoadingCreate }] = useCreateControlPanelMutation()

  const isCreate = ROUTES.CONFIG_CONTROL_PANELS_CREATE === location.pathname
  const isLeaveEdit = globalDirty && !isLoadingCreate && !isCreate && !!currentPanel && !skipDirty
  const isLeaveCreate = globalDirty && isCreate && !isEdit && !isLoadingCreate && !skipDirty

  usePrompt({ when: isLeaveEdit || isLeaveCreate })

  useEffect(() => {
    if (currentPanel) {
      reset({
        title: currentPanel.title,
        lineTitle:
          currentPanel.lines.length > 0
            ? currentPanel.lines[currentTab >= 0 ? currentTab : 0].title
            : '',
        lines: currentPanel.lines.map(line => ({
          ...line,
          lineId: line.id,
          items: getTree(line.items),
        })),
      })

      if (currentPanel.lines.length && currentTab < 0) {
        setCurrentTab(0)
      }
    }
  }, [currentPanel])

  const watchLineTitle = watch('lineTitle')

  const handleCreateLine = () => {
    trigger('lineTitle').then(validate => {
      if (validate) {
        if (!watchLineTitle) {
          setError('lineTitle', { type: 'required' })

          return
        }

        if (currentTab >= 0) {
          update(currentTab, { ...lines[currentTab], title: watchLineTitle })

          if (watchLineTitle === currentPanel?.lines[currentTab].title) {
            setUpdatedElements(
              updatedElements.filter(element =>
                element.type === ELEMENT_TYPE_PANEL.LINE
                  ? element.data?.id !== currentPanel?.lines[currentTab].id
                  : true
              )
            )

            return
          }

          handleSetUpdatedElement({
            operationType: lines[currentTab].lineId ? OPERATION_TYPE.UPDATE : OPERATION_TYPE.CREATE,
            type: ELEMENT_TYPE_PANEL.LINE,
            data: {
              id: lines[currentTab].lineId,
              order: lines[currentTab].order,
              panelId: currentPanel?.id || null,
              title: watchLineTitle,
            },
          })

          return
        }

        const alreadyExistsLine = lines.findIndex(({ title }) => title === watchLineTitle)

        if (alreadyExistsLine !== -1) {
          setCurrentTab(alreadyExistsLine)

          return
        }

        const id = uuid()
        append({ title: watchLineTitle, items: getTree([]), order: fields.length, uuid: id })
        handleSetUpdatedElement({
          operationType: OPERATION_TYPE.CREATE,
          type: ELEMENT_TYPE_PANEL.LINE,
          data: {
            uuid: id,
            title: watchLineTitle,
            panelId: currentPanel?.id || null,
            order: fields.length,
          },
        })
        setCurrentTab(fields.length)
      }
    })
  }

  const handleAddNewLine = () => {
    resetField('lineTitle', { defaultValue: '' })
    setCurrentTab(-1)
  }

  const handleRemoveLine = (index: number) => {
    const removedLine = fields[index]

    setUpdatedElements(prev =>
      prev.filter(element => {
        // Если уже линия изменялась/добавлялась
        if (element.type === ELEMENT_TYPE_PANEL.LINE && element.data) {
          const isMatchedLine = removedLine.lineId
            ? removedLine.lineId === element.data.id
            : removedLine.uuid === element.data?.uuid

          return !isMatchedLine
        }

        // удаление связанных с линией айтемов
        if (element.data && 'lineId' in element.data) {
          return removedLine.lineId !== element.data.lineId
        }

        // удаление связанных с линией айтемов
        if (element.data && 'lineUuid' in element.data) {
          return removedLine.uuid !== element.data.lineUuid
        }

        return true
      })
    )

    if (currentPanel && removedLine.lineId) {
      setUpdatedElements(prev => [
        ...prev,
        {
          operationType: OPERATION_TYPE.DELETE,
          type: ELEMENT_TYPE_PANEL.LINE,
          data: { id: removedLine.lineId, panelId: currentPanel.id },
        },
      ])
    }
    remove(index)

    // т.к. remove асинхронный и изменения в fields и lines сразу не видны
    const tmp = lines.filter((_line, prevIndex) => index !== prevIndex)
    const lastIndex = tmp.length - 1

    if (lastIndex >= 0) {
      setCurrentTab(lastIndex)
      resetField('lineTitle', { defaultValue: tmp[lastIndex].title })

      return
    }

    setCurrentTab(-1)
    resetField('lineTitle', { defaultValue: '' })
  }
  const handleChangeTab = (_: SyntheticEvent<Element, Event>, value: number) => {
    setCurrentTab(value)
    resetField('lineTitle', { defaultValue: fields[value].title })
  }

  const handleUpdateMenuPanelIndex = (index: number, items: TreeData) => {
    setValue(`lines.${index}.items`, items)
  }

  const handleSaveControlPanel = handleSubmit(({ lineTitle, ...data }: ControlPanelForm) => {
    if (isEdit && currentPanel) {
      const updatedPanel: UpdatedElementType | null =
        data.title !== currentPanel.title
          ? {
              type: ELEMENT_TYPE_PANEL.PANEL,
              operationType: OPERATION_TYPE.UPDATE,
              data: { title: data.title, id: currentPanel.id },
            }
          : null

      const elements = [
        ...(updatedPanel ? [updatedPanel] : []),
        ...transformUpdatedElementsForRequest(updatedElements, data.lines, currentPanel.id),
      ]

      if (elements.length) {
        updateControlPanel({
          id: currentPanel.id,
          elements,
        }).then(() => {
          setUpdatedElements([])
        })
      }

      return
    } else {
      setSkipDirty(true)
      createControlPanel([
        {
          type: ELEMENT_TYPE_PANEL.PANEL,
          operationType: OPERATION_TYPE.CREATE,
          data: { title: data.title },
        },
        ...transformUpdatedElementsForRequest(updatedElements, data.lines),
      ])
        .unwrap()
        .then(res => {
          if (!id) {
            navigate(`${ROUTES.CONFIG_CONTROL_PANELS_EDIT}/${res.id}`)
          }
          setUpdatedElements([])
        })
        .finally(() => {
          setSkipDirty(false)
        })
    }
  })

  const handleSetUpdatedElement = (element: UpdatedElementType) => {
    const findIndex = getFindIndexUpdatedElement(element, updatedElements)

    if (findIndex !== -1) {
      const clone = cloneDeep(updatedElements)

      clone.splice(findIndex, 1, { ...clone[findIndex], ...element })
      setUpdatedElements(clone)

      return
    }

    setUpdatedElements(prev => [...prev, element])
  }

  const handleSetUpdatedElements = (elements: UpdatedElementType[]) => setUpdatedElements(elements)

  const handleCancelPanel = () => {
    if (globalDirty && !confirm(t('notifications.leave'))) {
      return
    }

    reset()
    if (currentPanel) {
      setCurrentTab(0)
      setValue('lineTitle', currentPanel.lines[0]?.title)
    }
    setUpdatedElements([])
    navigate(ROUTES.CONFIG_CONTROL_PANELS)
  }

  return {
    data: {
      currentPanel,
    },
    state: {
      currentTab,
      methods,
      fields,
      lines,
      updatedElements,
      globalDirty,
    },
    handlers: {
      handleCreateLine,
      handleAddNewLine,
      handleRemoveLine,
      handleChangeTab,
      handleUpdateMenuPanelIndex,
      handleSaveControlPanel,
      handleSetUpdatedElement,
      handleSetUpdatedElements,
      handleCancelPanel,
    },
  }
}
