import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSetBlockingUIOverlay } from '@components/DisplayForm/hooks/useSetBlockingUIOverlay'

import { closeDialog } from '@redux/reducers/dialogWindowManager.reducer'

import { useAppDispatch } from '@hooks'
import { DIALOG_WINDOW_TYPE } from '@types'

type useHandlersParams = {
  id: string | undefined
  text: string
  setOuterValue: ((value: string) => void) | undefined
}

export const useStringEditDialog = ({ id, text, setOuterValue }: useHandlersParams) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [value, setValue] = useState<string>(text ?? '')
  const [isDirty, setIsDirty] = useState<boolean>(false)

  useEffect(() => {
    setIsDirty(text !== value)
  }, [value])

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value)
    setIsDirty(true)
  }

  const handleCancel = () => {
    if ((isDirty && !confirm(t('notifications.leave'))) || !id) {
      return
    }
    dispatch(closeDialog({ id }))
  }

  const handleSave = () => {
    if (!id) {
      return
    }

    setOuterValue?.(value)
    dispatch(closeDialog({ id }))
  }

  useSetBlockingUIOverlay({
    dialogId: id,
    dialogType: DIALOG_WINDOW_TYPE.STRING_EDIT,
  })

  return {
    state: {
      value,
      isDirty,
    },
    data: {
      t,
    },
    handlers: {
      handleChangeValue,
      handleCancel,
      handleSave,
    },
  }
}
