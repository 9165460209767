import { getColumnFieldName, getColumnFieldNameForSort } from '@pages/ConfiguredEntity/helpers'
import { isAvailableRuleByTypeAndNames } from '@pages/ConfiguredEntity/helpers/isAvailableRuleByTypeAndNames'

import { FIELD_VALUE_TYPE, SEARCH_RULE_TYPE } from '@constants'
import { ViewRow } from '@types'

export const changeGlobalCheckBoxInColumnHeader = (
  columnNameCheckBox: Record<string, boolean>,
  sortKeysColumnCheckBox: Record<string, boolean>,
  checked: boolean,
  setterTitle: (value: Record<string, boolean>) => void,
  setterSortKey: (value: Record<string, boolean>) => void,
  rule?: SEARCH_RULE_TYPE,
  columns?: ViewRow[],
  isBooleanColumns?: boolean
) => {
  const newColumnNameCheckbox = columnNameCheckBox
  const newSortKeysColumnCheckBox = sortKeysColumnCheckBox
  Object.keys(columnNameCheckBox).forEach(key => {
    if (rule && columns && checked) {
      const column = columns?.find(col => getColumnFieldName(col) === key)

      const isBoolean =
        isBooleanColumns && column
          ? column?.valueType === FIELD_VALUE_TYPE.BOOLEAN
          : column?.valueType !== FIELD_VALUE_TYPE.BOOLEAN

      newColumnNameCheckbox[key] = Boolean(
        column &&
          isAvailableRuleByTypeAndNames(column, rule) &&
          // Для boolean колонок чекбоксы не прожимаются, потому что нажатие на них включает режим поиска
          // по булевым значениям (isBooleanSearchMode в useQuickSearch становится true)
          isBoolean
      )

      return
    }

    newColumnNameCheckbox[key] = checked
  })

  Object.keys(sortKeysColumnCheckBox).forEach(key => {
    if (rule && columns && checked) {
      const column = columns?.find(col => getColumnFieldNameForSort(col) === key)

      const isBoolean =
        isBooleanColumns && column
          ? column?.valueType === FIELD_VALUE_TYPE.BOOLEAN
          : column?.valueType !== FIELD_VALUE_TYPE.BOOLEAN

      newSortKeysColumnCheckBox[key] = Boolean(
        column &&
          isAvailableRuleByTypeAndNames(column, rule) &&
          // Для boolean колонок чекбоксы не прожимаются, потому что нажатие на них включает режим поиска
          // по булевым значениям (isBooleanSearchMode в useQuickSearch становится true)
          isBoolean
      )

      return
    }

    newSortKeysColumnCheckBox[key] = checked
  })

  setterTitle(newColumnNameCheckbox)
  setterSortKey(newSortKeysColumnCheckBox)

  return {
    newColumnNameCheckbox,
    newSortKeysColumnCheckBox,
  }
}
