import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { DialogCopyModelType } from '@types'

type CopyDialogProps = {
  isShow: true
  onClose: () => void
  copyData: any
  onCopy: (data: any) => void
  dialogTitle: string
  loading?: boolean
}

export const CopyDialog: FC<CopyDialogProps> = ({
  isShow,
  onClose,
  copyData,
  onCopy,
  dialogTitle,
  loading,
}) => {
  const methods = useForm<DialogCopyModelType>()
  const { t } = useTranslation()

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods

  useEffect(() => {
    reset({ title: copyData.title })
  }, [copyData])

  const handleSave = (formValue: any) => {
    const { title } = formValue

    onCopy({ title, copyId: copyData.id })
  }

  const handleClose = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    onClose()
  }

  return (
    <ModalWrapper
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={loading}
      isShow={isShow}
      title={dialogTitle}
      onClose={handleClose}
      onSave={handleSubmit(handleSave)}
    >
      <Box my={1}>
        <FormProvider {...methods}>
          <FormGenerator
            mainGridSpacing={0}
            inputs={[
              {
                name: 'title',
                inputType: GENERATOR_INPUT_TYPE.INPUT,
                label: t('copying.inputs.title.label'),
                placeholder: t('copying.inputs.title.placeholder'),
                rules: { required: true },
              },
            ]}
          />
        </FormProvider>
      </Box>
    </ModalWrapper>
  )
}
