import { FC, useContext } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Button, Grid } from '@mui/material'

import { TableWithActions } from '@pages/Contexts/components/TableWithActions'
import { PageContext } from '@pages/DisplayRules'

import { DISPLAY_RULE_COLUMNS, GENERATOR_INPUT_TYPE } from '@constants'

type AllDisplayRulesProps = {
  methods: UseFormReturn<any>
}

export const AllDisplayRules: FC<AllDisplayRulesProps> = ({ methods }) => {
  const { t } = useTranslation()

  const {
    objects,
    displayRulesTables,
    onCreateDisplayRule,
    onDeleteDisplayRule,
    onEditDisplayRule,
    isLoadingDisplayRules,
  } = useContext(PageContext)

  return (
    <>
      <Box ml={1} sx={{ mr: 1, ml: 1 }}>
        <Grid container item alignItems='center' flexWrap='nowrap' gap={1}>
          <Grid item>
            <Button sx={{ mb: 1 }} variant='contained' onClick={onCreateDisplayRule}>
              {t(`displayRules.button.add`)}
            </Button>
          </Grid>
          <Grid item flexGrow={1}>
            <FormProvider {...methods}>
              <form>
                <Box>
                  <Grid container alignItems='center' justifyContent='center' spacing={0.5}>
                    <Grid item sm={6} xs={6}>
                      <FormInput
                        inputType={GENERATOR_INPUT_TYPE.INPUT}
                        name='searchPattern'
                        placeholder={t('configViews.allTab.search')}
                        size='small'
                      />
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <FormInput
                        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                        name='selectedObject'
                        placeholder={t('displayRules.placeholder.object')}
                        size='small'
                        sx={{ maxWidth: '100%' }}
                        autocompleteOptions={objects?.map(obj => ({
                          id: obj.code,
                          label: obj.title,
                        }))}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
        <TableWithActions
          columns={DISPLAY_RULE_COLUMNS}
          loading={isLoadingDisplayRules}
          rows={displayRulesTables}
          onDelete={onDeleteDisplayRule}
          onEdit={onEditDisplayRule}
        />
      </Box>
    </>
  )
}
