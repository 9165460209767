import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { AutocompleteOption } from '@types'

import { ModalRefPathPicker } from '@gantt/components/GanttCreateOrEdit/components/ModalRefPathPicker'
import { ConfigField } from '@gantt/components/GanttCreateOrEdit/types'
import { OptionsFilter } from '@gantt/types'

type Props = {
  isSingleField: boolean
  isFlatOptions: boolean
  isDisabled: boolean
  name: string
  fieldName: string
  prefix?: string
  embeddedObjectPickerControllerName: string
  label: string
  index: number
  field: Record<'id', string>
  object?: AutocompleteOption<string> | null
  onRemove: () => void
  onSetSelectedPaths: (paths: string[]) => void
  watchValue: ConfigField
  optionsFilter?: OptionsFilter
  selectedPaths?: string[]
  existingPaths?: string[]
  currentFieldsList: ConfigField[]
}

export const PickerItem = ({
  isSingleField = false,
  field,
  isDisabled,
  name,
  fieldName,
  prefix,
  isFlatOptions,
  embeddedObjectPickerControllerName,
  index,
  label,
  object,
  onRemove,
  onSetSelectedPaths,
  watchValue,
  optionsFilter,
  selectedPaths,
  existingPaths,
  currentFieldsList,
}: Props) => {
  const [initialValue, setInitialValue] = useState<ConfigField>()

  useEffect(() => {
    if (!initialValue?.field) {
      const clonedValue = structuredClone(watchValue)
      setInitialValue(clonedValue)
    }

    return () => setInitialValue(undefined)
  }, [watchValue?.field])

  const handleRemove = () => {
    onRemove()
  }

  const handleSaveField = useCallback(
    (value: ConfigField) => {
      const selectedPaths = currentFieldsList?.map(field => field.pathStr)
      onSetSelectedPaths(selectedPaths)
    },
    [currentFieldsList]
  )

  return (
    <Box display='flex'>
      <ModalRefPathPicker
        hasField
        currentValue={initialValue}
        embeddedObjectPickerControllerName={embeddedObjectPickerControllerName}
        existingPaths={existingPaths}
        fieldName={fieldName}
        isDisabled={isDisabled}
        isFieldArraySingleItem={isSingleField}
        isFlatOptions={isFlatOptions}
        label={index === 0 ? label : ' '}
        name={name}
        object={object}
        optionsFilter={optionsFilter}
        prefix={prefix}
        selectedPaths={selectedPaths}
        sx={{ width: '100%' }}
        onRemove={handleRemove}
        onSave={handleSaveField}
      />
    </Box>
  )
}
