import { FC } from 'react'
import { FieldArrayWithId, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'

import { ConfigContextMenuForm } from '@pages/ContextMenuCreateOrEdit/hooks/useHandlers'

import { UpdatedVariableType } from '@types'

import { VariableItemRow } from './VariableItemRow'

export type VariablesTabProps = {
  variables: FieldArrayWithId<ConfigContextMenuForm, 'variables', 'id'>[]
  onRemove: (index: number) => void
  onDeleteVariable: (variable: UpdatedVariableType) => void
  onSaveVariable: (variable: UpdatedVariableType) => void
}

export const VariablesTab: FC<VariablesTabProps> = ({
  onRemove,
  onDeleteVariable,
  onSaveVariable,
  variables,
}) => {
  const { watch } = useFormContext<ConfigContextMenuForm>()
  const { t } = useTranslation()

  const watchedObject = watch('object')

  return (
    <Box sx={{ border: theme => theme.palette.border.customTable, borderRadius: 1, py: 1 }}>
      <Grid container columnSpacing={4} px={3} xs={12}>
        <Grid item xs={5.5}>
          <Typography variant={'h5'}>
            {t('ganttCreate.timelineForm.additionalFieldsForm.variableHeader')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'h5'}>
            {t('ganttCreate.timelineForm.additionalFieldsForm.pathHeader')}
          </Typography>
        </Grid>
      </Grid>
      {variables.length === 0 ? (
        <Box
          alignItems={'center'}
          display={'flex'}
          justifyContent={'center'}
          sx={{ minHeight: '100px', height: '100%', userSelect: 'none' }}
        >
          <Typography>{t('ganttCreate.timelineForm.additionalFieldsForm.noFieldItems')}</Typography>
        </Box>
      ) : (
        <Grid container flexDirection={'column'}>
          {variables.map((field, index) => {
            return (
              <VariableItemRow
                key={field.id}
                controllerNameField={`variables.${index}.field`}
                controllerNamePathArray={`variables.${index}.pathArray`}
                controllerNamePathStr={`variables.${index}.pathStr`}
                field={field}
                index={index}
                watchValue={watch(`variables.${index}`)}
                watchedObject={watchedObject}
                onDelete={onDeleteVariable}
                onRemove={onRemove}
                onSave={onSaveVariable}
              />
            )
          })}
        </Grid>
      )}
    </Box>
  )
}
