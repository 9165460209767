import { FormDataEnriched, FormTab } from 'src/types'

import {
  BOTTOM_PADDING_CONTAINER,
  FORM_ELEMENT_TYPE,
  MIN_HEIGHT_FIELD,
  MIN_HEIGHT_FIELD_DIALOG,
  MIN_HEIGHT_FIELD_DIALOG_WITH_LABEL,
  MIN_HEIGHT_FIELD_WITH_LABEL,
} from '@constants'

export const getAllHeightTabs = (tabs: FormTab<FormDataEnriched>[], isDialogWindow: boolean) =>
  tabs.map(({ lines }) => {
    let sum = 0

    lines.forEach((line, index) => {
      if (index === lines.length - 1) {
        return
      }

      const hasField = line.elements.some(({ type }) => type === FORM_ELEMENT_TYPE.FIELD)

      if (isDialogWindow) {
        sum += hasField ? MIN_HEIGHT_FIELD_DIALOG_WITH_LABEL : MIN_HEIGHT_FIELD_DIALOG
      } else {
        sum += hasField ? MIN_HEIGHT_FIELD_WITH_LABEL : MIN_HEIGHT_FIELD
      }
    })

    if (sum === 0) {
      const minHeightField = isDialogWindow
        ? MIN_HEIGHT_FIELD_DIALOG_WITH_LABEL
        : MIN_HEIGHT_FIELD_WITH_LABEL

      return minHeightField + BOTTOM_PADDING_CONTAINER
    }

    return sum + BOTTOM_PADDING_CONTAINER
  })
