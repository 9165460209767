import { FC, ReactNode } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Box, IconButton, Tabs } from '@mui/material'

import { ARROW_DIRECTION } from '@constants'

export const ScrollButton = (props: { direction: ARROW_DIRECTION; disabled: boolean }) => (
  <IconButton
    {...props}
    disableFocusRipple
    disableRipple
    disableTouchRipple
    sx={{ p: 0, visibility: props.disabled ? 'hidden' : 'initial' }}
  >
    {props.direction === ARROW_DIRECTION.left ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
  </IconButton>
)

type ScrollableLineProps = {
  height?: string
  children: ReactNode
  width: number
}

export const ScrollableLine: FC<ScrollableLineProps> = ({ children, width, height }) => {
  return (
    <Box sx={{ maxWidth: width, display: 'flex', overflowX: 'auto' }}>
      <Tabs
        scrollButtons
        ScrollButtonComponent={ScrollButton}
        TabIndicatorProps={{ hidden: true }}
        value={0}
        variant='scrollable'
        sx={{
          // mx: 3,
          minHeight: '28px',
          alignItems: 'center',
          width,
          '.MuiTabs-flexContainer': { gap: 1, alignItems: 'center' },
          height,
        }}
      >
        {children}
      </Tabs>
    </Box>
  )
}
