import React, { ReactNode, useCallback, useEffect, useId, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import i18next from 'i18next'
import i18n from 'i18next'
import has from 'lodash/has'
import { FormInput } from '@microservices/wiskey-react-components'
import { FormHelperText } from '@mui/material'

import { CommandPickerController } from '@components/hookFormControllers/CommandPickerController'
import { PickerFieldArrayController } from '@components/hookFormControllers/PickerFieldArrayController'
import { ScriptValueEditor } from '@components/ScriptValueDialog'
import { ColorSettings } from '@components/TextSettings/components/ColorSettings'

import { ErrorValidJSType } from '@helpers'
import { GENERATOR_INPUT_TYPE } from '@constants'

import { ModalRefPathPicker } from '@gantt/components/GanttCreateOrEdit/components/ModalRefPathPicker' //todo не должно находится тут
import {
  AutocompleteOption,
  ConfigField,
  GANTT_BIND_TYPE_LIST,
} from '@gantt/components/GanttCreateOrEdit/types'
import {
  AutocompleteOptionsObject,
  getCommandsFormattedRequest,
  getKeyByBindType,
} from '@gantt/helpers'
import { ExtOptionFilter, OptionsFilter } from '@gantt/types'

import 'prismjs/components/prism-json'

type FieldPickerProps = {
  hasCommands?: boolean
  existingPaths?: string[]
  selectedPaths?: string[]
}

type ScriptEditorProps = {
  noValidate?: boolean
  validator?: (value: string) => ErrorValidJSType | undefined
  onAfterValidation?: (state: boolean) => void
}

type CommandPickerProps = {
  isTypeHidden?: boolean
  isMulti?: boolean
}

type FormInputProps = {
  selectedOptions?: string[]
}

type SpecialProps = {
  fieldPicker?: FieldPickerProps
  scriptEditor?: ScriptEditorProps
  commandPicker?: CommandPickerProps
  formInput?: FormInputProps
}

export type InputProps = {
  language?: string //todo why
  valueInputLabel?: string
  prefix?: string
  object?: AutocompleteOption<string> | null
  inputType?: InputType
  optionsFilter?: OptionsFilter
  isDisabled?: boolean
  onSaveField?: (value: ConfigField) => void
} & SpecialProps

type Props = {
  getPath: (key: GANTT_BIND_TYPE_LIST | string) => string
  type: GANTT_BIND_TYPE_LIST
  hint?: string
  placeholder?: string
  options?: ExtOptionFilter[]
  children?: ReactNode
} & InputProps

export type InputType = GENERATOR_INPUT_TYPE | INPUT_TYPE

export enum INPUT_TYPE {
  COLOR = 'color',
}

export const ValueInputFactory = ({
  fieldPicker,
  commandPicker,
  scriptEditor,
  getPath,
  type,
  object,
  hint,
  inputType = GENERATOR_INPUT_TYPE.INPUT,
  valueInputLabel = i18n.t('label.value'),
  isDisabled = false,
  prefix,
  children,
  placeholder,
  optionsFilter,
  options,
  onSaveField,
  formInput,
}: Props) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const watchValue = watch(getPath(getKeyByBindType(type)))
  const [initialValue, setInitialValue] = useState<ConfigField>()
  const componentInstanceId = useId()
  const componentFieldKey = [type, inputType, componentInstanceId].join('-')

  // ModalRefPathPicker restore value on cancel
  useEffect(() => {
    if (!initialValue?.field) {
      const clonedValue = structuredClone(watchValue)
      setInitialValue(clonedValue)
    }

    return () => setInitialValue(undefined)
  }, [watchValue?.field])

  const handleChange = useCallback((key: GANTT_BIND_TYPE_LIST, value: string) => {
    setValue(getPath(key), value, { shouldDirty: true })
  }, [])

  const handleOnSaveField = useCallback((value: ConfigField) => {
    fieldPicker?.hasCommands &&
      setValue(getPath(`field.commands`), getCommandsFormattedRequest(value.commands || []))
    onSaveField?.(value)
  }, [])

  const filteredOptions = useMemo(() => {
    if (optionsFilter && options) {
      return optionsFilter(options, type)
    }

    return options || []
  }, [options, optionsFilter])

  switch (type) {
    case GANTT_BIND_TYPE_LIST.FIELD:
      return (
        <ModalRefPathPicker
          key={componentFieldKey}
          hasField
          commandsName={getPath(`field.commands`)}
          currentValue={initialValue}
          embeddedObjectPickerControllerName={getPath(`field.pathArray`)}
          existingPaths={fieldPicker?.existingPaths}
          fieldName={getPath(`field.field`)}
          hasCommands={fieldPicker?.hasCommands}
          isDisabled={isDisabled}
          label={valueInputLabel}
          name={getPath(`field.pathStr`)}
          object={object}
          optionsFilter={optionsFilter}
          pickerName={getPath('field')}
          prefix={prefix}
          selectedPaths={fieldPicker?.selectedPaths}
          onSave={handleOnSaveField}
        />
      )

    case GANTT_BIND_TYPE_LIST.STATIC:
      switch (inputType) {
        case INPUT_TYPE.COLOR:
          return (
            <>
              <ColorSettings
                key={componentFieldKey}
                clearableColor
                isEdit
                color={watchValue}
                label={valueInputLabel ?? i18n.t('label.value')}
                labelMaxWidth={120}
                labelPlacement={'left'}
                onChangeColor={value => handleChange(GANTT_BIND_TYPE_LIST.STATIC, value)}
              />
              {hint && <FormHelperText sx={{ pl: 15, ml: 0 }}>{hint}</FormHelperText>}
            </>
          )
        default:
          return (
            <>
              <FormInput
                key={componentFieldKey}
                inputType={inputType}
                label={valueInputLabel}
                name={getPath(GANTT_BIND_TYPE_LIST.STATIC)}
                placeholder={placeholder}
              />
              {hint && <FormHelperText sx={{ pl: 15, ml: 0 }}>{hint}</FormHelperText>}
            </>
          )
      }

    case GANTT_BIND_TYPE_LIST.JS:
      return (
        <ScriptValueEditor
          key={componentFieldKey}
          hasError={has(errors, getPath(GANTT_BIND_TYPE_LIST.JS))}
          hint={hint}
          label={valueInputLabel}
          language={'js'}
          maxInputLength={10000}
          noValidate={scriptEditor?.noValidate}
          placeholder={placeholder}
          validator={scriptEditor?.validator}
          value={watchValue}
          onAfterValidationScript={scriptEditor?.onAfterValidation}
          onChange={value => handleChange(GANTT_BIND_TYPE_LIST.JS, value)}
        />
      )

    case GANTT_BIND_TYPE_LIST.FIELD_ARRAY:
      return (
        <PickerFieldArrayController
          key={componentFieldKey}
          disabled={isDisabled}
          existingPaths={fieldPicker?.existingPaths}
          isFlatOptions={false}
          label={valueInputLabel ?? i18next.t('label.value')}
          name={getPath(GANTT_BIND_TYPE_LIST.FIELD_ARRAY)}
          object={object}
          optionsFilter={optionsFilter}
          prefix={prefix}
        />
      )

    case GANTT_BIND_TYPE_LIST.JSON:
      return (
        <ScriptValueEditor
          key={componentFieldKey}
          hasError={has(errors, getPath(GANTT_BIND_TYPE_LIST.JSON))}
          hint={hint}
          label={valueInputLabel}
          language={'json'}
          maxInputLength={10000}
          placeholder={placeholder}
          validator={scriptEditor?.validator}
          value={watchValue}
          onAfterValidationScript={scriptEditor?.onAfterValidation}
          onChange={value => handleChange(GANTT_BIND_TYPE_LIST.JSON, value)}
        />
      )

    case GANTT_BIND_TYPE_LIST.COMMANDS:
      return (
        <CommandPickerController
          key={componentFieldKey}
          commandName={'name'}
          isSingle={!commandPicker?.isMulti}
          isTypeHidden={commandPicker?.isTypeHidden}
          name={getPath(GANTT_BIND_TYPE_LIST.COMMANDS)}
          objectCode={object?.id || ''}
        />
      )

    case GANTT_BIND_TYPE_LIST.FORM:
      return (
        <FormInput
          key={componentFieldKey}
          autocompleteOptions={filteredOptions as AutocompleteOption[]}
          getOptionDisabled={option => option.id === watchValue?.id}
          inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
          label={valueInputLabel}
          name={getPath(GANTT_BIND_TYPE_LIST.FORM)}
          placeholder={placeholder}
          rules={{ required: true }}
          renderAutocompleteOption={(
            props: React.HTMLAttributes<HTMLLIElement>,
            option: { id: string | number; label: string }
          ) => (
            <AutocompleteOptionsObject
              list={formInput?.selectedOptions || []}
              option={option}
              otherProps={props}
            />
          )}
        />
      )

    case GANTT_BIND_TYPE_LIST.CUSTOM:
      return <>{!!children && children}</>

    case GANTT_BIND_TYPE_LIST.COMMAND: // не задаётся импутами в конфигураторе но участвует
    default:
      return <></>
  }
}
