import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { Box } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'

import { AutocompleteOption } from '@types'

type SegmentLabelsFieldsProps = {
  watchedObject?: AutocompleteOption<string> | null
  existingPaths?: string[]
}

const labelHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.cornersScriptHint'),
}

export const SegmentLabelsFields = ({ watchedObject, existingPaths }: SegmentLabelsFieldsProps) => {
  const { t } = useTranslation()
  const isDisabled = !watchedObject

  const fieldPicker = useMemo(() => {
    return {
      existingPaths,
    }
  }, [existingPaths])

  return (
    <Box>
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.leftTopTitle')}
        containerName='labels.leftTopTitle'
        fieldPicker={fieldPicker}
        hintDict={labelHints}
        isDisabled={isDisabled}
        object={watchedObject}
      />
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.topTitle')}
        containerName='labels.topTitle'
        fieldPicker={fieldPicker}
        hintDict={labelHints}
        isDisabled={isDisabled}
        object={watchedObject}
      />
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.rightTopTitle')}
        containerName='labels.rightTopTitle'
        fieldPicker={fieldPicker}
        hintDict={labelHints}
        isDisabled={isDisabled}
        object={watchedObject}
      />
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.rightBottomTitle')}
        containerName='labels.rightBottomTitle'
        fieldPicker={fieldPicker}
        hintDict={labelHints}
        isDisabled={isDisabled}
        object={watchedObject}
      />
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.bottomTitle')}
        containerName='labels.bottomTitle'
        fieldPicker={fieldPicker}
        hintDict={labelHints}
        isDisabled={isDisabled}
        object={watchedObject}
      />
      <BindTypeInputs
        blockLabel={t('ganttCreate.timelineForm.leftBottomTitle')}
        containerName='labels.leftBottomTitle'
        fieldPicker={fieldPicker}
        hintDict={labelHints}
        isDisabled={isDisabled}
        object={watchedObject}
      />
    </Box>
  )
}
