import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { Add, Done } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { Tab, TabPanel, Tabs } from '@components/Tabs'

import { GENERATOR_INPUT_TYPE } from '@constants'

import { MenuTabPanel } from './components/MenuTabPanel'
import { useControlPanelsCreateOrEdit } from './hooks/useControlPanelsCreateOrEdit'

export const ControlPanelsCreateOrEdit = () => {
  const { t } = useTranslation()

  const { data, state, handlers } = useControlPanelsCreateOrEdit()

  const { currentTab, fields, methods, lines, updatedElements, globalDirty } = state

  const {
    handleAddNewLine,
    handleChangeTab,
    handleCreateLine,
    handleRemoveLine,
    handleUpdateMenuPanelIndex,
    handleSaveControlPanel,
    handleSetUpdatedElement,
    handleSetUpdatedElements,
    handleCancelPanel,
  } = handlers

  return (
    <PageContentLayout sx={{ p: 1 }}>
      <Typography mb={1} variant='h5'>
        {t(`controlPanelCreate.title`)}
      </Typography>
      <FormProvider {...methods}>
        <FormInput
          inputType={GENERATOR_INPUT_TYPE.INPUT}
          labelSx={{ width: 170, minWidth: 170 }}
          name={'title'}
          rules={{
            required: true,
            validate: value => value.trim().length !== 0,
          }}
          label={t('controlPanelCreate.form.title.label')}
          // replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
          placeholder={t('controlPanelCreate.form.title.placeholder')}
        />
        <Grid container gap={1}>
          <Grid item flexGrow={1}>
            <FormInput
              inputType={GENERATOR_INPUT_TYPE.INPUT}
              labelSx={{ width: 170, minWidth: 170 }}
              name={'lineTitle'}
              label={t('controlPanelCreate.form.lineTitle.label')}
              // replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
              placeholder={t('controlPanelCreate.form.lineTitle.placeholder')}
            />
          </Grid>
          <Grid item>
            <Button onClick={handleCreateLine}>
              <Done />
            </Button>
          </Grid>
        </Grid>
      </FormProvider>

      <Grid container justifyContent={'flex-end'} width={'100%'}>
        <Button onClick={handleAddNewLine}>
          <Add fontSize='small' />
          {t('controlPanelCreate.form.addLineButton')}
        </Button>
      </Grid>

      <Grid>
        <Tabs
          allowScrollButtonsMobile
          scrollButtons
          value={currentTab}
          variant='scrollable'
          onChange={handleChangeTab}
        >
          {/* Необходимо из-за дефекта, когда при обновлении значения у таба появляется скролл */}
          <Tab sx={{ visibility: 'hidden', width: 0, minWidth: 0, p: 0, m: 0 }} />
          {fields.map((field, index) => (
            <Tab key={field.title} label={field.title} value={index} />
          ))}
        </Tabs>
      </Grid>

      {fields.map((field, index) => (
        <TabPanel key={field.id} index={index} value={currentTab}>
          <MenuTabPanel
            index={index}
            lineId={field.lineId}
            lineUuid={field.uuid}
            menu={lines[index].items}
            updatedElements={updatedElements}
            onRemoveLine={handleRemoveLine}
            onSetUpdatedElement={handleSetUpdatedElement}
            onSetUpdatedElements={handleSetUpdatedElements}
            onUpdateMenu={handleUpdateMenuPanelIndex}
          />
        </TabPanel>
      ))}

      <Grid container gap={1} justifyContent={'flex-end'} width={'100%'}>
        <Grid item>
          <Button disabled={!globalDirty} variant={'contained'} onClick={handleSaveControlPanel}>
            {t('controlPanelCreate.form.saveButton')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant={'outlined'} onClick={handleCancelPanel}>
            {t('controlPanelCreate.form.cancelButton')}
          </Button>
        </Grid>
      </Grid>
    </PageContentLayout>
  )
}
