import { FC, SyntheticEvent, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  tabsClasses,
  Typography,
} from '@mui/material'

import { PageContext as ConfiguredViewPageContext } from '@pages/ConfiguredEntity'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { DisplayForm } from '@components/DisplayForm'
import { TabPanel, Tabs } from '@components/Tabs'

import { FORM_IN_NEW_TAB, ROUTES } from '@constants'

import { LinkType, LinkViewHistory } from '../../../../types'
import { FormsTab } from '../FormsTab'

const contentPaddingOffset = 15

export const StaticForms: FC = () => {
  const [expanded, setExpanded] = useState<string | false>('panel1')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const {
    currentStaticForm,
    handleStaticFormChange,
    staticFormsData,
    handleRemoveSamePageForm,
    type,
    samePageFormsData,
    selectedRowIds,
    handleCloseCurrentFormTab,
    handleUpdateSamePageFormData,
    onSetOpenedContainersInStaticForms,
  } = useContext(ConfiguredViewPageContext)

  const handleBreadcrumbsMainLinkClick = (link?: LinkViewHistory) => {
    // TODO FORM_IN_NEW_TAB скорее всего вообще уже не нужен. По-моему эта логика использовалась, когда форма могла открываться в отдельной вкладке браузера
    window.name === FORM_IN_NEW_TAB ? window.close() : navigate(`/${link?.viewPath}`)
  }

  const handleObjectDataRecordSuccessCreating = (links: LinkType[]) => {
    navigate('/' + (links[0].path || params.get('path')))
  }

  const handleNavigateToDefaultPage = () => {
    navigate(ROUTES.DEFAULT_PAGE)
  }

  const handleChangeFormCode = (formCode: string, verifyFormCode: string) => {
    const newPathname = location.pathname.replace(formCode, verifyFormCode)
    navigate(`${newPathname}${location.search}`)
  }

  const [maxTabsWidth, setTabsMaxWidth] = useState(0)
  const tabsWrapperRef = useRef<HTMLDivElement | null>(null)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setTabsMaxWidth(entries[0].contentRect.width - contentPaddingOffset)
    })

    tabsWrapperRef.current && observer.observe(tabsWrapperRef.current)

    return () => {
      tabsWrapperRef.current && observer.unobserve(tabsWrapperRef.current)
    }
  }, [])

  const handleTabChange = (_e: SyntheticEvent, newValue: number) => {
    handleStaticFormChange(newValue)
  }

  const handleAccordionChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <PageContentLayout>
      <Accordion
        expanded={expanded === 'panel1'}
        sx={{ position: 'static' }}
        onChange={handleAccordionChange('panel1')}
      >
        <AccordionSummary
          aria-controls='panel1a-content'
          expandIcon={<ExpandMoreIcon />}
          id='panel1a-header'
        >
          <Typography>{t('sidebar.links.forms')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Пока лучший (единственный) найденный способ отследить максимальную ширину для tabs - ref */}
          <Box ref={tabsWrapperRef}>
            <Tabs
              scrollButtons={true}
              value={currentStaticForm}
              variant='scrollable'
              sx={{
                minHeight: '30px',
                height: '30px',
                maxWidth: maxTabsWidth,
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
              onChange={handleTabChange}
            >
              {staticFormsData.map((formData, index) => {
                return (
                  <FormsTab
                    key={formData.objectId}
                    label={`${t('groups.variant.forms.element')} ${formData.id}`}
                    tabIndex={index}
                    onIconClick={() =>
                      formData.id && handleRemoveSamePageForm(formData.id, isDirty)
                    }
                  />
                )
              })}
            </Tabs>
            {staticFormsData.map((formData, index) => (
              <TabPanel
                key={formData.objectId}
                contentSx={{ p: 0, pt: 2 }}
                index={index}
                value={currentStaticForm}
              >
                <DisplayForm
                  entityType={type}
                  handleChangeFormCode={handleChangeFormCode}
                  handleCloseCurrentFormTab={handleCloseCurrentFormTab}
                  handleNavigateToDefaultPage={handleNavigateToDefaultPage}
                  handleRemoveSamePageForm={handleRemoveSamePageForm}
                  handleUpdateSamePageFormData={handleUpdateSamePageFormData}
                  passedParams={formData}
                  samePageFormsData={samePageFormsData}
                  selectedRowIds={selectedRowIds}
                  onBreadcrumbsMainLinkClick={handleBreadcrumbsMainLinkClick}
                  onChangeDirty={setIsDirty}
                  onObjectDataRecordSuccessCreating={handleObjectDataRecordSuccessCreating}
                  onSetOpenedContainersInStaticForms={onSetOpenedContainersInStaticForms}
                  // onSetAllObjectData={onSetAllObjectData}
                  dialogId={formData.globalId}
                />
              </TabPanel>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </PageContentLayout>
  )
}
