import { ChangeEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GETSearchType, POSTCreateSearchType } from 'src/types'
import { SortDirection } from '@mui/material'

import {
  useCreateSearchMutation,
  useDeleteSearchMutation,
  useUpdateSearchTitleMutation,
} from '@redux/api'
import { closeDialog } from '@redux/reducers/dialogWindowManager.reducer'

import { useAppDispatch } from '@hooks'

import { getSortedDataByTitle } from '../helpers'
import { FavoritesFiltersTableMode } from '../SavedFilterWindow'

type UseHandlersProps = {
  dialogId: string
  savedSearch: GETSearchType[]
  mode: FavoritesFiltersTableMode
  newSearch?: POSTCreateSearchType
}

export const useHandlers = ({ dialogId, savedSearch, mode, newSearch }: UseHandlersProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [tableMode, setTableMode] = useState<FavoritesFiltersTableMode>(mode)
  const [editTitle, setEditTitle] = useState(newSearch?.title || '')
  const [sort, setSort] = useState<SortDirection>(false)
  const [selectedSearch, setSelectedSearch] = useState<GETSearchType | null>(null)
  const [searchData, setSearchData] = useState<GETSearchType[]>([])

  const [updateSearchTitle, { isLoading: updateSearchLoading }] = useUpdateSearchTitleMutation()
  const [deleteSearch, { isLoading: deleteLoading }] = useDeleteSearchMutation()
  const [saveSearch, { isLoading: createLoading }] = useCreateSearchMutation()

  const isDirty = useMemo(
    () =>
      tableMode === FavoritesFiltersTableMode.CREATE
        ? editTitle !== ''
        : editTitle !== selectedSearch?.title && tableMode === FavoritesFiltersTableMode.EDIT,
    [tableMode, editTitle, selectedSearch]
  )

  const handleCloseDialog = () => dispatch(closeDialog({ id: dialogId }))

  const handleSelect = (search: GETSearchType) => {
    if (tableMode !== FavoritesFiltersTableMode.VIEW) {
      return
    }

    if (selectedSearch?.id === search.id) {
      setSelectedSearch(null)

      return
    }

    setSelectedSearch(search)
  }

  const handleChangeEditTitle = (e: ChangeEvent<HTMLInputElement>) => setEditTitle(e.target.value)

  const handleSortChange = () => {
    if (sort === 'asc') {
      setSearchData(getSortedDataByTitle(searchData, 'desc'))
      setSort('desc')

      return
    }
    if (sort === 'desc') {
      setSearchData(savedSearch || [])
      setSort(false)

      return
    }
    if (!sort) {
      setSearchData(getSortedDataByTitle(searchData, 'asc'))
      setSort('asc')
    }
  }

  const handleEditMode = () => {
    if (selectedSearch) {
      setTableMode(FavoritesFiltersTableMode.EDIT)
      setEditTitle(selectedSearch.title)

      return
    }
  }

  const handleCancel = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }
    setTableMode(FavoritesFiltersTableMode.VIEW)
  }

  const handleSaveOrRename = () => {
    if (selectedSearch) {
      updateSearchTitle({ title: editTitle, id: selectedSearch?.id })
        .unwrap()
        .then(res => {
          setTableMode(FavoritesFiltersTableMode.VIEW)
          setSelectedSearch(res)
          setEditTitle('')
        })

      return
    }

    if (newSearch) {
      saveSearch({ ...newSearch, title: editTitle })
        .unwrap()
        .then(() => {
          setTableMode(FavoritesFiltersTableMode.VIEW)
          setEditTitle('')
        })
        .catch(error => console.error(error.message))
    }
  }

  const handleDelete = () => {
    if (selectedSearch) {
      deleteSearch(selectedSearch.id).then(() => {
        setSelectedSearch(null)
        setEditTitle('')
      })
    }
  }

  const handleSetSearchData = (searchData: GETSearchType[]) => setSearchData(searchData)

  const handleSetTableMode = (mode: FavoritesFiltersTableMode) => setTableMode(mode)

  const handleSetEditTitle = (title: string) => setEditTitle(title)

  const handleSetSelectedSearch = (search: GETSearchType | null) => setSelectedSearch(search)

  return {
    state: {
      isDirty,
      updateSearchLoading,
      deleteLoading,
      createLoading,
      searchData,
      tableMode,
      editTitle,
      sort,
      selectedSearch,
    },
    handlers: {
      handleCloseDialog,
      handleSelect,
      handleChangeEditTitle,
      handleSortChange,
      handleEditMode,
      handleCancel,
      handleSaveOrRename,
      handleDelete,
      handleSetSearchData,
      handleSetTableMode,
      handleSetEditTitle,
      handleSetSelectedSearch,
    },
  }
}
