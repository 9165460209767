import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { ItemId, TreeData, TreeItem } from '@atlaskit/tree'

import { EntityOrFormGroupList } from '../../../../components/EntityOrFormGroupList'
import { getTree, transformTreeToMenu } from '../../../../helpers'
import { useDraggableTree, usePrompt } from '../../../../hooks'
import { PageContext as ConfigFormsContext } from '../../../../pages/ConfigForms'
import {
  useFetchAllFormQuery,
  useFetchAllFormUncertainQuery,
  useFetchFormGroupsQuery,
  useUpdateFormGroupsMutation,
} from '../../../../redux/api'
import { NoIdOptionGroupType, OptionType, POSTOptionGroupType } from '../../../../types'

type FormGroupListProps = {
  onSetDirty: (value: boolean) => void
}

export const FormGroupList: FC<FormGroupListProps> = ({ onSetDirty }) => {
  const { tree, setTree, deleteTreeItem, addTreeItem } = useDraggableTree()
  const [firstTree, setFirstTree] = useState<TreeData | null>(null)
  const { data: groups, isFetching: isFetchingGroups } = useFetchFormGroupsQuery()
  const [updateGroups, { isSuccess }] = useUpdateFormGroupsMutation()
  const { data: formsData } = useFetchAllFormQuery()
  const { data: uncertainFormsData, isFetching: isFetchingUncertainForms } =
    useFetchAllFormUncertainQuery()
  const transformFirstTree = useMemo(() => firstTree && transformTreeToMenu(firstTree), [firstTree])
  const transformTree = useMemo(() => tree && transformTreeToMenu(tree), [tree])

  const isDirty = JSON.stringify(transformFirstTree) !== JSON.stringify(transformTree)

  useEffect(() => {
    onSetDirty(isDirty)
  }, [isDirty])

  usePrompt({ when: isDirty })

  const handleUpdateGroups = (groups: POSTOptionGroupType[]) =>
    updateGroups(groups)
      .unwrap()
      .then(res => {
        setTree(getTree(res as OptionType[]))
        setFirstTree(getTree(res as OptionType[]))
      })

  useEffect(() => {
    if (groups) {
      setTree(getTree(groups as OptionType[]))
      setFirstTree(getTree(groups as OptionType[]))
    }
  }, [groups, isFetchingGroups])

  const handleDeleteTreeItem = (itemId: ItemId) => {
    deleteTreeItem(itemId)
  }

  const handleEditTreeItem = (treeItem: TreeItem) => {
    if (tree) {
      const newTree: TreeData = { ...tree }
      newTree.items[treeItem.id] = { ...treeItem }
      setTree(newTree)
    }
  }

  const handleAddTreeItem = (option: NoIdOptionGroupType, treeItem?: TreeItem) => {
    addTreeItem(option, treeItem)
  }

  const handleCancel = () => {
    if (groups) {
      setTree(getTree(groups as OptionType[]))
    }
  }

  const { onDeleteFormByCode, onShowDialog, onEditForm } = useContext(ConfigFormsContext)

  return (
    <EntityOrFormGroupList
      allData={formsData?.data || []}
      isDirty={isDirty}
      isFetchingGroups={isFetchingGroups}
      isFetchingUncertain={isFetchingUncertainForms}
      setTree={setTree}
      tree={tree}
      type={'forms'}
      uncertainData={uncertainFormsData?.data || []}
      updateGroups={handleUpdateGroups}
      onAddTreeItem={handleAddTreeItem}
      onCancel={handleCancel}
      onCopy={onShowDialog}
      onDelete={onDeleteFormByCode}
      onDeleteTreeItem={handleDeleteTreeItem}
      onEdit={onEditForm}
      onEditTreeItem={handleEditTreeItem}
    />
  )
}
