import { FC, ReactNode, useEffect, useMemo } from 'react'
import { CssBaseline, PaletteMode } from '@mui/material'
import {
  createTheme,
  PaletteOptions,
  Theme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'

import { GLOBAL_PARAMETER_CODE, SCROLL_HEIGHT, SCROLL_WIDTH } from '@constants'
import { ParameterPropertyType } from '@types'

declare module '@mui/material/styles' {
  // Типизация кастомных параметров темы
  // interface Theme {}
  // interface ThemeOptions {}

  interface Palette {
    border: {
      table: '1px solid rgb(81, 81, 81)' | 'none'
      paper: '1px solid #0000001f' | 'none'
      formCardContainer: '1px solid #e0e0e0' | 'none'
      field: '1px solid #757575' | 'none'
      default: '1px solid #000000'
      scroll: '1px solid #000000'
      customTable: '1px solid rgba(224, 224, 224, 1)' | 'none'
    }
    color: {
      default: '#000' | '#fff'
      grid: '#131d2d' | '#fff'
      formCardTab: '#131d2d' | '#000'
      formCardLabel: '#000' | '#fff'
      field: '#212121' | '#fff'
      buttonDisabled: '#cdcdcd'
      formWindowHeader: '#fff'
      divider: '#757575'
      listItem: '#15110E'
      ddwCheckbox: '#757575'
      breadcrumbsActive: '#212121'
      scroll: '#bbbbbb'
      warning: '#ff9966'
    }
    boxShadow: {
      button: '0 0 0 1px #000, 0 0 0 3px #e0e8e9, 0 0 0 4px #000' | 'none'
      filterButton: '0 0 0 0.5px #000, 0 0 0 3px #E0E8E9, 0 0 0 3.5px #000, 2px 2px 6px 0px #00000040, 2px 2px 6px 0px #00000040, 2px 2px 6px 0px #00000040'
      buttonDisabled: '0 0 0 2px #e0e8e9' | 'none'
      buttonHovered: '0 0 0 1px #000, 0 0 0 3px #c2deed, 0 0 0 4px #000' | 'none'
      paper: '2px 2px 6px 0px #000000'
    }
  }

  interface PaletteOptions {
    border?: {
      table: '1px solid rgb(81, 81, 81)' | 'none'
      paper: '1px solid #0000001f' | 'none'
      formCardContainer: '1px solid #e0e0e0' | 'none'
      field: '1px solid #7d7d7d' | 'none'
      scroll: '1px solid #000000'
      default: '1px solid #000000'
    }
    color?: {
      default: '#000' | '#fff'
      grid: '#131d2d' | '#fff'
      formCardTab: '#131d2d' | '#000'
      formCardLabel: '#000' | '#fff'
      field: '#212121' | '#fff'
      buttonDisabled: '#cdcdcd'
      formWindowHeader: '#fff'
      divider: '#757575'
      listItem: '#15110E'
      ddwCheckbox: '#757575'
      breadcrumbsActive: '#212121'
      scroll: '#bbbbbb'
      warning: '#ff9966'
    }
    boxShadow?: {
      button: '0 0 0 1px #000, 0 0 0 3px #e0e8e9, 0 0 0 4px #000' | 'none'
      filterButton: '0 0 0 0.5px #000, 0 0 0 3px #E0E8E9, 0 0 0 3.5px #000, 2px 2px 6px 0px #00000040, 2px 2px 6px 0px #00000040, 2px 2px 6px 0px #00000040'
      buttonDisabled: '0 0 0 2px #e0e8e9' | 'none'
      buttonHovered: '0 0 0 1px #000, 0 0 0 3px #c2deed, 0 0 0 4px #000' | 'none'
      paper: '2px 2px 6px 0px #000000'
    }
  }

  interface TypeBackground {
    main: '#f3f3f266' | '#161a1d'
    hovered: '#e0e8e9' | '#ffffff14'
    pinned: '#fff' | '#2d353c'
    emptyRow: '#242b30' | '#fff'
    selectedRow: '#e0e8e9' | '#90caf9'
    prevSelectedRow: '#f2ebdd' | '#e0e8e9'
    updatesRow: '#74de656e' | '#73de6581'
    evenRow: '#fafafa' | '#2d353c'
    oddRow: '#fff' | '#2d353c'
    filter: '#e0e8e9' | '#2d353c'
    chip: '#e0e8e9' | '#29b6f6'
    hoveredChip: '#eadec8' | '#0288d1'
    formCardContainer: '#e0e8e9' | '#383f48'
    formCardContainerContent: '#e0e8e9' | '#2d353e'
    formCardTab: '#f3f3f280' | 'transparent'
    formCardSelectedTab: '#e0e8e9' | '#90caf9'
    formCardBehindTab: '#fff' | '#383f48'
    searchAssistantLabel: '#fff' | '#2d353e'
    button: '#fff' | '#90caf9'
    buttonHovered: '#e0e8e9' | '#29b6f6'
    fieldDisabled: '#e0e8e9'
    fieldUpdated: '#c9fbbe'
    fieldError: '#d2999a'
    scroll: '#e0e8e9'
    savedFilterRow: '#fafafa'
    evenlyRows: 'linear-gradient( to bottom,#fafafa 0%,#fafafa 20%,#fff 20%,#fff 40%, #fafafa 40%, #fafafa 60%, #fff 60%, #fff 80%, #fafafa 80%, #fafafa 100% )'
    headerTable: '#ececec'
    searchValueRow: '#ffd662'
    topMenu: string
    footer: string
  }
}

interface ThemePallete {
  mode: PaletteMode
  light?: PaletteOptions
  dark?: PaletteOptions
}

// TODO: Редизайн. группировать стили

const themes = {
  light: {
    border: {
      paper: 'none',
      table: 'none',
      formCardContainer: 'none',
      field: '1px solid #7d7d7d',
      scroll: `1px solid #000000`,
      default: '0.3px solid #000000',
      customTable: '1px solid rgba(224, 224, 224, 1)',
    },
    primary: {
      main: '#7d7d7d',
    },
    background: {
      main: '#f3f3f266',
      paper: '#fff',
      default: '#fff',
      pinned: '#fff',
      hovered: '#e0e8e9',
      selectedRow: '#e0e8e9',
      prevSelectedRow: '#f2ebdd',
      updatesRow: '#73de6581',
      evenRow: '#fafafa',
      oddRow: '#fff',
      filter: '#e0e8e9',
      chip: '#f2ebdd',
      hoveredChip: '#eadec8',
      formCardContainer: '#e0e8e9',
      formCardContainerContent: '#e0e8e9',
      formCardTab: '#f3f3f280',
      formCardSelectedTab: '#e0e8e9',
      formCardBehindTab: '#fff',
      searchAssistantLabel: '#fff',
      button: '#fff',
      buttonHovered: '#e0e8e9',
      fieldDisabled: '#e0e8e9',
      fieldUpdated: '#c9fbbe',
      fieldError: '#d2999a',
      scroll: '#e0e8e9',
      savedFilterRow: '#fafafa',
      evenlyRows:
        'linear-gradient( to bottom,#fafafa 0%,#fafafa 20%,#fff 20%,#fff 40%, #fafafa 40%, #fafafa 60%, #fff 60%, #fff 80%, #fafafa 80%, #fafafa 100% )',
      headerTable: '#ececec',
      searchValueRow: '#ffd662',
      topMenu: '#F3F3F3',
      footer: '#F3F3F3',
    },
    color: {
      default: '#000',
      grid: '#131d2d',
      formCardTab: '#131d2d',
      formCardLabel: '#000',
      field: '#212121',
      buttonDisabled: '#cdcdcd',
      formWindowHeader: '#fff',
      divider: '#757575',
      listItem: '#15110E',
      ddwCheckbox: '#757575',
      breadcrumbsActive: '#212121',
      scroll: '#bbbbbb',
      warning: '#ff9966',
    },
    boxShadow: {
      button: '0 0 0 1px #000, 0 0 0 3px #e0e8e9, 0 0 0 4px #000',
      filterButton:
        '0 0 0 0.5px #000, 0 0 0 3px #E0E8E9, 0 0 0 3.5px #000, 2px 2px 6px 0px #00000040, 2px 2px 6px 0px #00000040, 2px 2px 6px 0px #00000040',
      buttonDisabled: '0 0 0 1px #e0e8e9, 0 0 0 4px #e0e8e9',
      buttonHovered: '0 0 0 1px #000, 0 0 0 3px #c2deed, 0 0 0 4px #000',
      paper: '2px 2px 6px 0px #000000',
    },
  },
  dark: {
    border: {
      paper: '#0000001f',
      table: '1px solid rgb(81, 81, 81)',
      formCardContainer: '1px solid #e0e0e0',
      field: 'none',
    },
    primary: {
      main: '#2d353c',
    },
    background: {
      main: '#161a1d',
      paper: '#2d353c',
      default: '#161a1d',
      pinned: '#2d353c',
      hovered: '#ffffff14',
      emptyRow: '#242b30',
      selectedRow: '#90caf9',
      prevSelectedRow: '#e0e8e9',
      updatesRow: '#74de656e',
      evenRow: '#fafafa',
      oddRow: '#2d353c',
      filter: '#2d353c',
      chip: '#29b6f6',
      hoveredChip: '#0288d1',
      formCardContainer: '#2d353e',
      formCardContainerContent: '#2d353e',
      formCardTab: 'transparent',
      formCardSelectedTab: '#90caf9',
      formCardBehindTab: '#383f48',
      searchAssistantLabel: '#2d353e',
      button: '#90caf9',
      buttonHovered: '#29b6f6',
      savedFilterRow: '#fafafa',
      evenlyRows:
        'linear-gradient( to bottom,#fafafa 0%,#fafafa 20%,#fff 20%,#fff 40%, #fafafa 40%, #fafafa 60%, #fff 60%, #fff 80%, #fafafa 80%, #fafafa 100% )',
      headerTable: '#ececec',
      topMenu: '#F3F3F3',
      footer: '#F3F3F3',
    },
    color: {
      default: '#fff',
      grid: '#fff',
      formCardTab: '#000',
      formCardLabel: '#fff',
      field: '#fff',
      buttonDisabled: '#cdcdcd',
      warning: '#ff9966',
    },
    boxShadow: {
      button: 'none',
      buttonDisabled: 'none',
      buttonHovered: 'none',
    },
  },
}

const getPalette = (mode: PaletteMode): ThemePallete => {
  return {
    mode,
    ...themes[mode],
  }
}

const getMuiCssBaseline = (): { styleOverrides?: ComponentsOverrides<Theme>['MuiCssBaseline'] } => {
  return {
    styleOverrides: {
      body: {
        minWidth: 600,
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          height: `${SCROLL_HEIGHT}px`,
          width: `${SCROLL_WIDTH}px`,
          backgroundColor: themes.light.background.scroll,
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          backgroundColor: themes.light.color.scroll,
          border: themes.light.border.scroll,
        },
        '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
          backgroundColor: themes.light.background.scroll,
        },
      },
      '.MuiButtonBase-root, .MuiChip-root, .MuiInputBase-root, .MuiPopover-root > .MuiPaper-root, .MuiAutocomplete-popper > .MuiPaper-root, .PrivatePickersYear-yearButton, .react-colorful__pointer, .react-colorful__saturation, .react-colorful__hue, .MuiSwitch-root, .MuiSwitch-track, .MuiSwitch-root .MuiSwitch-thumb':
        {
          borderRadius: '0 !important',
          '.MuiTouchRipple-root *': {
            borderRadius: '0 !important',
          },
        },
      '.MuiAutocomplete-popper *::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
      },
      '.MuiAutocomplete-popper ::-webkit-scrollbar-thumb, .MuiAutocomplete-popper *::-webkit-scrollbar-thumb':
        {
          borderWidth: '1px',
        },
      '.dropdown-list ::-webkit-scrollbar-thumb, .dropdown-list *::-webkit-scrollbar-thumb': {
        borderLeft: 'none',
        borderRight: 'none',
      },
      '.MuiMenuItem-root': {
        '&:hover': {
          backgroundColor: `${themes.light.background.hovered}`,
        },
        '&:active': {
          backgroundColor: `${themes.light.background.hovered} !important`,
        },
        '&.Mui-selected': {
          backgroundColor: `${themes.light.background.hovered} !important`,
        },
        '&.Mui-focused': {
          backgroundColor: `${themes.light.background.hovered} !important`,
        },
      },
      '.MuiAutocomplete-popper .MuiAutocomplete-listbox': {
        padding: 0,
        '& > li': {
          '&:hover': {
            backgroundColor: `${themes.light.background.hovered}`,
          },
          '&:active': {
            backgroundColor: `${themes.light.background.hovered} !important`,
          },
          '&.Mui-focused': {
            backgroundColor: `${themes.light.background.hovered}`,
          },
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: `${themes.light.background.hovered}`,
        },
      },
    },
  }
}

type ThemeProviderProps = {
  children: ReactNode
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const { data: globalFontVariants }: { data: ParameterPropertyType[] } =
    useFetchParameterPropertiesQuery({
      code: GLOBAL_PARAMETER_CODE.GLOBAL_FONT_VARIANTS,
    })

  const theme = useMemo(
    () =>
      createTheme({
        palette: getPalette('light'),
        components: {
          MuiCssBaseline: getMuiCssBaseline(),
          MuiButton: {
            styleOverrides: {
              root: {
                height: '40px',
              },
            },
          },
          MuiGrid: {
            styleOverrides: {
              root: {
                '.MuiBox-root': {
                  '.MuiDataGrid-root': {
                    borderRadius: 0,
                  },
                },
              },
            },
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                background: '#fff',
                height: '18px',
                bottom: '30px !important',
                padding: '0px 4px',
                marginLeft: '14px',
                marginTop: '0 !important',
              },
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                'input[type=time]': {
                  '::-webkit-datetime-edit-hour-field:focus, ::-webkit-datetime-edit-minute-field:focus':
                    {
                      background: '#e0e8e9',
                    },
                  '::-webkit-calendar-picker-indicator': {
                    display: 'none',
                  },
                },
              },
            },
          },
        },
      }),
    []
  )

  useEffect(() => {
    handleSetAppFontsFromParameters()
  }, [globalFontVariants])

  const handleSetAppFontsFromParameters = () => {
    if (!globalFontVariants) {
      return
    }

    globalFontVariants.forEach(fontVariant => {
      const linkTag = document.createElement('link')
      const documentHead = document.querySelector('head')
      const existingLink = document.querySelector(`link[href="${fontVariant.value}"]`)

      if (!documentHead) {
        return
      }

      if (existingLink) {
        return
      }

      linkTag.rel = 'stylesheet'
      linkTag.type = 'text/css'
      linkTag.href = fontVariant.value || ''

      documentHead.appendChild(linkTag)
    })
  }

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}
