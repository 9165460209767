import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmModal,
  DataGrid,
  GridCellParams,
  GridColumns,
} from '@microservices/wiskey-react-components'
import { Grid } from '@mui/material'

import { VariableRowType } from '@pages/Variables/types'

import { generateDataGridActionsColumn } from '@helpers'
import { POSTCopyVariable } from '@types'

type TableWithActionsProps<T> = {
  columns: GridColumns<VariableRowType>
  rows: T[]
  onDelete?: (id: number) => void
  onCopy?: (data: POSTCopyVariable) => void
  onEdit: (id: number) => void
  loading?: boolean
}

export const TableWithActions = <
  T extends {
    id: number | string
    internalId: string | number
    title: string
    variableType: string
    valueType: string
  }
>({
  columns,
  rows,
  onDelete,
  onCopy,
  onEdit,
  loading,
}: TableWithActionsProps<T>) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [currentRowId, setCurrentRowId] = useState<number>()
  const { t } = useTranslation()

  const handleCopy = (params: GridCellParams<T>) => {
    const { id, internalId, title } = params.row
    onCopy?.({ variableId: id, internalId, title })
  }

  const handleEdit = (params: GridCellParams<T>) => {
    onEdit?.(params.row.id)
  }

  const handleClickDelete = (params: GridCellParams<T>) => {
    if (params.row.id) {
      setShowDeleteModal(true)
      setCurrentRowId(params.row.id)
    }
  }

  const handleDelete = () => {
    if (currentRowId) {
      onDelete?.(currentRowId)
      setShowDeleteModal(false)
    }
  }

  const handleClose = () => setShowDeleteModal(false)

  const columnsWithActions = useMemo(
    () => [
      ...columns,
      generateDataGridActionsColumn({
        onEdit: handleEdit,
        onCopy: handleCopy,
        onDelete: handleClickDelete,
      }),
    ],
    [columns]
  )

  return (
    <Grid container px={1}>
      {showDeleteModal && (
        <ConfirmModal
          actionBtnText={t('modal.delete.btn')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={showDeleteModal}
          text={t('modal.delete.warning')}
          title={t('modal.delete.title')}
          onClose={handleClose}
          onConfirm={handleDelete}
        />
      )}
      <Grid container item>
        <DataGrid
          hideFooter
          columns={columnsWithActions}
          loading={loading}
          rows={rows}
          sx={{ borderRadius: 0 }}
        />
      </Grid>
    </Grid>
  )
}
