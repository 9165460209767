import { ParameterPropertyType } from 'src/types'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

export type ReturnTransformCommonParametersData = {
  numberOfWindows: AutocompleteOption[]
  timezone: AutocompleteOption[]
  pagination: AutocompleteOption[]
}

export const transformCommonParametersData = (
  parameters: ParameterPropertyType[]
): ReturnTransformCommonParametersData => {
  const initialData: Record<string, unknown> = {}

  parameters.forEach(({ key, value }) => {
    if (value) {
      const arrValue: string[] = JSON.parse(value)
      initialData[key] = arrValue.map(value => ({ id: value.toString(), label: value.toString() }))

      return
    }
    initialData[key] = []
  })

  return initialData as ReturnTransformCommonParametersData
}
