import { useEffect } from 'react'

import { setSelectedDialog } from '@redux/reducers/dialogWindowManager.reducer'
import { onSetDialogWindowData } from '@redux/reducers/uiOverlay.reducer'

import { useAppDispatch, useAppSelector } from '@hooks'
import { FORM_TYPE } from '@constants'
import { DIALOG_WINDOW_TYPE, FormType } from '@types'

type useSetBlockingUIOverlayParams = {
  form?: FormType | undefined
  dialogId: string | undefined
  formEvent?: FORM_TYPE
  dialogType?: DIALOG_WINDOW_TYPE
}

export const useSetBlockingUIOverlay = ({
  form,
  dialogId,
  formEvent,
  dialogType,
}: useSetBlockingUIOverlayParams) => {
  const dispatch = useAppDispatch()
  const uiOverlay = useAppSelector(state => state.uiOverlay)
  const dialogWindows = useAppSelector(state => state.dialogWindowManager.dialogWindows)
  const currentDialogWindow = dialogWindows.find(dialogWindow => dialogWindow.id === dialogId)

  useEffect(() => {
    if (dialogType === DIALOG_WINDOW_TYPE.STRING_EDIT) {
      if (!dialogId) {
        return
      }
    } else if (!form || !dialogId) {
      return
    }

    // const isBlockingWindowFromBackend = form.isBlockingWindow
    // TODO: В момент написания постановка следующая: блокирующими окнами являются формы создания и редактирование полей с типом string в формах (нажать на многоточие)
    const isBlockingWindowFromBackend =
      formEvent === FORM_TYPE.CREATE || dialogType === DIALOG_WINDOW_TYPE.STRING_EDIT

    if (!isBlockingWindowFromBackend) {
      return
    }

    const initialBlockingWindowFlag = uiOverlay.dialogTreeData[dialogId].isBlockingWindow

    dispatch(
      onSetDialogWindowData({
        dialogId,
        parentDialogId: currentDialogWindow ? currentDialogWindow.parentDialogId : null,
        isBlockingWindow: true,
      })
    )

    dispatch(setSelectedDialog({ id: dialogId }))

    return () => {
      dispatch(
        onSetDialogWindowData({
          dialogId,
          parentDialogId: currentDialogWindow ? currentDialogWindow.parentDialogId : null,
          isBlockingWindow: initialBlockingWindowFlag,
        })
      )
    }
  }, [form, dialogId])
}
