import isObject from 'lodash/isObject'
import sortBy from 'lodash/sortBy'

import { SortType } from '@hooks'
import { ObjectDataRecord } from '@types'

const transformValueToMakeItComparable = (value: any) => {
  // Если имеем дело с объектом в значении (пока такое только для обогащения array fields)
  if (isObject(value)) {
    // На бэке (core) сортировка array полей работает так:
    // Каждый массив сортируется, затем по первому значению происходит основная сортировка

    // Возвращаем первое значение из отсортированного списка
    const values = Object.values(value)

    return values.length ? sortBy(values)[0] : ''
  }

  return value
}

export const compareObjectDataRecords = (
  firstObject: ObjectDataRecord,
  secondObject: ObjectDataRecord,
  sort: SortType[]
): number => {
  for (const config of sort) {
    const sortDirection = config.sort
    const [fieldRef, nestedField] = (config.sortKey || config.field).split('.')

    const firstValue = firstObject[fieldRef]
    const secondValue = secondObject[fieldRef]

    if (firstValue !== secondValue) {
      const transformedFirstValue = transformValueToMakeItComparable(firstValue)
      const transformedSecondValue = transformValueToMakeItComparable(secondValue)

      if (sortDirection === 'asc') {
        return transformedFirstValue < transformedSecondValue ? -1 : 1
      } else {
        return transformedFirstValue > transformedSecondValue ? -1 : 1
      }
    }
  }

  return 0
}
