import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { contextsValueState } from '@types'

const initialState: contextsValueState = { id: '' }

const contexts = createSlice({
  name: 'contexts',
  initialState,
  reducers: {
    setContext(state, action: PayloadAction<contextsValueState>) {
      const { id } = action.payload

      state.id = id
    },
  },
})

export default contexts.reducer
export const { setContext } = contexts.actions
