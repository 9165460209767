import { createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { PageTitle } from '@components/PageTitle'

import { VISIBLE_HIDDEN } from '@constants'

import { AllVariables } from './components/AllVariables/AllVariables'
import { CopyDialog } from './components/CopyDialog'
import { useVariables } from './hooks'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const Variables = () => {
  const { t } = useTranslation()

  const {
    data: {
      methods,
      isAllVariables,
      isLoadingVariables,
      allVariables,
      showCopyDialog,
      copyVariableData,
      isLoadingCopyVariable,
    },
    handlers: {
      handleCreateVariable,
      handleEditVariable,
      handleShowDialog,
      handleCloseDialog,
      handleCopyVariable,
      handleDeleteVariable,
    },
  } = useVariables()

  const contextValue = {
    methods,
    variableTables: allVariables,
    variablesIsLoading: isLoadingVariables,
    onCreateVariable: handleCreateVariable,
    onEditVariable: handleEditVariable,
    onShowCopyDialog: handleShowDialog,
    onCopyVariable: handleCopyVariable,
    onDeleteVariable: handleDeleteVariable,
  }

  return (
    <PageContext.Provider value={contextValue}>
      {showCopyDialog && copyVariableData && (
        <CopyDialog
          copyData={copyVariableData}
          dialogTitle={t('copying.form.dialogTitle')}
          isShow={showCopyDialog}
          loading={isLoadingCopyVariable}
          models={allVariables.map(variable => variable.internalId)}
          onClose={handleCloseDialog}
          onCopy={handleCopyVariable}
        />
      )}
      <Outlet />
      <Box sx={{ ...(!isAllVariables && VISIBLE_HIDDEN) }}>
        <PageTitle
          end={false}
          start={
            <>
              <Typography display='inline' sx={{ mr: 1, ml: 1 }} variant='h6'>
                {t('pageTitle.models')}
              </Typography>
              <Typography display='inline' variant='subtitle2'>
                {t('pageTitle.variables')}
              </Typography>
            </>
          }
        />
        <AllVariables />
      </Box>
    </PageContext.Provider>
  )
}
