import { createContext, FC } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'

import { DisplayForm } from '@components/DisplayForm'
import { OutletContext } from '@components/DisplayForm/types'

import { FORM_TYPE, ROUTES } from '@constants'

import { LinkType, LinkViewHistory, PassedFormData } from '../../types'

type PageContextType = {
  params: URLSearchParams
  handleSetSearchParams: (value: URLSearchParamsInit) => void
}

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const FormPage: FC = () => {
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()

  const handleSetSearchParams = (value: URLSearchParamsInit) => {
    setParams(value)
  }

  const handleBreadcrumbsMainLinkClick = (link?: LinkViewHistory) => {
    // TODO FORM_IN_NEW_TAB скорее всего вообще уже не нужен. По-моему эта логика использовалась, когда форма могла открываться в отдельной вкладке браузера
    if (link) {
      navigate(`/${link?.viewPath}`)
    }
  }

  const handleObjectDataRecordSuccessCreating = (links: LinkType[]) => {
    navigate('/' + (links[0].path || params.get('path')))
  }

  const handleNavigateToDefaultPage = () => {
    navigate(ROUTES.DEFAULT_PAGE)
  }

  const handleChangeFormCode = (formCode: string, verifyFormCode: string) => {
    const newPathname = location.pathname.replace(formCode, verifyFormCode)
    navigate(`${newPathname}${location.search}`)
  }

  const passedParams: PassedFormData = {
    formCode: useParams().formCode as string,
    objectCode: params.get('objectCode') as string,
    objectId: params.get('objectId') as string,
    viewCode: params.get('viewCode') as string,
    path: params.get('path') as string,
    event: params.get('event') as FORM_TYPE,
    globalId: params.get('id') as string,
    openedContainers: [],
  }

  const {
    handleUpdateSamePageFormData,
    handleRemoveSamePageForm,
    handleCloseCurrentFormTab,
    entityType,

    onSetOpenedContainersInStaticForms,

    samePageFormsData,
    selectedRowIds,
  } = useOutletContext<OutletContext>()

  return (
    <PageContext.Provider value={{ params, handleSetSearchParams }}>
      <DisplayForm
        dialogId={passedParams.globalId}
        entityType={entityType}
        handleChangeFormCode={handleChangeFormCode}
        handleCloseCurrentFormTab={handleCloseCurrentFormTab}
        handleNavigateToDefaultPage={handleNavigateToDefaultPage}
        handleRemoveSamePageForm={handleRemoveSamePageForm}
        handleUpdateSamePageFormData={handleUpdateSamePageFormData}
        samePageFormsData={samePageFormsData}
        selectedRowIds={selectedRowIds}
        onObjectDataRecordSuccessCreating={handleObjectDataRecordSuccessCreating}
        onSetOpenedContainersInStaticForms={onSetOpenedContainersInStaticForms}
        passedParams={passedParams}
        // onSetAllObjectData={onSetAllObjectData}
        onBreadcrumbsMainLinkClick={handleBreadcrumbsMainLinkClick}
      />
    </PageContext.Provider>
  )
}
