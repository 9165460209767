import { FC, MouseEvent, useState } from 'react'
import { MoreHoriz } from '@mui/icons-material'
import { Box, Grid, IconButton, ListItemText, Menu } from '@mui/material'

import { ViewShortDTO } from '../../../types'
import { IconButtons } from '../IconButtons'

type UncertainListProps = {
  isListEdit: boolean
  view: ViewShortDTO
  onDelete: () => void
  onCopy: () => void
  onEdit: () => void
}

export const UncertainList: FC<UncertainListProps> = ({
  isListEdit,
  view,
  onDelete,
  onCopy,
  onEdit,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (actionType: 'copy' | 'edit' | 'delete') => {
    switch (actionType) {
      case 'copy':
        onCopy()
        break
      case 'edit':
        onEdit()
        break
      case 'delete':
        onDelete()
        break
      default:
        break
    }

    setAnchorEl(null)
  }

  return (
    <Box>
      <Grid container flexWrap={'nowrap'} justifyContent='space-between'>
        <Grid item sx={{ maxWidth: 150, overflowX: 'hidden' }}>
          <ListItemText primary={view.title} secondary={view.code} />
        </Grid>
        <Grid item>
          <IconButton disabled={isListEdit} onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id='view-item-context-menu'
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <IconButtons
          onCopy={() => handleClose('copy')}
          onDelete={() => handleClose('delete')}
          onEdit={() => handleClose('edit')}
        />
      </Menu>
    </Box>
  )
}
