import { ObjectFieldDTO } from 'src/types'

import { FIELD_VALUE_TYPE } from '@constants'

export const generateDefaultObjectByFields = (objectFields: ObjectFieldDTO[]) => {
  const srcObj: Record<string, unknown> = {}

  objectFields.forEach(field => {
    switch (field.valueType) {
      case FIELD_VALUE_TYPE.OBJ_EMBEDDED:
      case FIELD_VALUE_TYPE.OBJ_PK_LINK:
      case FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK:
      case FIELD_VALUE_TYPE.STRING:
      case FIELD_VALUE_TYPE.DATETIME:
        srcObj[field.name] = ''
        break
      case FIELD_VALUE_TYPE.INTEGER:
      case FIELD_VALUE_TYPE.DOUBLE:
        srcObj[field.name] = 0
        break
      case FIELD_VALUE_TYPE.BOOLEAN:
        srcObj[field.name] = true
        break
      default:
        srcObj[field.name] = ''
    }
  })

  return srcObj
}
