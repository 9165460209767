import { useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { Grid } from '@mui/material'

import { PageContext } from '@pages/Variables/Variables'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { GENERATOR_INPUT_TYPE, VARIABLES_COLUMNS } from '@constants'

import { TableWithActions } from '../TableWithActions'

export const AllVariables = () => {
  const { t } = useTranslation()

  const {
    methods,
    variableTables,
    variablesIsLoading,
    onCreateVariable,
    onEditVariable,
    onShowCopyDialog,
    onDeleteVariable,
  } = useContext(PageContext)

  return (
    <PageContentLayout sx={{ pt: 1 }}>
      <Grid container alignItems='center' flexWrap='nowrap' gap={1} px={1}>
        <Grid item sm={1.1}>
          <Button sx={{ mb: 1 }} variant='contained' onClick={onCreateVariable}>
            {t(`variables.addButton`)}
          </Button>
        </Grid>
        <Grid container item flexGrow={1} flexWrap='nowrap' spacing={1}>
          <FormProvider {...methods}>
            <Grid item flexGrow={1}>
              <FormInput
                inputType={GENERATOR_INPUT_TYPE.INPUT}
                name='searchPattern'
                placeholder={t(`variables.searchPlaceholder`)}
                size='small'
              />
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
      <TableWithActions
        columns={VARIABLES_COLUMNS}
        loading={variablesIsLoading}
        rows={variableTables || []}
        onCopy={onShowCopyDialog}
        onDelete={onDeleteVariable}
        onEdit={onEditVariable}
      />
    </PageContentLayout>
  )
}
