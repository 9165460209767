import { FC, MouseEvent, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { RndResizeCallback } from 'react-rnd'
import { Grid } from '@mui/material'

import {
  FORM_ELEMENT_TEXT_ALIGN,
  FORM_ELEMENT_TYPE,
  FORM_FIELD_LABEL_POSITION,
  GRID_SIZE,
} from '../../../../../constants'
import { ResizeGridItemWrapper } from '../../../../../features/ResizeGridItemWrapper'
import { getElementPathByDroppableId, getLineById } from '../../../../../helpers'
import {
  useCreateContainerElementMutation,
  useCreateListControlFilterMutation,
  useDeleteContainerElementMutation,
  useDeleteListControlFilterMutation,
  useUpdateContainerElementMutation,
  useUpdateListControlFilterMutation,
} from '../../../../../redux/api/container.api'
import { FormContainer, FormElement, GETListControlFilter } from '../../../../../types'
import { ElementContextMenu } from '../ActionMenu/ElementContextMenu'
import { TextPopover } from '../ActionMenu/TextPopover'
import { useActionMenu } from '../ActionMenu/useActionMenu'
import { FieldsConfigContext } from '../FieldsConfig'

import { Field } from './Field'

type DraggableFieldOnFormProps = {
  element: FormElement
  index: number
  countSizeRow: number
  pathToLine: string
  width: number
}

export const DraggableFieldOnForm: FC<DraggableFieldOnFormProps> = ({
  element,
  index,
  pathToLine,
  countSizeRow,
  width,
}) => {
  const { t } = useTranslation()
  const { containers, setContainers } = useContext(FieldsConfigContext)
  const [deleteElement] = useDeleteContainerElementMutation()
  const [updateElement] = useUpdateContainerElementMutation()
  const [createElement] = useCreateContainerElementMutation()
  const [createFilter] = useCreateListControlFilterMutation()
  const [updateFilter] = useUpdateListControlFilterMutation()
  const [deleteFilter] = useDeleteListControlFilterMutation()

  const [currentSize, setCurrentSize] = useState(element.size)
  const [editingInProgress, setTextElementEditing] = useState(false)
  const [textTitle, setTextTitle] = useState('')
  const [textAlign, setTextAlign] = useState<FORM_ELEMENT_TEXT_ALIGN>(FORM_ELEMENT_TEXT_ALIGN.LEFT)
  const [elementTitleHidden, setElementTitleHidden] = useState(false)
  const [labelPosition, setLabelPosition] = useState<FORM_FIELD_LABEL_POSITION>(
    FORM_FIELD_LABEL_POSITION.LEFT
  )

  const textElementCreationInProgress = element.type === FORM_ELEMENT_TYPE.TEXT && element.id === -1
  const isSmallSize = currentSize === 1
  const elementId = `elementId:${element.id}`

  const newItemRef = useRef<HTMLDivElement>(null)

  const minWidth = useMemo(() => width / GRID_SIZE, [width])
  const remains = useMemo(
    () => GRID_SIZE - (countSizeRow - currentSize),
    [countSizeRow, currentSize]
  )

  useEffect(() => {
    if (element.title) {
      setTextTitle(element.title)
    }

    if (element.labelPosition) {
      setLabelPosition(element.labelPosition)
    }

    if (element.textAlign) {
      setTextAlign(element.textAlign)
    }

    if (element.titleHidden !== undefined) {
      setElementTitleHidden(element.titleHidden)
    }
  }, [])

  const handleDeleteFieldOnForm = () => {
    const newContainers = structuredClone(containers) as FormContainer[]
    const [containerId, tabId, lineId] = getElementPathByDroppableId(pathToLine)
    const { line } = getLineById(newContainers, containerId, tabId, lineId)

    if (!line) {
      return
    }

    line.elements.splice(index, 1)

    deleteElement(element.id)
  }

  const getSize = (width: number): number => {
    const widthSize = Math.round(width / minWidth)
    // размер если widthSize больше свободного места в строке
    const sizeLargerRemains = widthSize >= remains ? remains : widthSize
    // результат в зависимости от единицы
    return widthSize > 1 ? sizeLargerRemains : 1
  }

  const handleResize: RndResizeCallback = (e, direction, ref, delta) => {
    const result = getSize(parseInt(ref.style.width, 10))

    setCurrentSize(result)
  }

  const handleResizeStop: RndResizeCallback = (e, direction, ref, delta) => {
    const [, , lineId] = getElementPathByDroppableId(pathToLine)

    const result = getSize(parseInt(ref.style.width, 10))

    if (result !== element.size) updateElement({ ...element, size: result, lineId })
  }

  const handleSetTextElementEditing = (value: boolean) => setTextElementEditing(value)

  const {
    popoverAnchorEl,
    menuPosition,
    isPopoverOpen,
    handleOpenPopover,
    handlePopoverClose,
    handleCancelPoppedEdit,
    handleElementContextClick,
    handleCloseMenu: handleFieldMenuClose,
    handleSetMenuPosition,
    handleSetPopoverAnchorEl,
  } = useActionMenu({ handleSetEditing: handleSetTextElementEditing })

  const handleFieldClick = (e: MouseEvent<HTMLDivElement>) => {
    if (element.type !== FORM_ELEMENT_TYPE.TEXT && element.type !== FORM_ELEMENT_TYPE.FIELD) {
      return
    }

    handleElementContextClick(e)
  }

  const getLineIdAndClosePopups = () => {
    if (menuPosition) {
      handleSetMenuPosition(null)
    }

    if (popoverAnchorEl) {
      handleSetPopoverAnchorEl(null)
    }

    const newContainers = structuredClone(containers) as FormContainer[]
    const [containerIndex, tabIndex, lineIndex] = getElementPathByDroppableId(pathToLine)
    const { line } = getLineById(newContainers, containerIndex, tabIndex, lineIndex)

    return line?.id
  }

  const handleSaveTextTitle = () => {
    if (!textTitle) {
      return
    }

    const lineId = getLineIdAndClosePopups()

    if (!lineId) {
      return
    }

    const { order, size, type } = element

    const newElement = {
      order,
      size,
      type,
      title: textTitle,
      textAlign,
    }

    if (editingInProgress) {
      updateElement({
        ...newElement,
        lineId,
        id: element.id,
      })

      setTextElementEditing(false)
    }

    if (textElementCreationInProgress) {
      createElement({
        ...newElement,
        lineId,
      })
    }
  }

  const handleUpdateFieldData = ({
    labelPositionValue,
    titleHiddenValue,
  }: {
    labelPositionValue: FORM_FIELD_LABEL_POSITION
    titleHiddenValue: boolean
  }) => {
    if (element.type !== FORM_ELEMENT_TYPE.FIELD) {
      return
    }

    const lineId = getLineIdAndClosePopups()

    if (!lineId) {
      return
    }

    const { order, size, type, data, title } = element

    const newElement = {
      order,
      size,
      type,
      data,
      title,
      labelPosition: labelPositionValue,
      titleHidden: titleHiddenValue,
    }

    if (element.labelPosition !== labelPositionValue || element.titleHidden !== titleHiddenValue) {
      setLabelPosition(labelPositionValue)
      setElementTitleHidden(titleHiddenValue)

      updateElement({
        ...newElement,
        lineId,
        id: element.id,
      })
    }
  }

  const handleCancelContainerCreationOrEdit = () => {
    if (textElementCreationInProgress) {
      const newContainers = structuredClone(containers) as FormContainer[]
      const [containerIndex, tabIndex, lineIndex] = getElementPathByDroppableId(pathToLine)
      const { line } = getLineById(newContainers, containerIndex, tabIndex, lineIndex)

      if (!line) {
        return
      }

      const textElementIndex = line?.elements.findIndex(element => element.id === -1)
      line?.elements.splice(textElementIndex, 1)

      setContainers(newContainers)
    }

    if (isPopoverOpen) {
      handleCancelPoppedEdit()
    }

    setTextElementEditing(false)
  }

  const handleSetTextTitle = (value: string) => setTextTitle(value)

  const handleSetTextAlign = (value: FORM_ELEMENT_TEXT_ALIGN) => setTextAlign(value)

  const handleSetLabelPosition = (value: FORM_FIELD_LABEL_POSITION) => {
    handleUpdateFieldData({ labelPositionValue: value, titleHiddenValue: elementTitleHidden })
  }

  const handleSetElementTitleHidden = (value: boolean) => {
    handleUpdateFieldData({ labelPositionValue: labelPosition, titleHiddenValue: value })
  }

  const handleSubmitFilter = (filter: GETListControlFilter) => {
    if (filter.id) {
      updateFilter(filter)

      return
    }
    createFilter(filter)
  }

  const handleDeleteFilter = (id: number) => {
    deleteFilter(id)
  }

  return (
    <>
      <TextPopover
        displayAlignmentSettings={element.type === FORM_ELEMENT_TYPE.TEXT}
        handlePopoverClose={handlePopoverClose}
        handleSaveText={handleSaveTextTitle}
        handleSetText={handleSetTextTitle}
        handleSetTextAlign={handleSetTextAlign}
        popoverAnchorEl={textElementCreationInProgress ? newItemRef.current : popoverAnchorEl}
        staticTextTitle={element.title}
        textAlign={textAlign}
        textTitle={textTitle}
        handleCancelPoppedEdit={
          textElementCreationInProgress
            ? handleCancelContainerCreationOrEdit
            : handleCancelPoppedEdit
        }
        isPopoverOpen={
          textElementCreationInProgress ? textElementCreationInProgress : isPopoverOpen
        }
      />
      <ElementContextMenu
        displayDeleteOption={isSmallSize}
        displayEditOption={element.type === FORM_ELEMENT_TYPE.TEXT}
        displayFieldParamsOption={element.type === FORM_ELEMENT_TYPE.FIELD}
        elementTitleHidden={elementTitleHidden}
        handleDeleteFieldOnForm={handleDeleteFieldOnForm}
        handleFieldMenuClose={handleFieldMenuClose}
        handleOpenEdit={handleOpenPopover}
        handleSetElementTitleHidden={handleSetElementTitleHidden}
        handleSetLabelPosition={handleSetLabelPosition}
        labelPosition={labelPosition}
        menuPosition={menuPosition}
      />
      <Draggable draggableId={elementId} index={index}>
        {(provided, snapshot) => {
          const dragHandlePropsForContainer =
            isSmallSize && provided.dragHandleProps ? provided.dragHandleProps : undefined
          const dragHandlePropsForIcon =
            !isSmallSize && provided.dragHandleProps ? provided.dragHandleProps : undefined

          return (
            <Grid
              ref={provided.innerRef}
              item
              alignItems={'center'}
              {...provided.draggableProps}
              sx={{
                py: 0.5,
              }}
              onContextMenu={handleFieldClick}
            >
              <ResizeGridItemWrapper
                countSize={countSizeRow}
                disableResizing={element.type === FORM_ELEMENT_TYPE.VIEW}
                size={currentSize}
                width={width}
                onResize={handleResize}
                onResizeStop={handleResizeStop}
              >
                <Field
                  ref={newItemRef}
                  dragHandlePropsForContainer={dragHandlePropsForContainer}
                  dragHandlePropsForIcon={dragHandlePropsForIcon}
                  elementId={element.id}
                  elementTitle={element.title}
                  elementType={element.type}
                  filters={element.filters}
                  handleDeleteFieldOnForm={handleDeleteFieldOnForm}
                  isEditOrPopup={textElementCreationInProgress && !isPopoverOpen}
                  isSmallSize={isSmallSize}
                  textTitle={textTitle}
                  onDeleteFilter={handleDeleteFilter}
                  onSubmitFilter={handleSubmitFilter}
                />
              </ResizeGridItemWrapper>
            </Grid>
          )
        }}
      </Draggable>
    </>
  )
}
