import { MutableRefObject, useEffect, useRef } from 'react'
// import {
//   GridApiPro,
//   GridEvents,
//   GridStateColDef,
// } from '@microservices/wiskey-react-components/DataGrid'
import { GridApiPro, GridEvents, GridStateColDef } from '@microservices/wiskey-react-components'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query'
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate'

import { ExtraOptions, RESTRICTION_TYPE, RestrictionData, RestrictionDTO } from '@types'

import { getParsedFields } from '../helpers'

export const useHandleColumnDrag = (
  apiRef: MutableRefObject<GridApiPro>,
  entityId: string,
  entityRestrictions: RestrictionData | undefined,
  handleUpdateRestrictions: (
    restrictions: RestrictionDTO[]
  ) => MutationActionCreatorResult<
    MutationDefinition<
      RestrictionDTO[],
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, ExtraOptions>,
      string,
      RestrictionDTO[],
      'commonApi'
    >
  >,
  isLoadingView?: boolean
) => {
  const handleColumnOrderChange = (field: string, order: number) => {
    const { parentFieldName, fieldName } = getParsedFields(field)

    const existingRestriction =
      entityRestrictions &&
      entityRestrictions?.order?.find(restriction => restriction.fieldCode === field)

    handleUpdateRestrictions([
      {
        ...(existingRestriction ? { id: existingRestriction.id } : {}),
        fieldName,
        fieldCode: field,
        viewId: entityId as string,
        parentFieldName,
        restrictionType: RESTRICTION_TYPE.ORDER,
        value: order,
      },
    ])
  }

  const startVisibleColumns = useRef<GridStateColDef<any, any, any>[]>([])

  useEffect(() => {
    if (isLoadingView || !apiRef || !apiRef.current.subscribeEvent) {
      return
    }

    apiRef.current.subscribeEvent(GridEvents.columnHeaderDragStart, () => {
      startVisibleColumns.current = apiRef?.current?.getVisibleColumns() || []
    })

    const cleanUpCallback = apiRef.current.subscribeEvent(
      GridEvents.columnHeaderDragEnd,
      ({ field }) => {
        const newColumnOrder = apiRef.current.getColumnIndex(field)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const orderOfPrevColumn = startVisibleColumns.current[newColumnOrder]?.order

        if (isNaN(orderOfPrevColumn)) {
          return
        }

        if (entityRestrictions) {
          handleColumnOrderChange(field, orderOfPrevColumn)
        }
      }
    )

    return () => cleanUpCallback()
  }, [apiRef, entityRestrictions, isLoadingView, entityId])
}
