import { FC, FocusEvent, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import { Moment } from 'moment'
import more from '@assets/images/more.svg'
import {
  DatePickerRange,
  DateRangeValue,
  DateTimePicker,
  TextField,
} from '@microservices/wiskey-react-components'
import { Box, SelectChangeEvent } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'
import { MappedCheckbox } from '@components/MappedCheckbox'
import { SelectBoolean } from '@components/SelectBoolean'
import { EnumAutocomplete } from '@components/ui/EnumAutocomplete'
import { GroupAutocomplete } from '@components/ui/GroupAutocomplete'
import { ObjectPicker } from '@components/ui/ObjectPicker'

import { generalFieldStylesByMode, getPlaceholderByField, isObjectValueType } from '@helpers'
import {
  CriteriaStoredValue,
  DATE_RANGE_POSITION,
  FIELD_VALUE_FORMAT,
  FIELD_VALUE_TYPE,
  FILTERS_DIRTY_TYPES,
  FILTERS_SA,
  FILTERS_SA_TYPE,
  OBJECT_FIELD_TYPE,
  REGEX,
  SEARCH_FIELD_TYPE,
  ValueAddType,
  ValueType,
} from '@constants'

import { AutocompleteOption } from '../../../../../../../types'
import { getDatePickerRangePlaceholderCriteria } from '../../../helpers/getDatePickerRangePlaceholderCriteria'
import { SearchAssistantContext } from '../../../SearchAssistant'
import { RelativeDatePicker, RelativeType } from '../../RelativeDatePicker'
import {
  SearchRuleType,
  StartAdornmentFilterRules,
} from '../../StartAdornmentFilterRules/StartAdornmentFilterRules'

type InputValueProps = {
  currentValue: CriteriaStoredValue
  addValue?: (value: ValueAddType) => void
  codeCriteria: string
  fieldType?: SEARCH_FIELD_TYPE
  disabled?: boolean
  // Для obj_embedded полей нужны linkedObjectCode и objectValue
  linkedObjectCode?: string
  objectValue?: string
  inputWidth?: number
  onObjectNotFoundError?: (value: boolean) => void
}

export const InputValue: FC<InputValueProps> = ({
  addValue,
  currentValue,
  codeCriteria,
  fieldType,
  linkedObjectCode = '',
  objectValue = '',
  disabled,
  inputWidth,
  onObjectNotFoundError,
}) => {
  const { t } = useTranslation()
  const [searchRule, setSearchRule] = useState<SearchRuleType>(
    FILTERS_SA[currentValue.searchRule || FILTERS_SA_TYPE.CONTAIN].value
  )
  const disabledInput = !FILTERS_DIRTY_TYPES.includes(searchRule.id)

  const { isSearchAssistantDialogWindow, onSetDropDownWindowOpen } =
    useContext(SearchAssistantContext)

  const getDefaultValue = () => {
    switch (currentValue.valueType) {
      case FIELD_VALUE_TYPE.DATETIME:
        return null
      case FIELD_VALUE_TYPE.OBJ_EMBEDDED:
      case FIELD_VALUE_TYPE.OBJ_PK_LINK:
      case FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK:
        return []
      default:
        return ''
    }
  }
  const placeholder = useMemo(() => getPlaceholderByField(currentValue), [currentValue])
  const [value, setValue] = useState<ValueType>(getDefaultValue())

  useEffect(() => {
    setSearchRule(FILTERS_SA[currentValue.searchRule || FILTERS_SA_TYPE.CONTAIN].value)
    if (isObjectValueType(currentValue.valueType)) {
      setValue(currentValue.value ? currentValue.value : [])

      return
    }
    if (currentValue.type === OBJECT_FIELD_TYPE.ENUM) {
      setValue(currentValue.value?.toString() ? currentValue.value : null)

      return
    }

    setValue(currentValue.value)
  }, [currentValue])

  const handleBlur = (
    eventValue?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    directEventValue?: AutocompleteOption
  ) => {
    if (directEventValue) {
      if (currentValue.type === OBJECT_FIELD_TYPE.ENUM) {
        addValue?.({
          codeCriteria,
          searchRule: searchRule.id,
          value: directEventValue.id,
          valueId: currentValue.id,
        })

        return
      }
    }

    if (eventValue) {
      if (!eventValue.currentTarget.contains(eventValue.relatedTarget)) {
        if (eventValue.currentTarget.value.trim()) {
          if (currentValue.valueType === FIELD_VALUE_TYPE.STRING && isString(value)) {
            addValue?.({
              codeCriteria,
              searchRule: searchRule.id,
              value: value.trim(),
              valueId: currentValue.id,
            })

            return
          }
          addValue?.({ codeCriteria, searchRule: searchRule.id, value, valueId: currentValue.id })

          return
        }
        addValue?.({ codeCriteria, searchRule: searchRule.id, valueId: currentValue.id, value: '' })
      }
    }
  }

  const handleBlurDate = (dateValue?: string | [DateRangeValue, DateRangeValue] | null) => {
    // проверка для DateRangePicker
    if (Array.isArray(dateValue)) {
      addValue?.({
        searchRule: searchRule.id,
        codeCriteria,
        value: !dateValue[0] && !dateValue[1] ? null : dateValue,
        valueId: currentValue.id,
      })
      setValue(!dateValue[0] && !dateValue[1] ? null : dateValue)

      return
    }
    // обычный DatePicker
    if (!isUndefined(dateValue)) {
      addValue?.({
        codeCriteria,
        searchRule: searchRule.id,
        value: dateValue,
        valueId: currentValue.id,
      })
      setValue(dateValue)
    }
  }

  const handleChange = (targetValue: string) => {
    const type = currentValue.valueType

    if (
      (type === FIELD_VALUE_TYPE.INTEGER && REGEX.ONLY_NUMBER.test(targetValue)) ||
      type === FIELD_VALUE_TYPE.DOUBLE
    ) {
      addValue?.({
        codeCriteria,
        searchRule: searchRule.id,
        value: targetValue,
        valueId: currentValue.id,
      })

      setValue(targetValue ? Number(targetValue) : '')

      return
    }
    addValue?.({
      codeCriteria,
      searchRule: searchRule.id,
      value: targetValue,
      valueId: currentValue.id,
    })
    setValue(targetValue)

    return
  }

  const handleChangeDate = (dateValue?: string | [DateRangeValue, DateRangeValue]) => {
    if (dateValue) {
      setValue(dateValue)
      // if (value) {
      addValue?.({
        codeCriteria,
        searchRule: searchRule.id,
        value: dateValue,
        valueId: currentValue.id,
      })
      setValue(dateValue)
      // }
    }
  }

  const handleChangeObjEmbedded = (value: AutocompleteOption | null | AutocompleteOption[]) => {
    if (Array.isArray(value) && value.length === 0) {
      addValue?.({
        codeCriteria,
        searchRule: searchRule.id,
        value: null,
        valueId: currentValue.id,
      })

      return
    }

    addValue?.({
      codeCriteria,
      searchRule: searchRule.id,
      value,
      valueId: currentValue.id,
    })
  }

  const handleMappedCheckboxChange = (value: number) => {
    addValue?.({
      codeCriteria,
      searchRule: searchRule.id,
      value: `${value}`,
      valueId: currentValue.id,
    })
    setValue(value)
  }

  const handleChangeBooleanSelect = ({ target: { value } }: SelectChangeEvent<string | number>) => {
    addValue?.({
      codeCriteria,
      searchRule: searchRule.id,
      value: `${value}`,
      valueId: currentValue.id,
    })
    setValue(value)
  }

  const handleChangeEnum = (value: AutocompleteOption | null) => {
    setValue(value)
    addValue?.({
      codeCriteria,
      searchRule: searchRule.id,
      value: value?.id.toString() ? value.id : null,
      valueId: currentValue.id,
    })
  }

  const handleChangeGroups = (value: AutocompleteOption[] | null) => {
    if (Array.isArray(value) && value.length === 0) {
      addValue?.({
        codeCriteria,
        value: null,
        valueId: currentValue.id,
        // TODO: узнать какие правила поиска для групп
        searchRule: searchRule.id,
      })

      return
    }

    addValue?.({
      codeCriteria,
      value,
      valueId: currentValue.id,
      // TODO: узнать какие правила поиска для групп
      searchRule: searchRule.id,
    })
  }

  const handleChangeRelativeDate = (value: [DateRangeValue, string, string]) => {
    setValue(value)
    addValue?.({
      codeCriteria,
      searchRule: searchRule.id,
      value: value.some(Boolean) ? value : null,
      valueId: currentValue.id,
    })
  }

  const handleValidateValue = (value: string) => {
    if (currentValue.objectValue && REGEX.REF_BY_ID.test(currentValue.objectValue)) {
      return false
    }

    return REGEX.ASSISTANT_FORBIDDEN_CHARS.test(value)
  }

  const handleSetSearchRules = (newSearchRule: SearchRuleType) => {
    setSearchRule(newSearchRule)
    addValue?.({
      codeCriteria,
      searchRule: newSearchRule.id,
      value: currentValue.value,
      valueId: currentValue.id,
    })
  }

  const handleCheckboxReset = () => {
    addValue?.({
      codeCriteria,
      value: '',
      searchRule: FILTERS_SA_TYPE.CONTAIN,
    })
  }

  const renderInput = () => {
    if (currentValue.isGroupRelated && currentValue.linkedObjectCode) {
      return (
        <GroupAutocomplete
          disabled={Boolean(disabled) || disabledInput}
          isDialogWindow={isSearchAssistantDialogWindow}
          name={currentValue.name}
          objectCode={currentValue.linkedObjectCode}
          placeholder={placeholder}
          value={value as AutocompleteOption}
          inputProps={{
            startAdornment: (
              <StartAdornmentFilterRules
                disabled={disabled}
                fieldType={currentValue.valueType}
                name={currentValue.name}
                objectFieldType={currentValue.type}
                setValue={handleSetSearchRules}
                value={searchRule}
              />
            ),
          }}
          onChange={handleChangeGroups}
        />
      )
    }

    if (currentValue.type === OBJECT_FIELD_TYPE.ENUM) {
      return (
        <EnumAutocomplete
          disabled={disabled || disabledInput}
          fieldCode={currentValue.name}
          isDialogWindow={isSearchAssistantDialogWindow}
          name={currentValue.name}
          objectCode={currentValue.objectCode ?? ''}
          placeholder={placeholder}
          smallPopupIconPadding={false}
          value={value as AutocompleteOption}
          valueType={currentValue.valueType as FIELD_VALUE_TYPE}
          inputProps={{
            startAdornment: (
              <StartAdornmentFilterRules
                disabled={disabled}
                fieldType={currentValue.valueType}
                name={currentValue.name}
                objectFieldType={currentValue.type}
                setValue={handleSetSearchRules}
                value={searchRule}
              />
            ),
          }}
          popupIcon={
            <Box height={'100%'} width={20}>
              <CustomSvgIcon src={more} sx={{ fontSize: '1rem' }} />
            </Box>
          }
          onChange={handleChangeEnum}
        />
      )
    }

    const asCheckbox =
      currentValue.valueFormat === FIELD_VALUE_FORMAT.NUMBER ||
      currentValue.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN

    if (asCheckbox && currentValue.valueFormat) {
      let mappedValue = value

      // Нужна дополнительная проверка для value во избежание конфузов приведения строки к boolean в ассистенте
      if (value === 'false' && currentValue.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN) {
        mappedValue = false as unknown as ValueType
      }

      if (value === 'true' && currentValue.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN) {
        mappedValue = true as unknown as ValueType
      }

      return (
        <MappedCheckbox
          catchOuterValueChanges
          ignoreErrorStyles
          resetByButton
          outerValue={mappedValue as number | boolean}
          setOuterValue={value => handleMappedCheckboxChange(value as number)}
          valueFormat={currentValue.valueFormat}
          checkboxSx={{
            top: 0,
          }}
          checkboxWrapperSx={{
            ml: '1px',
          }}
          onReset={handleCheckboxReset}
        />
      )
    }

    if (currentValue.valueFormat === FIELD_VALUE_FORMAT.TIME) {
      return (
        <InputMask
          disabled={disabled || disabledInput}
          mask='99:59'
          maskChar='-'
          value={value}
          formatChars={{
            5: '[0-5]',
            9: '[0-9]',
          }}
          onBlur={(event: FocusEvent<HTMLInputElement>) =>
            handleChange(event.currentTarget?.value.replaceAll('-', '0'))
          }
          onChange={(event: FocusEvent<HTMLInputElement>) =>
            handleChange(event.currentTarget?.value)
          }
        >
          {() => (
            <TextField
              fullWidth
              disabled={disabled || disabledInput}
              placeholder={placeholder}
              size='small'
              type='text'
              InputProps={{
                inputProps: { sx: { py: 1 } },
                startAdornment: (
                  <StartAdornmentFilterRules
                    disabled={disabled}
                    fieldType={currentValue.valueType}
                    name={currentValue.name}
                    objectFieldType={currentValue.type}
                    setValue={handleSetSearchRules}
                    value={searchRule}
                  />
                ),
              }}
              sx={theme => ({
                ...generalFieldStylesByMode(
                  theme,
                  undefined,
                  undefined,
                  undefined,
                  isSearchAssistantDialogWindow
                ),
              })}
            />
          )}
        </InputMask>
      )
    }

    switch (currentValue.valueType) {
      case FIELD_VALUE_TYPE.INTEGER:
      case FIELD_VALUE_TYPE.DOUBLE:
        return (
          <TextField
            fullWidth
            disabled={disabled || disabledInput}
            placeholder={placeholder}
            size='small'
            type='number'
            value={value}
            InputProps={{
              inputProps: { sx: { py: 1 } },
              startAdornment: (
                <StartAdornmentFilterRules
                  disabled={disabled}
                  fieldType={currentValue.valueType}
                  name={currentValue.name}
                  objectFieldType={currentValue.type}
                  setValue={handleSetSearchRules}
                  value={searchRule}
                />
              ),
            }}
            sx={theme => ({
              ...generalFieldStylesByMode(
                theme,
                undefined,
                undefined,
                undefined,
                isSearchAssistantDialogWindow
              ),
            })}
            onBlur={handleBlur}
            onChange={e => handleChange(e.currentTarget?.value)}
          />
        )
      case FIELD_VALUE_TYPE.STRING:
        return (
          <TextField
            fullWidth
            disabled={disabled || disabledInput}
            placeholder={placeholder}
            size='small'
            value={value}
            InputProps={{
              inputProps: { sx: { py: 1 } },
              startAdornment: (
                <StartAdornmentFilterRules
                  disabled={disabled}
                  fieldType={currentValue.valueType}
                  name={currentValue.name}
                  objectFieldType={currentValue.type}
                  setValue={handleSetSearchRules}
                  value={searchRule}
                />
              ),
            }}
            sx={theme => ({
              ...generalFieldStylesByMode(
                theme,
                undefined,
                undefined,
                undefined,
                isSearchAssistantDialogWindow
              ),
            })}
            onBlur={handleBlur}
            onChange={e => {
              const value = e.currentTarget?.value

              if (handleValidateValue(value)) {
                handleChange(value.trim())
              }
            }}
          />
        )
      case FIELD_VALUE_TYPE.DATETIME:
        if (fieldType === SEARCH_FIELD_TYPE.RANGE_OF_VALUES) {
          const placeholderStart = getDatePickerRangePlaceholderCriteria(
            currentValue,
            DATE_RANGE_POSITION.START
          )
          const placeholderEnd = getDatePickerRangePlaceholderCriteria(
            currentValue,
            DATE_RANGE_POSITION.END
          )

          return (
            <DatePickerRange
              fullWidth
              popoverDateShiftMode
              disabled={disabled}
              placeholderEnd={placeholderEnd}
              placeholderStart={placeholderStart}
              sxPopover={{ '& > .MuiPaper-root > .MuiBox-root': { width: 500 } }}
              value={value as Moment[] | null | undefined}
              sx={theme => ({
                ...generalFieldStylesByMode(
                  theme,
                  undefined,
                  undefined,
                  undefined,
                  isSearchAssistantDialogWindow
                ),
              })}
              onBlur={handleBlurDate}
              onChange={handleChangeDate}
            />
          )
        }
        if (fieldType === SEARCH_FIELD_TYPE.RELATIVE_VALUE) {
          return (
            <RelativeDatePicker
              popoverDateShiftMode
              disabled={disabled}
              isDialogWindow={isSearchAssistantDialogWindow}
              placeholder={placeholder}
              sxPopover={{ '& > .MuiPaper-root > .MuiBox-root': { width: 500 } }}
              value={(value || [null, '', '']) as RelativeType}
              width={inputWidth}
              onChange={handleChangeRelativeDate}
            />
          )
        }

        return (
          <DateTimePicker
            fullWidth
            popoverDateShiftMode
            disabled={disabled}
            placeholder={placeholder}
            sxPopover={{ '& > .MuiPaper-root > .MuiBox-root': { width: 500 } }}
            value={(value as string) || null}
            sx={theme => ({
              ...generalFieldStylesByMode(
                theme,
                undefined,
                undefined,
                undefined,
                isSearchAssistantDialogWindow
              ),
            })}
            onBlur={handleBlurDate}
            onChange={handleChangeDate}
          />
        )
      case FIELD_VALUE_TYPE.OBJ_EMBEDDED:
      case FIELD_VALUE_TYPE.OBJ_PK_LINK:
      case FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK:
        return (
          <ObjectPicker
            multiple
            disableClearable={false}
            disabled={disabled || disabledInput}
            dropDownList={currentValue.dropDownList}
            dropDownWindow={currentValue.dropDownWindow}
            formObjectCode={currentValue.objectCode}
            handleSetDropdownWindowOpenFormState={onSetDropDownWindowOpen}
            isDialogWindow={isSearchAssistantDialogWindow}
            isDropDownList={Boolean(currentValue.dropDownList)}
            isDropDownWindow={Boolean(currentValue.dropDownWindow)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            isSearchAssistiant={true}
            name={currentValue.name}
            objectCode={linkedObjectCode}
            objectValue={objectValue}
            placeholder={placeholder}
            value={value as AutocompleteOption | null | string}
            valueId={currentValue.id}
            valueType={currentValue.valueType}
            inputProps={{
              startAdornment: (
                <StartAdornmentFilterRules
                  disabled={disabled}
                  fieldType={currentValue.valueType}
                  name={currentValue.name}
                  objectFieldType={currentValue.type}
                  setValue={handleSetSearchRules}
                  value={searchRule}
                />
              ),
            }}
            popupIcon={
              <Box height={'100%'} width={24}>
                <CustomSvgIcon src={more} sx={{ fontSize: '1rem' }} />
              </Box>
            }
            sx={{
              '.MuiAutocomplete-popupIndicator': { marginRight: '10px' },
              '& .MuiButtonBase-root': { transform: 'none' },
            }}
            onChange={handleChangeObjEmbedded}
            onChangeMultipleValueDropdownEntity={handleChangeObjEmbedded}
            onObjectNotFoundError={onObjectNotFoundError}
            onValidateInputValue={handleValidateValue}
          />
        )
      case FIELD_VALUE_TYPE.BOOLEAN:
        return (
          <SelectBoolean
            isDialog={isSearchAssistantDialogWindow}
            value={value}
            startAdornment={
              <StartAdornmentFilterRules
                disabled={disabled}
                fieldType={currentValue.valueType}
                name={currentValue.name}
                objectFieldType={currentValue.type}
                setValue={handleSetSearchRules}
                value={searchRule}
              />
            }
            onChange={handleChangeBooleanSelect}
          />
        )

      default:
        return (
          <TextField
            fullWidth
            InputProps={{ inputProps: { sx: { py: 1 } } }}
            disabled={disabled || disabledInput}
            placeholder={placeholder}
            size='small'
            value={value}
            sx={theme => ({
              ...generalFieldStylesByMode(
                theme,
                undefined,
                undefined,
                undefined,
                isSearchAssistantDialogWindow
              ),
            })}
            onBlur={handleBlur}
            onChange={e => {
              const value = e.currentTarget?.value

              if (handleValidateValue(value)) {
                handleChange(value)
              }
            }}
          />
        )
    }
  }

  return renderInput()
}
