import moment from 'moment'

import { FormType, Row } from '@types'

export const getFormTitle = (form: FormType, row: Row) => {
  if (form && form.isJsTitle && form.titleJsValue) {
    const renderValue = new Function('srcObj', 'moment', form.titleJsValue)
    try {
      const result = renderValue(row, moment)

      return `${result}`
    } catch {
      return 'undefined'
    }
  }

  return form?.title || null
}
