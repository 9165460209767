import { createContext, FC, MouseEvent, useEffect, useMemo } from 'react'
import arrowTriangle from '@assets/images/arrow-triangle.svg'
import settings from '@assets/images/setings.svg'
import { Button } from '@microservices/wiskey-react-components'
import { Box, Grid } from '@mui/material'

import { HandleSetOpenedContainersParams } from '@pages/ConfiguredEntity/types'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { CustomSvgIcon } from '@components/CustomSvgIcon'
import { JumpButtonToConfig } from '@components/JumpButtonToConfig/JumpButtonToConfig'
import { MenuPortal } from '@components/MenuPortal'

import { updateLinkTitle } from '@redux/reducers/formHistory.reducer'

import { useAppDispatch, useContextMenu } from '@hooks'
import { buttonStylesByMode, getInitialPositionWindow } from '@helpers'
import { ENTITY, FORM_TYPE, MENU_POINT_TYPES, PreFillType } from '@constants'

import {
  LinkType,
  LinkViewHistory,
  MenuPointOptionType,
  ObjectDataRecord,
  PassedFormData,
} from '../../types'
import { AppLoader } from '../AppLoader'

import { DisplayFormContent } from './components/DisplayFormContent'
import { BreadcrumbsForm } from './components'
import { getFormTitle } from './helpers'
import { useDisplayForm } from './hooks'
import { FormContextType, FormStateType } from './types'

type DisplayFormProps = {
  passedParams: PassedFormData
  onChangeDirty?: (value: boolean) => void
  onSetWindowTitle?: (title: string | null) => void
  onBreadcrumbsMainLinkClick: (link?: LinkViewHistory) => void
  onObjectDataRecordSuccessCreating: (links: LinkType[], data: ObjectDataRecord) => void
  handleNavigateToDefaultPage: () => void
  handleChangeFormCode: (formCode: string, verifyFormCode: string) => void
  handleRemoveSamePageForm: (formDataId?: number, isDirty?: boolean) => void
  handleUpdateSamePageFormData: (
    oldObjectId: string,
    newFormCode: string,
    objectCode: string,
    objectId: string
  ) => void
  handleCloseCurrentFormTab: () => void
  entityType: ENTITY
  onSetOpenedContainersInStaticForms: (params: HandleSetOpenedContainersParams) => void
  samePageFormsData: PassedFormData[] | undefined
  selectedRowIds: string[] | undefined
  skipHotKeyPress?: boolean
  dialogId?: string
  isFormDialogWindow?: boolean
  windowHeight?: number
  windowWidth?: number
  preFill?: PreFillType
  dialogMeta?: any
}

export const FormContext = createContext<FormContextType>({} as FormContextType)
export const FormStateContext = createContext<FormStateType>({} as FormStateType)

export const DisplayForm: FC<DisplayFormProps> = ({ ...props }) => {
  const {
    passedParams,
    handleRemoveSamePageForm,
    onSetOpenedContainersInStaticForms,
    // TODO необходим рефакторинг, скорее всего оставить данную логику только для статичных форм и вынести наружу
    samePageFormsData,
    selectedRowIds,
    onChangeDirty,
    skipHotKeyPress,
    windowHeight,
    windowWidth,
    onSetWindowTitle,
    dialogId,
    isFormDialogWindow = false,
  } = props
  const dispatch = useAppDispatch()

  const { data, state, handlers } = useDisplayForm({
    ...props,
  })
  const { globalId, objectId } = passedParams

  const {
    form,
    links,
    viewCode,
    isFetchingForm,
    isFetchingFormError,
    view,
    withPassedParams,
    fetchedContainers,
    isViewType,
    viewPath,
    isDirty,
    methods,
    dirtyFields,
    formCodeToFetchBy,
  } = data

  const {
    currentRow,
    isViewEdit,
    defaultValues,
    isDeleteRow,
    isDropdownWindowOpenFormState,
    initialCommonParameters,
    isLoadingRow,
    currentLink,
    isSaveByEnterDisabled,
    isSuccessUpdateObjectData,
    isPendingCreateOrUpdateForm,
  } = state

  const {
    handleDataForNewForm,
    handleSetType,
    handleOpenLink,
    handleEditOrSaveClick,
    handleCancel,
    handleResetEdit,
    handleSetDropdownWindowOpenFormState,
    reset,
    setValueOnForm,
    watch,
    handleSetSaveByEnterDisabled,
  } = handlers

  useEffect(() => {
    if (onChangeDirty) {
      onChangeDirty(isDirty)
    }
  }, [onChangeDirty, isDirty])

  const formTitle = useMemo(() => {
    if (currentLink.event === FORM_TYPE.CREATE) {
      if (form) {
        return getFormTitle(form, {})
      }
    } else {
      if (form && currentRow && !isLoadingRow && !isFetchingForm) {
        return getFormTitle(form, currentRow)
      }
    }

    return null
  }, [form, currentRow, isLoadingRow, isFetchingForm])

  useEffect(() => {
    onSetWindowTitle?.(formTitle)
    if (dialogId && formTitle) {
      dispatch(updateLinkTitle({ id: dialogId, title: formTitle }))
    }
  }, [formTitle])

  const contextValue: FormContextType = {
    dialogId,
    isDropdownWindowOpenFormState,
    handleSetDropdownWindowOpenFormState,
    form,
    globalId,
    links,
    viewCode: viewCode ?? '',
    row: currentRow ?? {},
    type: currentLink.event,
    isLoadingRow: false,
    onSetNewForm: handleDataForNewForm,
    isFetchingForm,
    isPendingCreateOrUpdateForm,
    isFetchingFormError,
    onSetType: handleSetType,
    viewPath,
    auditFieldHistory: view?.auditFieldHistory,
    isSaveByEnterDisabled,
    handleSetSaveByEnterDisabled,
    withPassedParams,
    formDataId: passedParams.id,
    containers: fetchedContainers || [],
    objectCode: currentLink.objectCode,
    openLink: handleOpenLink,
    isViewType,
    isViewEdit,
    handleEditOrSaveClick,
    handleCancel,
    onResetEdit: handleResetEdit,
    methods,
    isDirty,
    reset,
    defaultValues,
    isDeleteRow,
    objectId,
    handleRemoveSamePageForm,
    onSetOpenedContainersInStaticForms,
    samePageFormsData,
    selectedRowIds,
    skipHotKeyPress,
    initialCommonParameters,
    windowHeight,
    formTitle,
    onSetWindowTitle,
    currentLink,
    setValueOnForm,
    watch,
    isSuccessUpdateObjectData,
    formCodeToFetchBy,
  }

  const { onClickLink, onOpenContextMenu, onCloseContextMenu, position } = useContextMenu({
    dialogId: globalId,
    variantPosition: 'element',
  })
  const menuIgnoreSelectorClassName = 'menuButton'

  const handleClickContextMenuItem = (data: {
    metaData: {
      row?: ObjectDataRecord
      menuItem: MenuPointOptionType
      event: MouseEvent<HTMLDivElement>
    }
  }) => {
    const position = getInitialPositionWindow(data.metaData.event)
    if (data.metaData.menuItem.menuPointType === MENU_POINT_TYPES.LINK) {
      onClickLink(data.metaData.menuItem, position)
    }
  }

  const handleClickButtonContextMenu = (event: MouseEvent<HTMLElement>) => {
    if (!position) {
      onOpenContextMenu(event, null)
    } else {
      onCloseContextMenu()
    }
  }

  return (
    <FormContext.Provider value={contextValue}>
      {isFetchingForm ? (
        <AppLoader />
      ) : (
        <FormStateContext.Provider value={{ isDirty, isViewType, dirtyFields, isFormDialogWindow }}>
          <Grid container justifyContent={'space-between'} px={1}>
            <Grid item>
              {props.dialogId && links && (
                <BreadcrumbsForm
                  dialogId={props.dialogId}
                  links={links}
                  maxItems={4}
                  openLink={handleOpenLink}
                  windowWidth={windowWidth}
                />
              )}
            </Grid>
            <JumpButtonToConfig code={form?.code} sx={{ p: 0, mt: 1.25, mr: 0.5 }} type='form' />
            <Grid item pr={0.5}>
              {form?.contextMenu && (
                <MenuPortal
                  items={form?.contextMenu?.menuPoints}
                  position={position}
                  selectorsIgnore={[menuIgnoreSelectorClassName]}
                  dataTransfer={{
                    changes: {},
                    config: {},
                    data: {},
                    metaData: { srcObj: currentRow },
                  }}
                  onClose={onCloseContextMenu}
                  onItemClick={handleClickContextMenuItem}
                />
              )}
              <Button
                className={menuIgnoreSelectorClassName}
                disabled={!form?.contextMenu}
                sx={theme => ({
                  ...buttonStylesByMode(theme),
                  p: '0 !important',
                  height: 21,
                  width: 54,
                  minWidth: 54,
                  justifyContent: 'space-between',
                  pl: '10px',
                  pr: '6px',
                  mt: 1.5,
                  ...(position && {
                    background: theme.palette.background.buttonHovered,
                    boxShadow: theme.palette.boxShadow.buttonHovered,
                  }),
                })}
                onClick={handleClickButtonContextMenu}
              >
                <CustomSvgIcon
                  className={menuIgnoreSelectorClassName}
                  imgClassName={menuIgnoreSelectorClassName}
                  imgStyle={{}}
                  src={settings}
                  sx={{ fontSize: '1rem', ml: '8px' }}
                />
                <CustomSvgIcon
                  className={menuIgnoreSelectorClassName}
                  imgClassName={menuIgnoreSelectorClassName}
                  src={arrowTriangle}
                  sx={{ fontSize: '1rem', mr: '6px' }}
                />
              </Button>
            </Grid>
          </Grid>
          {!withPassedParams ? (
            <PageContentLayout isBreadcrumbs>
              <Box height='100%' pt={1} px={1}>
                <DisplayFormContent isDirty={isDirty} methods={methods} />
              </Box>
            </PageContentLayout>
          ) : (
            <DisplayFormContent isDirty={isDirty} methods={methods} />
          )}
        </FormStateContext.Provider>
      )}
    </FormContext.Provider>
  )
}
