import { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import {
  useDeleteViewByCodeMutation,
  useDeleteViewMutation,
  useFetchAllObjectQuery,
  useFetchAllViewQuery,
} from '@redux/api'

import { useDebounce } from '@hooks'
import { getEntityTypeObjectForRequest, getSortParamRequest } from '@helpers'
import { ENTITY, ROUTES, SEARCH_PATTERN_DELAY } from '@constants'

import { useHandlers } from './useHandlers'

export const useConfigEntities = ({ type }: { type: ENTITY }) => {
  const { pathname } = useLocation()
  const isAllEntities =
    matchPath(ROUTES.CONFIG_VIEWS, pathname) ||
    matchPath(ROUTES.CONFIG_LIST_CONTROLS, pathname) ||
    matchPath(ROUTES.CONFIG_DROP_DOWN_ENTITY, pathname)

  const [deleteView] = useDeleteViewMutation()
  const [deleteViewByCode] = useDeleteViewByCodeMutation()

  const { state, data, handlers } = useHandlers({
    deleteView,
    deleteViewByCode,
    type,
  })

  const { objectCode, searchPattern, currentSort, allEntities } = state

  const { sort } = data

  const { handleSetCurrentSort, handleSetAllEntities, handleTabChange } = handlers

  const debouncedSearchPattern = useDebounce(searchPattern, SEARCH_PATTERN_DELAY)
  const sortParam = getSortParamRequest(currentSort)

  const { data: viewsData, isFetching: isFetchingViews } = useFetchAllViewQuery(
    {
      sort: sortParam ? sortParam : undefined,
      objectCode: objectCode ? objectCode : undefined,
      searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
      ...getEntityTypeObjectForRequest(type),
    },
    { refetchOnMountOrArgChange: true }
  )
  const { data: objects } = useFetchAllObjectQuery()

  useEffect(() => {
    handleTabChange(0)
  }, [type])

  useEffect(() => {
    handleSetAllEntities(viewsData?.data || [])
  }, [viewsData])

  useEffect(() => {
    if (sort.length && JSON.stringify(sort) !== JSON.stringify(currentSort)) {
      handleSetCurrentSort(sort)
    }
  }, [sort])

  return {
    state: {
      ...state,
      isAllEntities,
      allEntities,
    },
    data: {
      ...data,
      isFetchingViews,
      objects,
    },
    handlers,
  }
}
