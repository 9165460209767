import { useEffect, useRef } from 'react'
import { BeforeCapture } from 'react-beautiful-dnd'
import { BoxModel, getBox, Position } from 'css-box-model'

type EventOptions = {
  passive?: boolean
  capture?: boolean
  // sometimes an event might only event want to be bound once
  once?: boolean
}

type EventBinding = {
  eventName: string
  fn: () => void
  options?: EventOptions
}

type UnbindFn = () => void

function getOptions(shared: EventOptions, fromBinding: EventOptions): EventOptions {
  return {
    ...shared,
    ...fromBinding,
  }
}

function bindEvents(
  el: HTMLElement,
  bindings: EventBinding[],
  sharedOptions?: EventOptions
): () => void {
  const unbindings: UnbindFn[] = bindings.map((binding: EventBinding): UnbindFn => {
    const options = getOptions(sharedOptions || {}, binding.options || {})

    el.addEventListener(binding.eventName, binding.fn, options)

    return function unbind() {
      el.removeEventListener(binding.eventName, binding.fn, options)
    }
  })

  // Return a function to unbind events
  return function unbindAll() {
    unbindings.forEach((unbind: UnbindFn) => {
      unbind()
    })
  }
}

export const useTrimming = () => {
  const clientSelectionRef = useRef<Position>({ x: 0, y: 0 })
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const unsubscribe = bindEvents(window, [
      {
        eventName: 'mousemove',
        fn: (event: MouseEvent) => {
          const current: Position = {
            x: event.clientX,
            y: event.clientY,
          }
          clientSelectionRef.current = current
        },
        options: { passive: true },
      },
    ])

    return unsubscribe
  })

  function onBeforeCapture(before: BeforeCapture) {
    window.dispatchEvent(
      new CustomEvent('onBeforeCapture', {
        detail: { before, clientSelection: clientSelectionRef.current },
      })
    )
  }

  return { onBeforeCapture }
}

export const useTrimmingDraggable = (shouldAllowTrimming: boolean, id: string) => {
  const ref = useRef<HTMLElement>(null)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const unsubscribe = bindEvents(window, [
      {
        eventName: 'onBeforeCapture',
        fn: (event: CustomEvent) => {
          // if (!useTrimming) {
          //   return;
          // }
          if (!shouldAllowTrimming) {
            return
          }

          const before: BeforeCapture = event.detail.before
          const clientSelection: Position = event.detail.clientSelection

          if (before.mode !== 'FLUID') {
            return
          }

          if (before.draggableId !== id) {
            return
          }

          const el = ref.current

          if (!el) {
            return
          }

          const box: BoxModel = getBox(el)

          // want to shrink the item to 200px wide.
          // want it to be centered as much as possible to the cursor
          const targetWidth = 250
          const halfWidth = targetWidth / 2
          const distanceToLeft = Math.max(clientSelection.x - box.borderBox.left, 0)

          el.style.width = `${targetWidth}px`

          // Nothing left to do
          if (distanceToLeft < halfWidth) {
            return
          }

          // what the new left will be
          const proposedLeftOffset: number = distanceToLeft - halfWidth
          // what the raw right value would be
          const targetRight: number = box.borderBox.left + proposedLeftOffset + targetWidth

          // how much we would be going past the right value
          const rightOverlap: number = Math.max(targetRight - box.borderBox.right, 0)

          // need to ensure that we don't pull the element past
          // it's resting right position
          const leftOffset: number = proposedLeftOffset - rightOverlap
          el.style.position = 'relative'
          el.style.left = `${leftOffset}px`
        },
      },
    ])

    return unsubscribe
  }, [shouldAllowTrimming, id])

  return ref
}
