import { useState } from 'react'

import {
  useFetchAllDictionariesQuery,
  useFetchAllDictionaryCategoriesQuery,
  useFetchDictionariesByCategoryCodeQuery,
} from '@redux/api'

import { usePagination } from '@hooks'

export const useConfigDictionary = () => {
  const [categoryId, setCategoryId] = useState<string | null>(null)
  const { page, setPage } = usePagination()

  const { data: dictionariesData, isLoading: dictionariesIsLoading } = useFetchAllDictionariesQuery(
    {
      size: 10,
      page,
    }
  )

  const { data: dictionaryCategories } = useFetchAllDictionaryCategoriesQuery()
  const { data: dictionariesByCategory } = useFetchDictionariesByCategoryCodeQuery(categoryId, {
    skip: !categoryId,
  })

  const countPage = dictionariesByCategory && categoryId ? 1 : dictionariesData?.totalCount
  const tableRows = categoryId ? dictionariesByCategory : dictionariesData?.data

  const handleSelectCategory = (catCode: string) => {
    setCategoryId(catCode)
    setPage(0)
  }

  const handleResetCategory = () => setCategoryId(null)

  const handlePageChange = (value: number) => setPage(value)

  return {
    state: {
      categoryId,
    },
    data: {
      dictionariesData,
      dictionariesIsLoading,
      dictionaryCategories,
      dictionariesByCategory,
      countPage,
      tableRows,
      page,
    },
    handlers: {
      handleSelectCategory,
      handleResetCategory,
      handlePageChange,
    },
  }
}
