import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormLabel } from '@microservices/wiskey-react-components'
import { LabelPlacement } from '@microservices/wiskey-react-components/dist/types'
import { Grid, SxProps, useTheme } from '@mui/material'

import { generalFieldStylesByMode } from '@helpers'

import { MappedCheckbox, MappedCheckboxProps } from './MappedCheckbox'

export type MappedCheckboxForFormProps = {
  mappedCheckboxProps: MappedCheckboxProps
  formRelatedProps: {
    dirtyFields?: Partial<Readonly<{ [x: string]: boolean }>>
    field?: string
    updateField?: boolean
    isDialogWindow?: boolean
    labelPosition: LabelPlacement
    label?: string
    name: string
  }
}

export const MappedCheckboxForForm: FC<MappedCheckboxForFormProps> = ({
  mappedCheckboxProps,
  formRelatedProps,
}) => {
  const { dirtyFields, field, updateField, isDialogWindow, labelPosition, label, name } =
    formRelatedProps

  const theme = useTheme()

  const { control } = useFormContext()

  const labelWidthSx: SxProps = {
    width: undefined,
    minWidth: undefined,
    flexBasis: 120,
    ...(labelPosition === 'top' && {
      width: '100%',
      flexBasis: undefined,
    }),
    '*': {
      transition: '0.2s ease-in',
    },
    fontWeight: 400,
    letterSpacing: 0.5,
    color: theme.palette.color.default,
  }

  const checkboxExtraSx: SxProps = {
    '*': {
      transition: '0.2s ease-in',
    },
    '& > .MuiFormControl-root': {
      ...generalFieldStylesByMode(theme, dirtyFields, field, updateField, isDialogWindow),
      '& .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root': {
        padding: isDialogWindow ? 0 : 1,
      },
    },
  }

  return (
    <Grid item justifyContent='space-between' sx={{ mb: 1 }}>
      <FormLabel
        label={label}
        labelPlacement={labelPosition}
        name={name}
        sx={checkboxExtraSx}
        control={
          <Controller
            control={control}
            name={name}
            render={() => {
              return <MappedCheckbox {...mappedCheckboxProps} catchOuterValueChanges />
            }}
          />
        }
        labelSx={{
          ...labelWidthSx,
        }}
      />
    </Grid>
  )
}
