import { isAvailableRuleByTypeAndNames } from '@pages/ConfiguredEntity/helpers/isAvailableRuleByTypeAndNames'

import { FIELD_VALUE_TYPE, SEARCH_RULE_TYPE } from '@constants'
import { ViewRow } from '@types'

import { getColumnFieldName, getColumnFieldNameForSort } from './getColumnFieldName'

export const getInitialColumnVisibilityModel = (
  viewColumns: ViewRow[],
  needSortKey?: boolean,
  rule?: SEARCH_RULE_TYPE
) => {
  return Object.fromEntries(
    viewColumns.map(column => {
      let isColumnVisible = rule
        ? Boolean(
            column &&
              isAvailableRuleByTypeAndNames(column, rule) &&
              column.valueType !== FIELD_VALUE_TYPE.BOOLEAN
          )
        : true

      // column.visibility из config views выше по приоритету, чем column.restrictions.show
      if (!column?.restrictions?.show && column?.restrictions?.show !== undefined) {
        isColumnVisible = false
      }

      if (!column.visibility) {
        isColumnVisible = false
      }

      return needSortKey
        ? [getColumnFieldNameForSort(column), isColumnVisible]
        : [getColumnFieldName(column), isColumnVisible]
    })
  )
}
