import { FormHistoryState, LinkFormHistory, LinkViewHistory } from 'src/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: FormHistoryState = {}
const formHistory = createSlice({
  name: 'formHistory',
  initialState,
  reducers: {
    createHistoryByForm(
      state,
      action: PayloadAction<{ id: string; parentForm: LinkFormHistory; childForm: LinkFormHistory }>
    ) {
      const { id, parentForm, childForm } = action.payload

      state[id] = { links: [parentForm, childForm], selectedIndex: 1 }
    },
    createHistoryByView(
      state,
      action: PayloadAction<{ id: string; form: LinkFormHistory; view: LinkViewHistory }>
    ) {
      const { id, form, view } = action.payload

      state[id] = { links: [view, form], selectedIndex: 1 }
    },
    addFormHistory(state, action: PayloadAction<{ id: string; form: LinkFormHistory }>) {
      const { id, form } = action.payload

      if (!state[id]) {
        state[id] = { links: [form], selectedIndex: 0 }

        return
      }

      const { selectedIndex, links } = state[id]

      if (selectedIndex !== links.length - 1) {
        const nextLink = links[selectedIndex + 1]

        // В случае, когда находимся не на последней форме
        // Если формы не совпадают
        // история ПОСЛЕ перезаписывается на новую форму
        if (
          nextLink &&
          'formCode' in nextLink &&
          (nextLink.formCode !== form.formCode || nextLink.objectId !== form.objectId)
        ) {
          state[id].links = [...links.slice(0, selectedIndex + 1), form]
          state[id].selectedIndex += 1

          return
        }

        // Если формы совпадают, то просто перемещаем текущий индекс
        if (
          'formCode' in nextLink &&
          nextLink.formCode === form.formCode &&
          nextLink.objectId === form.objectId
        ) {
          state[id].selectedIndex += 1

          return
        }
      }

      state[id].links.push(form)
      state[id].selectedIndex += 1
    },
    addViewHistory(state, action: PayloadAction<{ id: string; view: LinkViewHistory }>) {
      const { id, view } = action.payload

      if (!state[id]) {
        state[id].links = [view]

        return
      }

      state[id].links.push(view)
      state[id].selectedIndex += 1
    },
    selectIndexById(state, action: PayloadAction<{ id: string; index: number }>) {
      const { id, index } = action.payload

      if (index === 0 && !('formCode' in state[id].links[index])) {
        delete state[id]

        return
      }

      state[id].selectedIndex = index
    },
    deleteById(state, action: PayloadAction<{ id: string }>) {
      delete state[action.payload.id]
    },
    updateLinkTitle(state, action: PayloadAction<{ id: string; title: string }>) {
      const { id, title } = action.payload
      const { selectedIndex } = state[id]

      state[id].links[selectedIndex].title = title
    },
  },
})

export default formHistory.reducer
export const {
  createHistoryByView,
  createHistoryByForm,
  addFormHistory,
  addViewHistory,
  selectIndexById,
  deleteById,
  updateLinkTitle,
} = formHistory.actions
