import { ItemId, TreeItem } from '@atlaskit/tree'

export const extractParentId = (finalId: string) => {
  const ids = finalId.split('-')
  ids.pop()

  return ids.join('-')
}

export type TreeItemOption = {
  id: number
  title: string
  childrenMenu: TreeItemOption[]
  expanded?: boolean
} & Record<string, unknown>

export type CustomTreeData<T> = {
  rootId: ItemId
  items: Record<ItemId, Omit<TreeItem, 'data'> & { data: T }>
}

export class TreeBuilder {
  items: Record<ItemId, TreeItem>
  rootId: ItemId

  constructor(rootId: ItemId, item: Partial<TreeItemOption>) {
    const rootItem = this._createItem(`${rootId}`, item)
    this.rootId = rootItem.id
    this.items = {
      [rootItem.id]: rootItem,
    }
  }

  withLeaf(id: number | string, item: Partial<TreeItemOption>) {
    const leafItem = this._createItem(`${this.rootId}-${id}`, item, this.rootId)
    this._addItemToRoot(leafItem.id)
    this.items[leafItem.id] = leafItem

    return this
  }

  withSubTree(tree: typeof this) {
    const subTree = tree.build()
    this._addItemToRoot(`${this.rootId}-${subTree.rootId}`)

    Object.keys(subTree.items).forEach(itemId => {
      const finalId = `${this.rootId}-${itemId}`
      this.items[finalId] = {
        ...subTree.items[itemId],
        id: finalId,
        data: { ...subTree.items[itemId].data, treeItemParentId: extractParentId(finalId) },
        children: Array.from<ItemId>(
          new Set(subTree.items[itemId].children.map(i => `${this.rootId}-${i}`))
        ),
      }
    })

    return this
  }

  build() {
    return {
      rootId: this.rootId,
      items: this.items,
    }
  }

  _addItemToRoot(id: string) {
    const rootItem = this.items[this.rootId]
    if (!rootItem.children.includes(id)) {
      rootItem.children.push(id)
    }
    rootItem.isExpanded = !!rootItem.isExpanded
    rootItem.hasChildren = true
  }

  _createItem = (itemId: string, item: Partial<TreeItemOption>, rootId?: ItemId) => {
    return {
      id: `${itemId}`,
      children: [],
      hasChildren: false,
      isExpanded: !!item.expanded,
      isChildrenLoading: false,
      data: {
        parentId: rootId || '0',
        treeItemParentId: rootId || '0',
        title: item.title,
        ...item,
      },
    }
  }
}
