import { useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import {
  useDeleteFormByCodeMutation,
  useDeleteFormMutation,
  useFetchAllFormQuery,
  useFetchAllObjectQuery,
} from '@redux/api'

import { useDebounce } from '@hooks'
import { getSortParamRequest } from '@helpers'
import { SEARCH_PATTERN_DELAY } from '@constants'

import { ConfigRowsType } from '../../../types'

import { useHandlers } from './useHandlers'

export const useConfigForms = () => {
  const { pathname } = useLocation()
  const isAllForms = matchPath('/forms', pathname)

  const [allForms, setAllForms] = useState<ConfigRowsType[]>([])

  const [deleteForm] = useDeleteFormMutation()
  const [deleteFormByCode] = useDeleteFormByCodeMutation()

  const { state, data, handlers } = useHandlers({ deleteForm, deleteFormByCode })

  const { searchPattern, currentSort, objectCode } = state

  const { sort } = data

  const { handleSetCurrentSort } = handlers

  const debouncedSearchPattern = useDebounce(searchPattern, SEARCH_PATTERN_DELAY)
  const sortParam = getSortParamRequest(currentSort)

  const {
    data: formsData,
    isFetching: isFetchingForms,
    refetch,
  } = useFetchAllFormQuery({
    sort: sortParam ? sortParam : undefined,
    objectCode: objectCode ? objectCode : undefined,
    searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
  })
  const { data: objects } = useFetchAllObjectQuery()

  useEffect(() => {
    setAllForms(formsData?.data || [])
  }, [formsData])

  useEffect(() => {
    if (sort.length && JSON.stringify(sort) !== JSON.stringify(currentSort)) {
      handleSetCurrentSort(sort)
    }
  }, [sort])

  const handleRefreshFormTablesData = () => {
    refetch()
  }

  const handleSetAllForms = (value: ConfigRowsType[]) => setAllForms(value)

  return {
    state: {
      ...state,
      allForms,
    },
    data: {
      ...data,
      isAllForms,
      isFetchingForms,
      objects,
    },
    handlers: {
      ...handlers,
      handleRefreshFormTablesData,
      handleSetAllForms,
    },
  }
}
