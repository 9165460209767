import { FC, useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box, CircularProgress, Grid } from '@mui/material'

import { PageContext } from '@pages/EntityCreateOrEdit'

import { MODAL_TYPE } from '@constants'

import { useAddActionDialog } from './hooks'

export const AddActionDialog: FC = () => {
  const { t } = useTranslation()
  const {
    onCloseModal,
    showDialog,
    modalType,
    mainTable: { rows },
  } = useContext(PageContext)

  const { isDirty, actionInputs, methods, isLoading, handleSave } = useAddActionDialog({
    columns: rows,
  })

  return (
    <ModalWrapper
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={showDialog}
      title={`${t(`addDialog.${modalType}`)} Action`}
      onClose={() => onCloseModal(isDirty)}
      onSave={handleSave}
    >
      {isLoading && modalType === MODAL_TYPE.EDIT ? (
        <Grid container alignItems={'center'} justifyContent={'center'} my={3}>
          <CircularProgress />
        </Grid>
      ) : (
        <Box pt={1}>
          <FormProvider {...methods}>
            <form>
              <FormGenerator inputs={actionInputs} mainGridSpacing={0} />
            </form>
          </FormProvider>
        </Box>
      )}
    </ModalWrapper>
  )
}
