import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { v4 as uuid } from 'uuid'
import { ItemId, TreeData } from '@atlaskit/tree'

import { useFetchAllObjectQuery } from '@redux/api'
import {
  useCreateContextMenuMutation,
  useFetchContextMenuByIdQuery,
  useUpdateContextMenuMutation,
} from '@redux/api/contextMenu.api'

import { useDraggableTree, usePrompt, useYupValidationResolver } from '@hooks'
import { getTree, transformExpandedTreeData } from '@helpers'
import { CONTEXT_MENU_TABS, ELEMENT_TYPE_CONTEXT_MENU, OPERATION_TYPE, ROUTES } from '@constants'
import {
  AutocompleteOption,
  ContextMenuUpdatedElement,
  TreeItemMenuPointOptionType,
  UpdatedElementMenuPointType,
  UpdatedElementMenuPointTypePayload,
  UpdatedElementVariableType,
  UpdatedVariableType,
} from '@types'

import { contextMenuSchema } from '@validations'

import { AddMenuPointForm } from '../components/AddMenuPointDialog'
import { VariableType } from '../ContextMenuCreateOrEdit'

export type ConfigContextMenuForm = {
  title: string
  object: AutocompleteOption<string> | null
  variables: VariableType[]
}

export type ConfigContextMen1uForm = {
  title: string
  object: string
  variables: VariableType[]
}

export const useHandlers = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: objects } = useFetchAllObjectQuery()
  const isCreate = ROUTES.CONFIG_CONTEXT_MENU_CREATE === location.pathname
  const isEdit = Boolean(id)

  const [skipDirty, setSkipDirty] = useState(false)
  const [initialTree, setInitialTree] = useState(getTree([]))
  const [currentTab, setCurrentTab] = useState(CONTEXT_MENU_TABS.MENU_POINT)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [updatedElements, setUpdatedElements] = useState<UpdatedElementMenuPointType[]>([])
  const [updatedElementsVariables, setUpdatedElementsVariables] = useState<
    UpdatedElementVariableType[]
  >([])
  const [editTreeItemForm, setEditTreeItemForm] = useState<TreeItemMenuPointOptionType | null>(null)
  const [treeParentItemId, setTreeParentItemId] = useState<ItemId | null>(null)

  // TODO ???
  const objectsOptions: AutocompleteOption[] = useMemo(
    () => objects?.map(obj => ({ id: obj.code, label: obj.title })) || [],
    [[objects]]
  )

  const { data: currentContextMenu } = useFetchContextMenuByIdQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  })

  const [createContextMenu, { isLoading: isLoadingCreate }] = useCreateContextMenuMutation()
  const [updateContextMenu] = useUpdateContextMenuMutation()
  const resolver = useYupValidationResolver(contextMenuSchema)
  const methods = useForm<ConfigContextMenuForm>({
    defaultValues: {
      object: null,
      title: '',
      variables: [],
    },
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
  })
  const { fields, append, remove } = useFieldArray({
    name: 'variables',
    control: methods.control,
  })
  const {
    watch,
    handleSubmit,
    formState: { isDirty: isDirtyForm },
    reset,
  } = methods

  useEffect(() => {
    if (currentContextMenu && objects) {
      const { menuPoints, objectCode, ...data } = currentContextMenu
      const object = objects.find(({ code }) => code === objectCode)

      reset({
        ...data,
        object: object ? { id: object.code, label: object.title } : null,
      })

      const tree = getTree(menuPoints)
      setTree(tree)
      setInitialTree(tree)
    }
  }, [currentContextMenu, objects])

  const watchedObject = watch('object')
  const watchVariables = watch('variables')

  const handleChangeTab = (_: SyntheticEvent<Element, Event>, value: number) => {
    setCurrentTab(value)
  }

  const handleCloseMenuPointDialog = () => {
    setShowAddDialog(false)
    setEditTreeItemForm(null)
    setTreeParentItemId(null)
  }

  const handleAddItem = () => {
    append({ field: '', pathStr: '', pathArray: [] })
  }

  const handleRemoveItem = (index: number) => {
    remove(index)
  }

  const handleSetUpdatedElement = (element: UpdatedElementMenuPointType) => {
    const findIndex = updatedElements.findIndex(el => el.treeItemId === element.treeItemId)

    if (findIndex !== -1) {
      const clone = cloneDeep(updatedElements)

      clone.splice(findIndex, 1, { ...clone[findIndex], ...element })
      setUpdatedElements(clone)

      return
    }

    setUpdatedElements(prev => [...prev, element])
  }

  const { tree, setTree, deleteTreeItem, addTreeItem } = useDraggableTree(initialTree)

  const handleDeleteTreeItem = (treeItemId: ItemId, itemId?: number) => {
    deleteTreeItem(treeItemId)
    if (itemId) {
      handleSetUpdatedElement({
        treeItemId,
        operationType: OPERATION_TYPE.DELETE,
        type: ELEMENT_TYPE_CONTEXT_MENU.POINT,
        data: { id: itemId },
      })
    } else {
      setUpdatedElements(updatedElements.filter(el => el.treeItemId !== treeItemId))
    }
  }

  const handleEditTreeItem = (treeItem: TreeItemMenuPointOptionType) => {
    setEditTreeItemForm(treeItem)
  }

  const handleClickAddTreeItem = (treeItemId: ItemId, itemId?: number) => {
    setTreeParentItemId(treeItemId)
    setShowAddDialog(true)
  }

  const handleClickAddMenuPoint = () => {
    setShowAddDialog(true)
  }

  const handleDragEnd = (treeItem: TreeItemMenuPointOptionType, _order: number) => {
    const { childrenMenu, treeItemParentId, ...itemData } = treeItem.data

    handleSetUpdatedElement({
      treeItemParentId,
      treeItemId: treeItem.id,
      data: itemData,
      operationType: treeItem.data.id ? OPERATION_TYPE.UPDATE : OPERATION_TYPE.CREATE,
      type: ELEMENT_TYPE_CONTEXT_MENU.POINT,
    })
  }

  const handleSaveMenuPoint = (data: AddMenuPointForm) => {
    if (editTreeItemForm && tree) {
      const newTree: TreeData = { ...tree }
      newTree.items[editTreeItemForm.id] = {
        ...editTreeItemForm,
        data: {
          ...editTreeItemForm.data,
          ...data,
          menuPointType: data.menuPointType?.id || null,
          objectType: data.objectType?.id || null,
          value: data.value?.id,
          valueTitle: data.value?.label,
        },
      } as TreeItemMenuPointOptionType
      setTree(newTree)

      const { childrenMenu, treeItemParentId, ...itemData } =
        newTree.items[editTreeItemForm.id].data

      handleSetUpdatedElement({
        treeItemParentId,
        treeItemId: editTreeItemForm.id,
        data: itemData,
        operationType: itemData.id ? OPERATION_TYPE.UPDATE : OPERATION_TYPE.CREATE,
        type: ELEMENT_TYPE_CONTEXT_MENU.POINT,
      })

      handleCloseMenuPointDialog()

      return
    }

    const addedItem = addTreeItem(
      {
        uuid: uuid(),
        childrenMenu: [],
        ...data,
        menuPointType: data.menuPointType?.id || null,
        objectType: data.objectType?.id || null,
        value: data.value?.id,
        ...(treeParentItemId &&
          tree?.items[treeParentItemId].data.id && {
            parentId: tree?.items[treeParentItemId].data.id,
          }),
        ...(treeParentItemId &&
          tree?.items[treeParentItemId].data.uuid && {
            parentUuid: tree?.items[treeParentItemId].data.uuid,
          }),
        valueTitle: data.value?.label,
      },
      treeParentItemId ? tree?.items[treeParentItemId] : undefined
    )

    if (addedItem) {
      const { childrenMenu, treeItemParentId, ...itemData } = addedItem.data

      handleSetUpdatedElement({
        treeItemParentId,
        treeItemId: addedItem.id,
        data: itemData,
        operationType: OPERATION_TYPE.CREATE,
        type: ELEMENT_TYPE_CONTEXT_MENU.POINT,
      })
    }

    handleCloseMenuPointDialog()
  }

  const handleSaveContextMenu = handleSubmit((data: ConfigContextMenuForm) => {
    const updatedMenu: ContextMenuUpdatedElement | null =
      data.title !== currentContextMenu?.title
        ? {
            type: ELEMENT_TYPE_CONTEXT_MENU.CONTEXT,
            operationType: OPERATION_TYPE.UPDATE,
            data: {
              title: data.title,
              id: currentContextMenu?.id,
              objectCode: currentContextMenu?.objectCode,
              objectTitle: currentContextMenu?.objectTitle,
            },
          }
        : null

    const menuPoints: UpdatedElementMenuPointTypePayload[] = updatedElements.map(
      // Убираются не нужные св-ва для бэка
      ({ treeItemId, treeItemParentId, data, ...el }) => {
        const contextMenuId = currentContextMenu ? currentContextMenu.id : null
        const order =
          tree?.items[treeItemParentId || tree.rootId]?.children.findIndex(
            value => value === treeItemId
          ) || 0

        if (data) {
          return {
            ...el,
            data: {
              ...data,
              contextMenuId,
              order,
            },
          }
        }

        return el
      }
    )

    if (!currentContextMenu) {
      const object = objects?.find(({ code }) => code === data.object?.id)
      const contextMenu: ContextMenuUpdatedElement = {
        type: ELEMENT_TYPE_CONTEXT_MENU.CONTEXT,
        operationType: OPERATION_TYPE.CREATE,
        data: {
          objectCode: object?.code,
          objectTitle: object?.title,
          title: data.title,
        },
      }
      setSkipDirty(true)
      createContextMenu([contextMenu, ...menuPoints, ...updatedElementsVariables])
        .unwrap()
        .then(res => navigate(`${ROUTES.CONFIG_CONTEXT_MENU_EDIT}/${res.id}`))
        .finally(() => setSkipDirty(false))
    } else {
      const elements = [
        ...(updatedMenu ? [updatedMenu] : []),
        ...menuPoints,
        ...updatedElementsVariables,
      ]

      if (elements.length) {
        updateContextMenu({
          id: currentContextMenu.id,
          elements: [
            ...(updatedMenu ? [updatedMenu] : []),
            ...menuPoints,
            ...updatedElementsVariables,
          ],
        })
          .unwrap()
          .then(() => {
            setUpdatedElements([])
            setUpdatedElementsVariables([])
          })
      }
    }
  })

  const handleSetUpdatedElementVariables = (element: UpdatedElementVariableType) => {
    const findIndex = updatedElementsVariables.findIndex(
      el => el.data?.field === element.data?.field
    )

    if (findIndex !== -1) {
      const clone = cloneDeep(updatedElementsVariables)

      clone.splice(findIndex, 1, { ...clone[findIndex], ...element })
      setUpdatedElementsVariables(clone)

      return
    }

    setUpdatedElementsVariables(prev => [...prev, element])
  }

  const handleDeleteVariable = (variable: UpdatedVariableType) => {
    if (variable.id) {
      handleSetUpdatedElementVariables({
        operationType: OPERATION_TYPE.DELETE,
        type: ELEMENT_TYPE_CONTEXT_MENU.VARIABLE,
        data: currentContextMenu ? { ...variable, contextMenuId: currentContextMenu.id } : variable,
      })

      return
    }

    setUpdatedElementsVariables(
      updatedElementsVariables.filter(savedVar => savedVar.data?.field !== variable.field)
    )
  }

  const handleSaveVariable = (variable: UpdatedVariableType) => {
    handleSetUpdatedElementVariables({
      operationType: variable.id ? OPERATION_TYPE.UPDATE : OPERATION_TYPE.CREATE,
      type: ELEMENT_TYPE_CONTEXT_MENU.VARIABLE,
      data: currentContextMenu ? { ...variable, contextMenuId: currentContextMenu.id } : variable,
    })
  }

  const handleCancelContextMenu = () => {
    if (globalDirty && !confirm(t('notifications.leave'))) {
      return
    }

    reset()
    setUpdatedElements([])
    setUpdatedElementsVariables([])
    setEditTreeItemForm(null)
    setTreeParentItemId(null)

    navigate(ROUTES.CONFIG_CONTEXT_MENU)
  }

  const transformFirstTree = useMemo(
    () => transformExpandedTreeData(getTree(currentContextMenu?.menuPoints || [])),
    [currentContextMenu]
  )
  const transformTree = useMemo(() => (tree ? transformExpandedTreeData(tree) : null), [tree])

  const isDirtyTree = useMemo(
    () => !isEqual(transformFirstTree, transformTree),
    [transformFirstTree, transformTree]
  )
  const globalDirty = useMemo(
    () => isDirtyTree || isDirtyForm || Boolean(updatedElementsVariables.length),
    [isDirtyTree, isDirtyForm, updatedElementsVariables]
  )

  const isLeaveEdit =
    globalDirty && !isLoadingCreate && !isCreate && !!currentContextMenu && !skipDirty
  const isLeaveCreate = globalDirty && isCreate && !isEdit && !isLoadingCreate && !skipDirty

  usePrompt({ when: isLeaveEdit || isLeaveCreate })

  return {
    data: {
      objects,
      currentContextMenu,
    },
    state: {
      methods,
      currentTab,
      showAddDialog,
      updatedElements,
      editTreeItemForm,
      treeParentItemId,
      watchedObject,
      fields,
      watchVariables,
      tree,
      objectsOptions,
      globalDirty,
    },
    handlers: {
      setTree,
      handleDeleteTreeItem,
      handleEditTreeItem,
      handleClickAddTreeItem,
      handleClickAddMenuPoint,
      handleDragEnd,
      handleSaveMenuPoint,
      handleSaveContextMenu,
      handleChangeTab,
      handleCloseMenuPointDialog,
      handleAddItem,
      handleRemoveItem,
      handleSetUpdatedElementVariables,
      handleDeleteVariable,
      handleSaveVariable,
      handleCancelContextMenu,
    },
  }
}
