import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import {
  useCreateOrUpdateCriteriaMutation,
  useDeleteCriteriaMutation,
  useFetchAllObjectQuery,
  useFetchContextViewsByIdQuery,
  useFetchCriteriaByIdMutation,
  useFetchCriteriasListQuery,
  useFetchDefaultViewSAQuery,
  useFetchSearchAssistantByContextIdQuery,
  useUpdateDefaultViewSAMutation,
  useUpdateVisibilityByIdMutation,
} from '@redux/api'

import { generateDataGridActionsColumn } from '@helpers'
import { SEARCH_ASSISTANT_TABLE_COLUMNS } from '@constants'

import { AutocompleteOption } from '../../../../../types'

import { useHandlers } from './useHandlers'

type FormValues = {
  objects: AutocompleteOption | null
  view: AutocompleteOption | null
}

export const useSearchAssistant = () => {
  const { id: contextId } = useParams()

  const [createOrUpdateCriteria] = useCreateOrUpdateCriteriaMutation()
  const [deleteCriteria] = useDeleteCriteriaMutation()
  const [updateVisibility, { isLoading: isLoadingUpdateVisibility }] =
    useUpdateVisibilityByIdMutation()
  const [fetchCriteria, { data: row, isLoading }] = useFetchCriteriaByIdMutation()
  const { data: objects } = useFetchAllObjectQuery()
  const [updateDefaultViewSA] = useUpdateDefaultViewSAMutation()
  const { data: defaultView } = useFetchDefaultViewSAQuery(
    {
      contextId: contextId ? contextId : '',
    },
    { skip: !contextId }
  )

  const { data: contextViewsById, isLoading: isLoadingContextViewsById } =
    useFetchContextViewsByIdQuery(contextId, {
      skip: !contextId,
      refetchOnMountOrArgChange: true,
    })

  const methods = useForm<FormValues>({
    defaultValues: { view: null, objects: null },
  })

  const {
    watch,
    reset,
    formState: { isDirty },
  } = methods
  const watchedView = watch('view')
  const watchedObject = watch('objects')

  const objectsValues = useMemo(
    () =>
      objects?.map(obj => ({
        id: obj.code,
        label: obj.title,
      })),
    [objects]
  )

  const viewsOptions = useMemo(() => {
    const views = contextViewsById?.selectedViews

    if (!views) {
      return []
    }

    return views?.map(({ id, title }) => ({ id, label: title }))
  }, [contextViewsById])
  const viewsValue = useMemo(
    () => viewsOptions?.find(({ id }) => id === defaultView?.id) || null,
    [viewsOptions, defaultView]
  )

  const { state, handlers } = useHandlers({
    fetchCriteria,
    deleteCriteria,
    updateVisibility,
  })

  const { templateTable, sortParam, objectCodeState } = state

  const { data: contextSA } = useFetchSearchAssistantByContextIdQuery(
    { contextId: contextId ? contextId : '' },
    { skip: !contextId }
  )

  const { data: templateData, isLoading: loadingData } = useFetchCriteriasListQuery(
    {
      searchAssistantId: contextSA?.id,
      sort: sortParam,
      objectCode: objectCodeState,
    },
    { skip: !contextSA, refetchOnMountOrArgChange: true }
  )

  const countPage = templateData?.totalCount || 1

  const {
    handleSetEditRow,
    handleSetCriteriaTable,
    handleChangeObjectCode,
    handleEdit,
    handleClickDelete,
  } = handlers

  useEffect(() => {
    if (row) {
      handleSetEditRow(row)
    }
  }, [row])

  useEffect(() => {
    handleSetCriteriaTable(templateData?.data || [])
  }, [templateData])

  useEffect(() => {
    const objectCode = watchedObject?.id ? `${watchedObject.id}` : null
    handleChangeObjectCode(objectCode)
  }, [watchedObject])

  useEffect(() => {
    if (watchedView && isDirty) {
      updateDefaultViewSA({ contextId, viewId: watchedView?.id })
    }
  }, [watchedView, isDirty])

  useEffect(() => {
    reset({ view: viewsValue })
  }, [viewsValue])

  const columnsWithActions = useMemo(
    () => [
      ...SEARCH_ASSISTANT_TABLE_COLUMNS,
      generateDataGridActionsColumn({
        onEdit: handleEdit,
        onDelete: handleClickDelete,
      }),
    ],
    []
  )

  return {
    data: {
      countPage,
      loadingData,
      isLoading,
      objectsValues,
      methods,
      objects,
      columns: columnsWithActions,
      contextId,
      contextSA,
    },
    state: {
      isLoadingUpdateVisibility,
      viewsOptions,
      watchedView,
      viewsValue,
      ...state,
    },
    handlers,
    mutations: {
      createOrUpdateCriteria,
    },
  }
}
