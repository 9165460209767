import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { GridSortModel } from '@mui/x-data-grid'

import { useCopyViewMutation } from '@redux/api'

import { useSorting } from '@hooks'
import { ENTITY, ENTITY_PATH, ROUTES } from '@constants'

import {
  ConfigRowsType,
  CopyModelDataType,
  DataOfCopiedModelType,
  ViewShortDTO,
} from '../../../types'
import { deleteEntityType, deleteViewByCodeType } from '../types'

type useHandlersParams = {
  deleteView: deleteEntityType
  deleteViewByCode: deleteViewByCodeType
  type: ENTITY
}

export const useHandlers = ({ deleteView, deleteViewByCode, type }: useHandlersParams) => {
  const navigate = useNavigate()
  const { sort, setSort } = useSorting()
  const [objectCode, setObjectCode] = useState<string>()
  const [searchPattern, setSearchPattern] = useState<string>()
  const [currentSort, setCurrentSort] = useState<GridSortModel>(sort || [])
  const [currentTab, setCurrentTab] = useState(0)
  const [isDirty, setDirty] = useState(false)
  const [allEntities, setAllEntities] = useState<ViewShortDTO[]>([])
  const [showCopyDialog, setShowCopyDialog] = useState(false)
  const [copyViewData, setCopyViewData] = useState<DataOfCopiedModelType | null>(null)
  const [copyEntity, { isLoading: isLoadingCopyEntity }] = useCopyViewMutation()

  const handleDeleteEntity = (id: string | number) => deleteView(id)

  // TODO привести удаление к одному методу
  const handleDeleteEntityByCode = (code: string) => deleteViewByCode(code)

  const handleEditEntity = (code: string) => {
    navigate(`${ROUTES[`CONFIG_${ENTITY_PATH[type]}_EDIT`]}/${code}`)
  }

  const handleSetSorting = (value: GridSortModel | null) => {
    setSort(value ? `${value[0].field},${value[0].sort}` : undefined)
    setCurrentSort(value ? value : [])
  }

  const handleSetObjectCode = (code: string) => setObjectCode(code)

  const handleSetSearchPattern = (pattern: string) => {
    setSearchPattern(pattern)
  }

  const handleTabChange = (value: number) => {
    if (isDirty && confirm(t('notifications.leave'))) {
      setCurrentTab(value)
      setDirty(false)

      return
    }

    if (!isDirty) {
      setCurrentTab(value)
    }
  }

  const handleSetDirty = (value: boolean) => setDirty(value)

  const handleSetCurrentSort = (value: GridSortModel) => setCurrentSort(value)

  const handleSetAllEntities = (value: ConfigRowsType[]) => setAllEntities(value)

  const handleShowDialog = (data: ConfigRowsType) => {
    const { id, objectCode, title, objectTitle } = data

    setCopyViewData({ id, objectCode, title, objectTitle })
    setShowCopyDialog(true)
  }

  const handleCloseDialog = () => {
    setCopyViewData(null)
    setShowCopyDialog(false)
  }

  const handleCopyEntity = (data: CopyModelDataType) => {
    const { code, title, copyId: copyEntityId } = data
    copyEntity({ code, title, copyViewId: copyEntityId })
      .unwrap()
      .then(res => {
        setShowCopyDialog(false)
        navigate(`${ROUTES[`CONFIG_${ENTITY_PATH[type]}_EDIT`]}/${res.code}`)
      })
  }

  return {
    state: {
      objectCode,
      searchPattern,
      currentSort,
      currentTab,
      isDirty,
      allEntities,
      showCopyDialog,
      copyViewData,
      isLoadingCopyEntity,
    },
    data: {
      sort,
    },
    handlers: {
      handleDeleteEntity,
      handleDeleteEntityByCode,
      handleCopyEntity,
      handleEditEntity,
      handleSetSorting,
      handleSetObjectCode,
      handleSetSearchPattern,
      handleSetDirty,
      handleSetCurrentSort,
      handleSetAllEntities,
      handleTabChange,
      handleShowDialog,
      handleCloseDialog,
    },
  }
}
