import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { Chip, FormGenerator, useGridApiRef } from '@microservices/wiskey-react-components'
// import { DataGrid } from '@microservices/wiskey-react-components/DataGrid'
import { DataGrid } from '@microservices/wiskey-react-components'
import { DataGridProProps as MUIDataGridProps } from '@microservices/wiskey-react-components'
import { Grid, useTheme } from '@mui/material'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'

import { useDisplayFormState } from '@components/DisplayForm/hooks'

import { useFetchObjectFieldChangesMutation } from '@redux/api'

import { ScrollingParams, useInfiniteScroll } from '@hooks'
import { generalFieldStylesByMode } from '@helpers'
import { BIND_TYPE, GENERATOR_INPUT_TYPE, OBJECT_FIELD_CHANGES_COLUMNS } from '@constants'
import { AutocompleteOption, GETObjectFieldChangesParams, ObjectFieldChangesDTO } from '@types'

import { FormContext } from '../../DisplayForm'

export const ObjectFieldChangesTable: FC = () => {
  const theme = useTheme()

  const { currentLink, form, isSuccessUpdateObjectData } = useContext(FormContext)
  const { isFormDialogWindow } = useDisplayFormState()

  const [historyPage, setHistoryPage] = useState(0)

  const methods = useForm<{ fields: AutocompleteOption[] }>({ defaultValues: { fields: [] } })
  const { watch } = methods
  const watchField = watch('fields')

  const fieldChangesGridApiRef = useGridApiRef()

  const {
    combinedData: fieldChanges,
    readMore,
    refresh,
    currentFilter,
    currentSort,
    isLoading: areFieldChangesFetching,
    totalPages,
  } = useInfiniteScroll<GETObjectFieldChangesParams, ObjectFieldChangesDTO>(
    useFetchObjectFieldChangesMutation,
    {
      objectCode: currentLink.objectCode,
      objectId: currentLink.objectId,
    }
  )

  useEffect(() => {
    handleSetFieldsForFilter(watchField.length > 0 ? watchField : undefined)
  }, [watchField])

  useEffect(() => {
    if (isSuccessUpdateObjectData) {
      setHistoryPage(0)
      handleRefreshFieldChanges()
    }
  }, [isSuccessUpdateObjectData])

  const fieldOptions = useMemo(
    () =>
      form?.fields
        ?.filter(field => field.bindType === BIND_TYPE.FIELD)
        .map(field => ({ id: Number(field.id), label: field.value })) || [],
    [form]
  )

  const columns: GridColumns<ObjectFieldChangesDTO> = OBJECT_FIELD_CHANGES_COLUMNS.map(column => ({
    ...column,
    renderCell: params => {
      if (column.field === 'field') {
        return (
          <Chip
            label={params.value}
            sx={{ height: isFormDialogWindow ? '19px' : '23px' }}
            variant='outlined'
          />
        )
      }

      return <>{params.value}</>
    },
  }))

  // TODO Костыль. Нужно переходить на курсорную пагинацию
  const allHistoryChanges = useMemo(
    () =>
      fieldChanges.map(field => ({
        ...field,
        id: uuid(),
      })),
    [fieldChanges]
  )

  const handleRefreshFieldChanges = (params?: Omit<ScrollingParams, 'page'>) => {
    refresh(params)

    fieldChangesGridApiRef.current.scrollToIndexes &&
      fieldChangesGridApiRef.current.scrollToIndexes({ rowIndex: 0 })
  }

  const handleSetFieldsForFilter = (fields?: AutocompleteOption[]) => {
    if (!currentFilter && !fields) {
      return
    }
    handleRefreshFieldChanges({
      filter: fields?.map(field => `field==${field.label}`).join(' or '),
    })
  }

  const handleHistoryPageChange: MUIDataGridProps['onRowsScrollEnd'] = () => {
    readMore()
  }

  const handleSetHistorySorting = (value: GridSortModel | null) => {
    const isSorting = value && value.length
    handleRefreshFieldChanges({ sort: isSorting ? value : [] })
  }

  return (
    <Grid container sx={{ width: '100%', px: 1 }}>
      <FormProvider {...methods}>
        <FormGenerator
          inputs={[
            {
              name: 'fields',
              inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
              label: 'Fields',
              autocompleteOptions: fieldOptions,
              multiple: true,
              sx: {
                '& + .MuiTypography-root': {
                  color: theme.palette.color.field,
                },
                '& > .MuiFormControl-root': {
                  ...generalFieldStylesByMode(
                    theme,
                    undefined,
                    undefined,
                    undefined,
                    isFormDialogWindow
                  ),
                },
              },
            },
          ]}
        />
      </FormProvider>
      <DataGrid
        {...(isFormDialogWindow && { headerHeight: 24 })}
        {...(isFormDialogWindow && { rowHeight: 24 })}
        hideFooter
        apiRef={fieldChangesGridApiRef}
        columns={columns}
        disableColumnResize={false}
        fetchNextPage={handleHistoryPageChange}
        loading={areFieldChangesFetching}
        page={historyPage}
        pageCount={totalPages}
        pageSize={5}
        rows={allHistoryChanges ? allHistoryChanges : []}
        sortModel={currentSort}
        sortingMode={'server'}
        onSortModelChange={handleSetHistorySorting}
      />
    </Grid>
  )
}
