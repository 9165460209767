import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormLabel } from '@microservices/wiskey-react-components'
import { Box, FormHelperText, useTheme } from '@mui/material'

import { ErrorValidJSType } from '@helpers'

import { EditorBase } from './EditorBase'

type ScriptValueEditorProps = {
  value: string
  onChange: (value: string) => void
  label?: string
  language?: 'js' | 'json'
  hint?: string
  validator?: (value: string) => ErrorValidJSType | undefined
  onAfterValidationScript?: (state: boolean) => void
  placeholder?: string
  noValidate?: boolean
  maxInputLength?: number
  hasError?: boolean
}

export const ScriptValueEditor = ({
  label,
  language = 'js',
  hint,
  onAfterValidationScript,
  hasError = false,
  ...otherProps
}: ScriptValueEditorProps) => {
  const theme = useTheme()
  const [invalid, setInvalid] = useState(false)

  const getStyle = useMemo(() => {
    return {
      width: '100%',
      border: invalid ? `1px solid ${theme.palette.error.main}` : theme.palette.border.field,
      transition: 'all .5s ease-in-out',
      flexGrow: 1,
    }
  }, [theme, invalid])

  const handleSetInvalid = useCallback((state: boolean) => {
    setInvalid(state)
    onAfterValidationScript?.(state)
  }, [])

  useEffect(() => {
    if (hasError) {
      setInvalid(true)
    } else {
      setInvalid(false)
    }
  }, [hasError])

  return (
    <>
      <Box display='flex'>
        <FormLabel
          label={label}
          name={''}
          control={
            <Box flexGrow={1}>
              <EditorBase
                invalid={invalid}
                language={language}
                style={getStyle}
                onInvalid={handleSetInvalid}
                {...otherProps}
              />
              {hint && <FormHelperText sx={{ pt: 0.5, pl: 0, ml: 0 }}>{hint}</FormHelperText>}
            </Box>
          }
        />
      </Box>
    </>
  )
}
