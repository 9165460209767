import { GETSearchType } from 'src/types'
import { SortDirection } from '@mui/material'

export const getSortedDataByTitle = (data: GETSearchType[], direction: SortDirection) => {
  const copyData = [...data]

  copyData.sort((a, b) => {
    const titleA = a.title.toLowerCase()
    const titleB = b.title.toLowerCase()

    if (direction === 'desc') {
      return titleA > titleB ? -1 : 1
    }

    return titleA < titleB ? -1 : 1
  })

  return copyData
}
