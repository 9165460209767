import { forwardRef, MouseEvent, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteOption, ParameterPropertyType } from 'src/types'
import { AutoComplete, TextField } from '@microservices/wiskey-react-components'
import {
  Add as IncreaseIcon,
  ArrowDropDown as ArrowDropDownIcon,
  FormatAlignCenter as AlignCenterIcon,
  FormatAlignLeft as AlignLeftIcon,
  FormatAlignRight as AlignRightIcon,
  FormatBold as BoldIcon,
  FormatColorText as FontColorIcon,
  FormatItalic as ItalicIcon,
  Remove as DecreaseIcon,
} from '@mui/icons-material'
import {
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'

import { ColorPicker } from '@components/ColorPicker'

import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'

import { GLOBAL_PARAMETER_CODE } from '@constants'

import { ColorSettings } from './components/ColorSettings'

export type ExternalTextSettings = TextSettingsStateType

export type AlignmentType = 'left' | 'center' | 'right'
export type FormatType = 'bold' | 'italic'
export type TextParams = {
  fontFamily: string
  fontSize: number | string
  fontColor: string | null
  bgFontColor?: string
  alignment: AlignmentType
  isBold: string
  isItalic: string
  borderColor?: string
}

export const isTextParam = (textParam: string): textParam is keyof TextParams => {
  return [
    'fontFamily',
    'fontSize',
    'fontColor',
    'bgFontColor',
    'alignment',
    'isBold',
    'isItalic',
    'borderColor',
  ].includes(textParam)
}

export type GetTextParamsHandle = {
  getTextParams: () => TextParams
  setValues: (values: TextSettingsStateType) => void
}

type TextSettingsProps = {
  labelText: string
  isEdit?: boolean
  backgroundSettings?: boolean
  borderSettings?: boolean
  labelPlacement?: 'top' | 'left'
  initialTextState?: TextSettingsStateType
  onSetExternalDirty?: (value: ExternalTextSettings) => void
}

export type TextSettingsStateType = {
  fontFamily: AutocompleteOption
  fontSize: string
  formats: FormatType[]
  alignment: AlignmentType
  fontColor: string
  bgFontColor?: string
  borderColor?: string
}

const alignIconsData = {
  left: <AlignLeftIcon />,
  center: <AlignCenterIcon />,
  right: <AlignRightIcon />,
}

const mockText = 'Lorem ipsum dolor sit amet consectetur!'

export const INITIAL_TEXT_STATE: TextSettingsStateType = {
  fontFamily: { id: 'Arial', label: 'Arial' },
  fontSize: '14',
  formats: [],
  alignment: 'left',
  fontColor: '#000',
  bgFontColor: 'none',
  borderColor: 'none',
}

// Стейт "вытягивается" с помощью useImperativeHandle, чтобы не приходилось иметь свое состояние
// для каждого кейса
const TextSettings = forwardRef<GetTextParamsHandle, TextSettingsProps>(
  (
    {
      labelText,
      isEdit = false,
      backgroundSettings = true,
      borderSettings = true,
      labelPlacement = 'left',
      initialTextState = INITIAL_TEXT_STATE,
      onSetExternalDirty,
    },
    ref
  ) => {
    const { t } = useTranslation()

    const fontSizeInputRef = useRef<HTMLInputElement | null>(null)

    const [fontFamilyOptions, setFontFamilyOptions] = useState<AutocompleteOption[] | null>(null)
    const [fontFamily, setFontFamily] = useState<AutocompleteOption>(initialTextState.fontFamily)
    const [fontSize, setFontSize] = useState<number>(Number(initialTextState.fontSize))
    const [formats, setFormats] = useState<FormatType[]>(initialTextState.formats)
    const [alignment, setAlignment] = useState<AlignmentType>(initialTextState.alignment)
    const [fontColor, setFontColor] = useState<string>(initialTextState.fontColor)
    const [bgFontColor, setBgFontColor] = useState<string | undefined>(initialTextState.bgFontColor)
    const [borderColor, setBorderColor] = useState<string | undefined>(initialTextState.borderColor)

    const [alignMenuAnchor, setAlignMenuAnchor] = useState<null | HTMLElement>(null)
    const alignMenuOpen = Boolean(alignMenuAnchor)
    const [openFontColorPicker, setOpenFontColorPicker] = useState(false)
    const [isDirty, setDirty] = useState(false)

    const { data: globalFontVariants }: { data: ParameterPropertyType[] } =
      useFetchParameterPropertiesQuery({
        code: GLOBAL_PARAMETER_CODE.GLOBAL_FONT_VARIANTS,
      })

    useEffect(() => {
      if (initialTextState) {
        handleSetValues(initialTextState)
      }
    }, [initialTextState])

    useEffect(() => {
      if (fontSizeInputRef.current) {
        fontSizeInputRef.current.value = `${fontSize}`
      }
    }, [fontSize])

    useEffect(() => {
      if (!globalFontVariants) {
        return
      }

      setFontFamilyOptions(
        globalFontVariants.map(
          fontVariant => ({ id: fontVariant.key, label: fontVariant.key } as AutocompleteOption)
        )
      )
    }, [globalFontVariants])

    useImperativeHandle(ref, () => ({
      getTextParams: () => ({
        fontFamily: fontFamily.label,
        fontSize: fontSize.toString(),
        fontColor,
        alignment,
        isBold: String(Boolean(formats.find(format => format === 'bold'))),
        isItalic: String(Boolean(formats.find(format => format === 'italic'))),
        ...(backgroundSettings ? { bgFontColor } : {}),
        ...(borderSettings ? { borderColor } : {}),
      }),
      setValues: handleSetValues,
    }))

    const handleSetValues = (values: TextSettingsStateType) => {
      setFontFamily(values.fontFamily)
      setFontSize(Number(values.fontSize))
      setFormats(values.formats)
      setAlignment(values.alignment)
      setFontColor(values.fontColor)

      if (backgroundSettings) {
        setBgFontColor(values.bgFontColor)
      }

      if (borderSettings) {
        setBorderColor(values.borderColor)
      }

      onSetExternalDirty?.({
        fontFamily: values.fontFamily,
        fontSize: values.fontSize?.toString(),
        formats: values.formats,
        alignment: values.alignment,
        fontColor: values.fontColor,
        ...(backgroundSettings && values.bgFontColor ? { bgFontColor: values.bgFontColor } : {}),
        ...(borderSettings && values.borderColor ? { borderColor: values.borderColor } : {}),
      })
    }

    const handleAlignMenuAnchorClick = (e: React.MouseEvent<HTMLElement>) => {
      setAlignMenuAnchor(e.currentTarget)
    }
    const handleAlignMenuAnchorClose = () => {
      setAlignMenuAnchor(null)
    }

    const handleFormatChange = (event: MouseEvent<HTMLElement>, newFormats: FormatType[]) => {
      setFormats(newFormats)
      onSetExternalDirty?.({
        fontFamily,
        fontSize: fontSize.toString(),
        formats: newFormats,
        alignment,
        fontColor,
        ...(backgroundSettings ? { bgFontColor } : {}),
        ...(borderSettings ? { borderColor } : {}),
      })
    }

    const handleAlignmentChange = (newAlignment: AlignmentType) => {
      setAlignment(newAlignment)
      onSetExternalDirty?.({
        fontFamily,
        fontSize: fontSize.toString(),
        formats,
        alignment: newAlignment,
        fontColor,
        ...(backgroundSettings ? { bgFontColor } : {}),
        ...(borderSettings ? { borderColor } : {}),
      })
      handleAlignMenuAnchorClose()
    }

    const handleFontFamilyChange = (value: AutocompleteOption) => {
      setFontFamily(value)
      onSetExternalDirty?.({
        fontFamily: value,
        fontSize: fontSize.toString(),
        formats,
        alignment,
        fontColor,
        ...(backgroundSettings ? { bgFontColor } : {}),
        ...(borderSettings ? { borderColor } : {}),
      })
    }

    const handleFontSizeChange = (value: number) => {
      if (!fontSizeInputRef.current) {
        return
      }

      if (value === 0) {
        fontSizeInputRef.current.value = `${fontSize}`

        return
      }

      fontSizeInputRef.current.value = `${value}`

      setFontSize(value)
      onSetExternalDirty?.({
        fontFamily,
        fontSize: value.toString(),
        formats,
        alignment,
        fontColor,
        ...(backgroundSettings ? { bgFontColor } : {}),
        ...(borderSettings ? { borderColor } : {}),
      })
    }

    const handleChangeFontBgColor = (value: string) => {
      setBgFontColor(value)
      onSetExternalDirty?.({
        fontFamily,
        fontSize: fontSize.toString(),
        formats,
        alignment,
        fontColor,
        ...(backgroundSettings ? { bgFontColor: value } : {}),
        ...(borderSettings ? { borderColor: value } : {}),
      })
    }

    const handleChangeFontColor = (value: string) => {
      setFontColor(value)
      onSetExternalDirty?.({
        fontFamily,
        fontSize: fontSize.toString(),
        formats,
        alignment,
        fontColor: value,
        ...(backgroundSettings ? { bgFontColor } : {}),
        ...(borderSettings ? { borderColor } : {}),
      })
    }

    const handleChangeBorderColor = (value: string) => {
      setBorderColor(value)
      onSetExternalDirty?.({
        fontFamily,
        fontSize: fontSize.toString(),
        formats,
        alignment,
        fontColor,
        ...(backgroundSettings ? { bgFontColor: value } : {}),
        ...(borderSettings ? { borderColor: value } : {}),
      })
    }

    return (
      <>
        <Menu anchorEl={alignMenuAnchor} open={alignMenuOpen} onClose={handleAlignMenuAnchorClose}>
          <MenuItem disableRipple onClick={() => handleAlignmentChange('left')}>
            <AlignLeftIcon sx={{ mr: 1 }} />
            {t('parameters.visual.alignOptions.left')}
          </MenuItem>
          <MenuItem disableRipple onClick={() => handleAlignmentChange('center')}>
            <AlignCenterIcon sx={{ mr: 1 }} />
            {t('parameters.visual.alignOptions.center')}
          </MenuItem>
          <MenuItem disableRipple onClick={() => handleAlignmentChange('right')}>
            <AlignRightIcon sx={{ mr: 1 }} />
            {t('parameters.visual.alignOptions.right')}
          </MenuItem>
        </Menu>

        <Grid container flexDirection={labelPlacement === 'left' ? 'row' : 'column'}>
          <Grid item flex={1} maxWidth={200} mb={labelPlacement === 'top' ? 0.5 : 0}>
            <Typography color={'GrayText'} my='9px'>
              {labelText}
            </Typography>
          </Grid>
          <Grid item flexGrow={1}>
            <Grid
              container
              alignItems='center'
              gap={2}
              sx={{ border: theme => `1px solid ${theme.palette.grey['A700']}` }}
            >
              <Grid item flexGrow={1}>
                <AutoComplete
                  disableClearable
                  disabled={!isEdit}
                  options={fontFamilyOptions ?? []}
                  size='small'
                  sx={{ minWidth: 230 }}
                  value={fontFamily}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          sx: {
                            fontFamily: fontFamily.label ?? 'inherit',
                            p: 0,
                          },
                        }}
                        sx={{
                          color: theme => theme.palette.grey[600],
                          border: 'none',
                          '& fieldset': { border: 'none' },
                        }}
                      />
                    )
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li
                        key={option.id}
                        style={{ fontFamily: option.label ?? 'inherit' }}
                        {...props}
                      >
                        {option.label}
                      </li>
                    )
                  }}
                  onChange={handleFontFamilyChange}
                />
              </Grid>
              <Grid container item alignItems='center' width='auto'>
                <Divider orientation='vertical' sx={{ height: '28px' }} />
                <IconButton
                  disabled={!isEdit}
                  size='small'
                  sx={{ ml: 1 }}
                  onClick={() => handleFontSizeChange(Number(fontSize) - 1)}
                >
                  <DecreaseIcon />
                </IconButton>
                <TextField
                  disabled={!isEdit}
                  inputProps={{
                    sx: { px: 1.1, py: 0, width: 35, height: 32, textAlign: 'center' },
                    ref: fontSizeInputRef,
                  }}
                  sx={{
                    width: 35,
                    height: 32,
                    mx: 1,
                  }}
                  onBlur={e => handleFontSizeChange(Number(e.target.value))}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      handleFontSizeChange(Number(e.target.value))
                    }
                  }}
                />
                <IconButton
                  disabled={!isEdit}
                  size='small'
                  sx={{ mr: 1 }}
                  onClick={() => {
                    handleFontSizeChange(Number(fontSize) + 1)
                  }}
                >
                  <IncreaseIcon />
                </IconButton>
                <Divider orientation='vertical' sx={{ height: '28px', mr: 1 }} />
                <ToggleButtonGroup
                  disabled={!isEdit}
                  value={formats}
                  sx={{
                    borderTopRightRadius: 1,
                    borderBottomRightRadius: 1,
                    'button.Mui-disabled': {
                      border: 'none !important',
                    },
                  }}
                  onChange={handleFormatChange}
                >
                  <ToggleButton sx={{ p: 1, border: 'none', borderRadius: 1 }} value='bold'>
                    <BoldIcon />
                  </ToggleButton>
                  <ToggleButton sx={{ p: 1, border: 'none', borderRadius: 1 }} value='italic'>
                    <ItalicIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                <IconButton
                  disabled={!isEdit}
                  sx={{ borderRadius: 1, position: 'relative' }}
                  onClick={() => setOpenFontColorPicker(true)}
                >
                  <FontColorIcon />
                  <ColorPicker
                    color={fontColor ?? '#000000'}
                    open={openFontColorPicker}
                    sx={{ right: 0, bottom: 0 }}
                    onChange={handleChangeFontColor}
                    onClickOutside={() => setOpenFontColorPicker(false)}
                  />
                </IconButton>
                <IconButton
                  disabled={!isEdit}
                  sx={{ borderRadius: 1, pr: 2 }}
                  onClick={handleAlignMenuAnchorClick}
                >
                  {alignIconsData[`${alignment}`]}
                  <ArrowDropDownIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container alignItems='center' my={0.5}>
              <Grid item>
                <Typography
                  sx={{
                    mr: 2,
                    color: theme => theme.palette.primary.main,
                    display: 'inline-block',
                  }}
                >
                  {t('parameters.visual.exampleText')}:{' '}
                </Typography>
              </Grid>
              <Grid item flexGrow={1}>
                <Typography
                  sx={{
                    width: '100%',
                    display: 'inline-block',
                    textAlign: alignment,
                    fontSize: `${fontSize}px`,
                    color: fontColor,
                    fontFamily: fontFamily.label,
                    fontWeight: formats.find(format => format === 'bold') ? 'bold' : 'normal',
                    fontStyle: formats.find(format => format === 'italic') ? 'italic' : 'normal',
                  }}
                >
                  {mockText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {backgroundSettings && (
          <ColorSettings
            clearableColor
            color={bgFontColor}
            isEdit={isEdit}
            label={t('parameters.visual.background')}
            labelPlacement={labelPlacement}
            onChangeColor={handleChangeFontBgColor}
          />
        )}

        {borderSettings && (
          <ColorSettings
            clearableColor
            color={borderColor}
            isEdit={isEdit}
            label={t('parameters.visual.border')}
            labelPlacement={labelPlacement}
            onChangeColor={handleChangeBorderColor}
          />
        )}
      </>
    )
  }
)

TextSettings.displayName = 'TextSettings'
export default TextSettings
