import { ChangeEventHandler, FC, useContext, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Button, TextField } from '@microservices/wiskey-react-components'
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, InputAdornment, LinearProgress } from '@mui/material'

import { TabPanel } from '@components/Tabs'

import { FORM_ELEMENT_TYPE_TO_DROPPABLE_TYPE } from '@constants'

import { DraggableElement } from './AvailableElementsOutsideForm/DraggableElement'
import { FieldsConfigContext } from './FieldsConfig'

export const AvailableFormElements: FC = () => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState(0)
  const [search, setSearch] = useState('')

  const {
    availableFormElements,
    availableFormFields,
    availableViews,
    isLoadingAvailableFields,
    changeFieldsFilter,
  } = useContext(FieldsConfigContext)

  const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = event => {
    setSearch(event.target.value)
  }

  const handleClearSearch = () => {
    setSearch('')
    changeFieldsFilter(undefined)
  }

  const handleSearchButtonClick = () => {
    changeFieldsFilter(search || undefined)
  }

  const handleChangeTab = (tabIndex: number) => {
    return () => {
      setCurrentTab(tabIndex)
    }
  }

  const tabsData = [availableFormFields, availableViews, availableFormElements]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {isLoadingAvailableFields && currentTab === 0 && (
        <LinearProgress sx={{ position: 'absolute', top: '-1rem', width: '100%' }} />
      )}
      <Grid container direction={'row'} flexWrap={'nowrap'} spacing={1}>
        <Grid item>
          <Button
            variant={currentTab === 0 ? 'contained' : 'outlined'}
            onClick={handleChangeTab(0)}
          >
            {t('fieldsConfig.fields')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={currentTab === 1 ? 'contained' : 'outlined'}
            onClick={handleChangeTab(1)}
          >
            {t('fieldsConfig.lists')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ mr: 2 }}
            variant={currentTab === 2 ? 'contained' : 'outlined'}
            onClick={handleChangeTab(2)}
          >
            {t('fieldsConfig.elements')}
          </Button>
        </Grid>
      </Grid>
      {currentTab === 0 && (
        <Grid container item sx={{ mt: 1 }}>
          <TextField
            fullWidth
            placeholder={t('fieldsConfig.placeholder.fieldsFilter')}
            size={'small'}
            sx={{ mr: 2 }}
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {search && (
                    <IconButton size={'small'} onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton color={'primary'} size={'small'} onClick={handleSearchButtonClick}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeSearch}
          />
        </Grid>
      )}

      {tabsData.map((tabsData, tabIndex) => (
        <TabPanel key={tabIndex} contentSx={{ p: 0, ml: -2 }} index={tabIndex} value={currentTab}>
          {tabsData.map((element, elementIndex) => (
            <Droppable
              key={`${element.id}`}
              isDropDisabled
              droppableId={`${element.type}:${element.id}`}
              type={FORM_ELEMENT_TYPE_TO_DROPPABLE_TYPE[element.type]}
            >
              {provided => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  <DraggableElement
                    element={element}
                    index={elementIndex}
                    title={element.title || ''}
                  />
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          ))}
        </TabPanel>
      ))}
    </Box>
  )
}
