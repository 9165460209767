import { Dispatch, FC, SetStateAction } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'

import { CriteriaType, ValueAddType } from '@constants'

import { SearchChips } from '../SearchChips'
import { SelectOption } from '../SelectOption'

type RenderCriteriasProps = {
  criterias: CriteriaType[]
  currentCriterias: CriteriaType[]
  addValue: (value: ValueAddType) => void
  setCurrentCriterias: Dispatch<SetStateAction<CriteriaType[]>>
  options?: CriteriaType[]
  filterDisabled: boolean
  title: string
}

export const RenderCriterias: FC<RenderCriteriasProps> = ({
  addValue,
  criterias,
  currentCriterias,
  filterDisabled,
  setCurrentCriterias,
  options,
  title,
}) => {
  return (
    <>
      <SearchChips
        addValue={addValue}
        criterias={criterias}
        currentCriterias={currentCriterias}
        setCurrentCriteria={setCurrentCriterias}
        title={title}
      />
      <AutoSizer disableHeight style={{ width: '100%' }}>
        {({ width: sourceWidth }) => {
          const offset = 2
          const width = sourceWidth >= offset ? sourceWidth - offset : 0

          return currentCriterias.map(criteria => (
            <SelectOption
              key={criteria.codeCriteria}
              addValue={addValue}
              currentCriteriaNames={currentCriterias.map(({ title }) => title)}
              disabled={filterDisabled}
              options={options}
              setCurrentCriteria={setCurrentCriterias}
              width={width}
              assistantCriteria={
                criterias.find(filter => {
                  return filter.codeCriteria === criteria.codeCriteria
                }) as CriteriaType
              }
            />
          ))
        }}
      </AutoSizer>
    </>
  )
}
