import { FC } from 'react'
import { CircularProgress, Grid, SxProps, Theme } from '@mui/material'

type AppLoaderProps = {
  size?: string
  sx?: SxProps<Theme>
}

export const AppLoader: FC<AppLoaderProps> = ({ sx, size = '4rem' }) => {
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ height: '100%', width: '100%', ...sx }}
    >
      <CircularProgress size={size} />
    </Grid>
  )
}
