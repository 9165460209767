import { Key, ReactNode } from 'react'
import { Box } from '@mui/material'

type ModalFormContainerProps = {
  children?: ReactNode
  key?: Key | null
  maxHeight?: number | string
  minHeight?: number | string
  maxWidth?: number | string
  minWidth?: number | string
  width?: number | string
}

export const ModalFormContainer = ({
  children,
  key,
  maxWidth,
  minWidth,
  minHeight,
  maxHeight,
  width,
}: ModalFormContainerProps) => {
  return (
    <Box
      key={key}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      minHeight={minHeight}
      minWidth={minWidth}
      pb={0.25}
      pr={1}
      pt={1}
      sx={{ overflowY: 'auto', overflowX: 'hidden' }}
      width={width}
    >
      {children}
    </Box>
  )
}
