import React, { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import get from 'lodash/get'
import { ErrorMessage } from '@hookform/error-message'
import { ConfirmModal, FormInput } from '@microservices/wiskey-react-components'
import { Delete } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material'
import { CellContext } from '@tanstack/react-table'

import { BindTypeInputs } from '@components/BindTypeInputs'
import { CommandPickerController } from '@components/hookFormControllers/CommandPickerController'
import { SwitchTable } from '@components/SwitchTable'
import { Tab, TabPanel, Tabs } from '@components/Tabs'

import { useFetchObjectByCodeNoCacheMutation } from '@redux/api'

import { usePrevious } from '@hooks'
import { getParamsStringSafe } from '@helpers'
import { GANTT_BIND_TYPE, GENERATOR_INPUT_TYPE, MODEL_TYPE } from '@constants'
import { AutocompleteOption, ENTITY_COMMAND_TYPE, ModalType, ObjectShortDTO } from '@types'

import {
  AvailableVariables,
  AXIS_TYPE,
  ConfigField,
  FILTER_BINDING_TYPE,
  GanttActionType,
  PageContext,
  SectionType,
} from '@gantt/components/GanttCreateOrEdit'
import {
  COMMON_META_DATA,
  DefReadAll,
  GANTT_CONFIG_ACTION_TIMELINE_COLUMNS,
  GANTT_SEGMENT_COLUMNS,
  JS_FUN_ARGS,
} from '@gantt/constants'
import {
  AutocompleteOptionsObject,
  getAutocompleteOptionDisabledObject,
  getAutoCompleteOptions,
  getBarDefaultValue,
  getBindTypeOptionsByEnum,
  getDefaultCommand,
  getInvolvedPaths,
  keyFieldOptionsFilter,
} from '@gantt/helpers'

import { getAvailableVariablesObject } from '../../helpers'
import { BaseFormFields, GanttPreFilterHelper, TimelineAdditionalFields } from '../'

type Props = {
  isShowDialog: boolean
  isLoadingObjects: boolean
  isSingleBar: boolean
  rows: GanttActionType[]
  rowsSegmentTable: SectionType[]
  index: number
  objects: ObjectShortDTO[] | undefined
  filteredObjects: ObjectShortDTO[] | undefined
  usedObjectsList: string[]
  onEdit: (
    row: SectionType | GanttActionType | null,
    id?: number | string,
    isAction?: boolean | undefined,
    barIndex?: number,
    watchedBarObject?: AutocompleteOption<string> | null,
    inputAvailableVariables?: AvailableVariables,
    existingPaths?: string[]
  ) => void
  onDelete: (id: string | number, index: number) => void
  onOpenDialog: (props: {
    type: ModalType
    id?: number | string
    row?: GanttActionType | SectionType | null
    barIndex?: number | null
    selectedBarObject?: AutocompleteOption<string> | null
    inputAvailableVariables?: AvailableVariables
    existingPaths?: string[]
  }) => void
  onDeleteSegment: (id: string | number, index: number) => void
  onRemoveBar: (index: number) => void
}

const filterHints = {
  static: i18next.t('ganttCreate.timelineForm.hint.filterStaticHint'),
}

export const TimelineForm = ({
  isShowDialog,
  isLoadingObjects,
  isSingleBar,
  rows,
  rowsSegmentTable,
  index,
  filteredObjects,
  objects,
  usedObjectsList,
  onEdit,
  onDeleteSegment: handleDeleteSegment,
  onOpenDialog,
  onDelete: handleDelete,
  onRemoveBar,
}: Props) => {
  const { t } = useTranslation()
  const { code } = useParams()

  const { currentBarIndex } = useContext(PageContext)
  const {
    watch,
    resetField,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext()

  const [isShowScriptDialog, setIsShowScriptDialog] = useState(false)
  const [isShowClearModal, setIsShowClearModal] = useState(false)
  const [isShowConfirmBarDelete, setIsShowConfirmBarDelete] = useState(false)
  const [currentTabTimeline, setCurrentTabTimeline] = useState(0)

  const isDisabled = !watch(`timeline.${index}.data`)
  const watchedBarObject: { id: string; label: string } = watch(`timeline.${index}.data`)
  const watchedBarKey = watch(`timeline.${index}.key`)
  const watchedBarCommand = watch(`timeline.${index}.command`)
  const isNewBar = watch(`timeline.${index}.isNew`)
  const isEdit = !!code
  const watchedMainObjectCode = watch('sort.code')
  const watchedBar = watch(`timeline.${index}`)
  const watchedBarFilter = watch(`timeline.${index}.filter`)
  const watchedMainObjectAxis = watch('sort.axis')

  const [prevObjectId, clearId] = usePrevious(watchedBarObject?.id)
  const [prevObjectLabel, clearLabel] = usePrevious(watchedBarObject?.label)
  const [prevKey, clearKey] = usePrevious(watchedBarKey)
  const [prevCommand] = usePrevious(watchedBarCommand)

  const [fetchObjectByCode, { data: objectByCode }] = useFetchObjectByCodeNoCacheMutation({
    fixedCacheKey: 'timeline-object',
  })

  const availableVariablesObject: AvailableVariables = useMemo(() => {
    const variablesObject = Object.keys(getAvailableVariablesObject(watch(`timeline.${index}`)))
    const variableItems = watch(`timeline.${index}.additionalFields`).map(
      (item: ConfigField) => item?.field
    )

    return {
      [JS_FUN_ARGS.mainData]: variableItems.concat(variablesObject),
      [JS_FUN_ARGS.restData]: [],
      ...COMMON_META_DATA,
    }
  }, [watch()])

  useEffect(() => {
    watchedBarObject?.id && fetchObjectByCode(watchedBarObject?.id)
  }, [watchedBarObject?.id])

  useEffect(() => {
    if (
      (watchedBarObject?.id && prevObjectId !== watchedBarObject?.id && prevObjectId) ||
      (!watchedBarObject?.id && prevObjectId)
    ) {
      handleOpen()
    }
  }, [watchedBarObject])

  const segmentRows: SectionType[] = useMemo(
    () => rowsSegmentTable.map(item => ({ ...item, id: item.link.systemName })),
    [rowsSegmentTable]
  )

  const commandOptions = useMemo(
    () => objectByCode?.commands.map(command => ({ id: command.code, label: command.code })),
    [objectByCode?.commands]
  )

  const handleTabChangeTimeline = useCallback((event: SyntheticEvent, newValue: number) => {
    setCurrentTabTimeline(newValue)
  }, [])

  const handleEditAction = useCallback(
    (params: CellContext<GanttActionType, unknown>) => {
      onEdit(params.row.original, params.row.original.id, true, index, watchedBarObject)
    },
    [onEdit]
  )

  const handleEditSegment = useCallback(
    (params: CellContext<SectionType, any>) => {
      onEdit(
        params.row.original,
        params.row.original.link.systemName,
        false,
        index,
        watchedBarObject,
        availableVariablesObject,
        getInvolvedPaths(watchedBar)
      )
    },
    [onEdit]
  )

  const toggleOpenScriptValueDialog = useCallback(() => {
    setIsShowScriptDialog(prevState => !prevState)
  }, [])

  const handleClearOnChangeObject = () => {
    const barDefaultValue = getBarDefaultValue()
    watchedBarObject?.id &&
      fetchObjectByCode(watchedBarObject?.id)
        .unwrap()
        .then(data => {
          const idField = data?.fields?.find(field => field.isPk)
          const internalIdField = data?.fields?.find(field => field.isInternalId)
          const key = idField || internalIdField

          const newKey = key && {
            field: key.name,
            pathStr: key.name,
            pathArray: [
              {
                objectCode: watchedBarObject?.id,
                field: key.name,
                valueType: key.valueType,
              },
            ],
          }

          const defaultCommand = getDefaultCommand(data?.commands || [], DefReadAll)

          const newDefaultCommand = defaultCommand && [
            {
              name: { id: defaultCommand.code, label: defaultCommand.code },
              type: {
                id: defaultCommand.code,
                label: ENTITY_COMMAND_TYPE.GET_ALL,
              },
            },
          ]

          setValue(`timeline.${index}`, {
            ...barDefaultValue,
            data: watchedBarObject,
            key: {
              bindType: GANTT_BIND_TYPE.FIELD,
              field: newKey || { field: '', pathStr: '', pathArray: [] },
            },
            commands: newDefaultCommand ?? [{ name: null, type: null }],
          })
        })

    handleClose(false)
  }

  const handleOpen = useCallback(() => {
    setIsShowClearModal(true)
  }, [])

  const handleClose = (resetObject: boolean) => {
    clearId?.()
    clearLabel?.()
    clearKey?.()
    resetObject &&
      resetField(`timeline.${index}.data`, {
        defaultValue: { id: prevObjectId, label: prevObjectLabel },
      })
    resetObject &&
      resetField(`timeline.${index}.key`, {
        defaultValue: prevKey,
      })
    resetObject &&
      resetField(`timeline.${index}.command`, {
        defaultValue: prevCommand,
      })
    setIsShowClearModal(false)
  }

  const handleCloseBarDeleteModal = useCallback(() => {
    setIsShowConfirmBarDelete(false)
  }, [])

  const handleDeleteBar = useCallback(() => {
    onRemoveBar(index)
  }, [])

  const handleShowDeleteBarWarning = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setIsShowConfirmBarDelete(true)
    event.stopPropagation()
  }, [])

  const handleShowDialog = (type: ModalType, id?: string | number, isAction?: boolean) => {
    onOpenDialog({
      type,
      id,
      barIndex: index,
      selectedBarObject: watchedBarObject,
      inputAvailableVariables: availableVariablesObject,
      existingPaths,
    })
  }

  const handleChangeObject = (value: AutocompleteOption<string | number>) => {
    if (value?.id && prevObjectId !== value?.id && prevObjectId) {
      handleOpen()
    }

    if (!watchedMainObjectCode) {
      setValue('sort.code', value?.id)
      setValue('sort.axis', AXIS_TYPE.X)
    }

    !isShowClearModal &&
      value?.id &&
      fetchObjectByCode(String(value?.id))
        .unwrap()
        .then(data => {
          const idField = data?.fields?.find(field => field.isPk)
          const internalIdField = data?.fields?.find(field => field.isInternalId)
          const key = idField || internalIdField

          const newKey = key && {
            field: key.name,
            pathStr: key.name,
            pathArray: [
              {
                objectCode: value?.id,
                field: key.name,
                valueType: key.valueType,
              },
            ],
          }
          const defaultCommand = getDefaultCommand(data?.commands || [], DefReadAll)

          const newDefaultCommand = defaultCommand && [
            {
              name: { id: defaultCommand.code, label: defaultCommand.code },
              type: {
                id: defaultCommand.code,
                label: ENTITY_COMMAND_TYPE.GET_ALL,
              },
            },
          ]

          newKey && setValue(`timeline.${index}.key.field`, newKey, { shouldValidate: true })
          newDefaultCommand &&
            setValue(
              `timeline.${index}.commands`,
              newDefaultCommand ?? [{ name: null, type: null }],
              { shouldValidate: true }
            )
        })
        .finally(() => {
          trigger(`timeline.${index}.commands`)
        })
  }

  const barLabel = t('ganttCreate.timelineForm.barName', {
    index: index + 1,
    name: watchedBarObject?.label || t('ND'),
  })

  const existingPaths = useMemo(() => {
    return getInvolvedPaths(watchedBar)
  }, [watchedBar])

  return (
    <Box p={2} pt={0}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display='flex' justifyContent='space-between' width='100%'>
            <Typography alignSelf='center'>{barLabel}</Typography>
            {!isSingleBar && (
              <Button onClick={handleShowDeleteBarWarning}>
                <Delete fontSize='small' />
              </Button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTabTimeline} onChange={handleTabChangeTimeline}>
              <Tab label={t('ganttCreate.timelineForm.barSteps.data')} />
              <Tab
                label={
                  <Typography
                    color={get(errors, `timeline.${index}.sections`) && 'error'}
                    variant={'body2'}
                  >
                    {t('ganttCreate.timelineForm.segments')}
                  </Typography>
                }
              />
              <Tab label={t('ganttCreate.timelineForm.barSteps.view')} />
              <Tab label={t('ganttCreate.timelineForm.actions')} />
              <Tab label={t('ganttCreate.timelineForm.additionalFieldsForm.tabName')} />
              <Tab label={t('ganttCreate.timelineForm.barSteps.preFilter')} />
            </Tabs>
          </Box>
          <TabPanel index={0} sx={{ p: 1 }} value={currentTabTimeline}>
            <FormInput
              autocompleteOptions={getAutoCompleteOptions(objects)}
              disabled={isEdit && !isNewBar}
              inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
              label={t('ganttCreate.timelinesAxis')}
              loading={isLoadingObjects}
              name={`timeline.${index}.data`}
              placeholder={i18next.t('placeholder.selectObject')}
              getOptionDisabled={option =>
                getAutocompleteOptionDisabledObject(option, usedObjectsList)
              }
              renderAutocompleteOption={(
                props: React.HTMLAttributes<HTMLLIElement>,
                option: { id: string | number; label: string }
              ) => (
                <AutocompleteOptionsObject
                  list={usedObjectsList}
                  option={option}
                  otherProps={props}
                />
              )}
              rules={{
                required: true,
              }}
              onChangeAutocomplete={handleChangeObject}
            />
            {watchedBarObject?.id && (
              <BindTypeInputs
                bindTypeOptions={[]}
                containerName={`timeline.${index}.key`}
                isDisabled={isDisabled}
                object={watchedBarObject}
                optionsFilter={keyFieldOptionsFilter}
                valueInputLabel={t('ganttCreate.timelineForm.key')}
              />
            )}
            {watchedBarObject?.id && (
              <Box component='fieldset'>
                <legend>{t('label.commandTitle')}</legend>
                <CommandPickerController
                  isSingle
                  commandName='name'
                  name={`timeline.${index}.commands`}
                  objectCode={watchedBarObject?.id}
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel index={1} value={currentTabTimeline}>
            <SwitchTable
              disablePagination
              isCrud
              btnDisabled={!watchedBarObject?.id}
              btnText={t('ganttCreate.timelineForm.addSegment')}
              columns={GANTT_SEGMENT_COLUMNS}
              loading={false}
              rows={segmentRows}
              showActionsColumn={true}
              showDialog={isShowDialog && index === currentBarIndex}
              type={MODEL_TYPE.GANTT_SEGMENT}
              onDelete={id => handleDeleteSegment(id, index)}
              onEdit={handleEditSegment}
              onShowDialog={handleShowDialog}
            />
            <ErrorMessage
              errors={errors}
              name={`timeline.${index}.sections`}
              render={({ message }) => (
                <Typography color={'error'} pt={1}>
                  {message}
                </Typography>
              )}
            />
          </TabPanel>
          <TabPanel index={2} sx={{ p: 1 }} value={currentTabTimeline}>
            <BaseFormFields
              isShownCornersInput
              currentBarKey={`bar_${currentBarIndex}`}
              disabled={isDisabled}
              existingPaths={existingPaths}
              isShowScriptDialog={isShowScriptDialog}
              mainPath={`timeline.${index}`}
              toggleOpenScriptValueDialog={toggleOpenScriptValueDialog}
              variablesForJS={availableVariablesObject}
              watchedObject={watchedBarObject}
            />
          </TabPanel>
          <TabPanel index={3} value={currentTabTimeline}>
            <SwitchTable
              disablePagination
              isCrud
              btnDisabled={!watchedBarObject?.id}
              btnText={t(`ganttCreate.resourceForm.addAction`)}
              columns={GANTT_CONFIG_ACTION_TIMELINE_COLUMNS}
              loading={false}
              rows={rows}
              showActionsColumn={true}
              showDialog={isShowDialog && index === currentBarIndex}
              type={MODEL_TYPE.GANTT_ACTION_TIMELINES}
              onDelete={id => handleDelete(id, index)}
              onEdit={handleEditAction}
              onShowDialog={handleShowDialog}
            />
          </TabPanel>
          <TabPanel index={4} value={currentTabTimeline}>
            <TimelineAdditionalFields barIndex={index} />
          </TabPanel>
          <TabPanel index={5} sx={{ p: 1 }} value={currentTabTimeline}>
            <BindTypeInputs
              isFieldsetMarkup
              bindTypeOptions={getBindTypeOptionsByEnum(FILTER_BINDING_TYPE)}
              containerName={`timeline.${index}.filter`}
              fieldsetTitle={t('ganttCreate.timelineForm.filter')}
              hintDict={filterHints}
              inputType={GENERATOR_INPUT_TYPE.TEXTAREA}
            />
            <GanttPreFilterHelper
              appearsIn={AXIS_TYPE.X}
              axis={watchedMainObjectAxis}
              mainObject={watchedMainObjectCode}
              object={watchedBarObject}
              preFilterValue={getParamsStringSafe(watchedBarFilter)}
            />
          </TabPanel>
        </AccordionDetails>
      </Accordion>
      {isShowClearModal && (
        <ConfirmModal
          actionBtnText={t('modal.changeObjectAxisX.btn')}
          containerStyle={{ px: 2.5, py: 2.5, borderRadius: 0 }}
          isShow={isShowClearModal}
          text={t('modal.changeObjectAxisX.warning')}
          title={t('modal.changeObjectAxisX.title')}
          onClose={() => handleClose(true)}
          onConfirm={handleClearOnChangeObject}
        />
      )}
      {isShowConfirmBarDelete && (
        <ConfirmModal
          actionBtnText={t('modal.deleteBar.btn')}
          containerStyle={{ px: 2.5, py: 2.5, borderRadius: 0 }}
          isShow={isShowConfirmBarDelete}
          text={t('modal.deleteBar.warning', { index: index + 1 })}
          title={t('modal.deleteBar.title')}
          onClose={handleCloseBarDeleteModal}
          onConfirm={handleDeleteBar}
        />
      )}
    </Box>
  )
}
