import {
  FormState,
  PayloadSetMetaForm,
  PayloadSetOpenedContainer,
  PayloadSetTabIndexContainer,
} from 'src/types/redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: FormState = {}
const formStates = createSlice({
  name: 'formStates',
  initialState,
  reducers: {
    setOpenedContainerForm(state, action: PayloadAction<PayloadSetOpenedContainer>) {
      const { containerId, open, formCode } = action.payload

      if (!state[formCode]) {
        state[formCode] = { containers: { [containerId]: { open, currentTabIndex: 0 } } }

        return
      }

      if (!state[formCode].containers[containerId]) {
        state[formCode].containers[containerId] = { open, currentTabIndex: 0 }

        return
      }

      state[formCode].containers[containerId].open = open
    },
    setTabIndexInContainer(state, action: PayloadAction<PayloadSetTabIndexContainer>) {
      const { containerId, formCode, tabIndex } = action.payload

      if (state[formCode]) {
        state[formCode].containers[containerId].currentTabIndex = tabIndex
      }
    },
    setMetaForm(state, action: PayloadAction<PayloadSetMetaForm>) {
      const { formCode, ...meta } = action.payload

      if (!state[formCode]) {
        state[formCode] = { meta, containers: {} }

        return
      }

      if (!state[formCode].meta) {
        state[formCode] = { ...state[formCode], meta }

        return
      }

      state[formCode].meta = { ...state[formCode].meta, ...meta }
    },
  },
})

export default formStates.reducer
export const { setOpenedContainerForm, setTabIndexInContainer, setMetaForm } = formStates.actions
