import { FC, ReactNode, useContext } from 'react'
import Tree, {
  ItemId,
  moveItemOnTree,
  mutateTree,
  RenderItemParams,
  TreeDestinationPosition,
  TreeSourcePosition,
} from '@atlaskit/tree'
import { Box, List } from '@mui/material'

import { EntityOrFormGroupListContext } from '../../EntityOrFormGroupList'

import { Item } from './Item'

type MenuListProps = {
  isEdit: boolean
}

export const MenuList: FC<MenuListProps> = ({ isEdit }) => {
  const { tree, setTree, onDeleteTreeItem } = useContext(EntityOrFormGroupListContext)

  const renderItem = (props: RenderItemParams): ReactNode => {
    return <Item {...props} deleteTreeItem={onDeleteTreeItem} isEditGlobal={isEdit} />
  }

  const onExpand = (itemId: ItemId): void => {
    if (tree) {
      setTree(mutateTree(tree, itemId, { isExpanded: true }))
    }
  }

  const onCollapse = (itemId: ItemId): void => {
    if (tree) {
      setTree(mutateTree(tree, itemId, { isExpanded: false }))
    }
  }

  const onDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    const parentId = destination?.parentId || ''
    if (!destination || !tree) {
      return
    }

    const isViewOrForm = tree?.items[parentId]?.data.viewCode || tree?.items[parentId].data.formCode

    if (tree && destination && isViewOrForm) {
      return
    }

    const newTree = moveItemOnTree(tree, source, destination)
    setTree(newTree)
  }

  return (
    <Box sx={{ overflowY: 'auto', height: '50vh' }}>
      <List sx={{ mt: -2 }}>
        {tree && (
          <Tree
            isNestingEnabled
            isDragEnabled={isEdit}
            renderItem={renderItem}
            tree={tree}
            onCollapse={onCollapse}
            onDragEnd={onDragEnd}
            onExpand={onExpand}
          />
        )}
      </List>
    </Box>
  )
}
