import { useCallback, useContext, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import last from 'lodash/last'
import { ModalWrapper } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { ModalFormContainer } from '@components/ModalFormContainer'

import { useYupValidationResolver } from '@hooks'
import { getNewLastIndexByData } from '@helpers'
import { ObjectFieldDTO } from '@types'

import { PageContext, SectionType, SegmentType } from '@gantt/components/GanttCreateOrEdit'

import { sectionFormSchema } from '@validations'

import { getGanttSegmentDefaultValue } from './helpers/getGanttSegmentDefaultValue'
import { useAddGanttSegmentDialog } from './hooks/useAddGanttSegmentDialog'
import { SegmentItem } from './SegmentItem'

type SegmentsArrayProps = {
  type?: SegmentType
  rawObjectFields?: ObjectFieldDTO[]
}

export const AddSegmentDialog = ({
  type = SegmentType.MULTI,
  rawObjectFields,
}: SegmentsArrayProps) => {
  const { showDialog, handleCloseModal, modalType, currentSectionsList } = useContext(PageContext)

  const resolver = useYupValidationResolver(sectionFormSchema)

  const defaultValues = getGanttSegmentDefaultValue(
    getNewLastIndexByData({
      items: currentSectionsList,
      getterNumber: value => last(value?.link?.systemName?.split('_')),
    })
  )

  const methods = useForm<SectionType>({
    defaultValues,
    resolver,
  })

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
    setError,
    clearErrors,
  } = methods

  const handleDuplicateError = (error: boolean) => {
    if (error) {
      setError('link.systemName', { type: 'custom', message: t('error.duplicatedSystemName') })

      return
    }

    clearErrors('link.systemName')
  }

  const { t } = useTranslation()
  const { handleSave } = useAddGanttSegmentDialog({
    reset,
    onDuplicateError: handleDuplicateError,
    modalType,
  })

  const handleOnCloseModal = useCallback(() => {
    handleCloseModal(isDirty)
  }, [isDirty])

  const getModalTitle = useMemo(() => {
    return modalType === 'edit'
      ? t('ganttCreate.timelineForm.segmentDialogEdit')
      : t('ganttCreate.timelineForm.segmentDialogCreate')
  }, [modalType])

  return (
    <FormProvider {...methods}>
      {showDialog && (
        <ModalWrapper
          btnText={t('ganttCreate.common.apply')}
          disabledSave={!isDirty}
          isShow={showDialog}
          title={getModalTitle}
          onClose={handleOnCloseModal}
          onSave={handleSubmit(handleSave)}
        >
          <ModalFormContainer maxHeight={'calc(100vh/1.5)'} width={'calc(100vw/1.5)'}>
            <Box mb={1}>
              <SegmentItem objectFieldsForValidation={rawObjectFields} type={type} />
            </Box>
          </ModalFormContainer>
        </ModalWrapper>
      )}
    </FormProvider>
  )
}
