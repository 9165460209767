import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { matchPath, useLocation } from 'react-router-dom'

import { useFetchAllContextQuery } from '@redux/api'

import { useDebounce } from '@hooks'
import { SEARCH_PATTERN_DELAY } from '@constants'

import { ContextRowType } from '../types'

import { useHandlers } from '.'

export const useContexts = () => {
  const { pathname } = useLocation()
  const isAllContexts = matchPath('/contexts', pathname)
  const [allContexts, setAllContexts] = useState<ContextRowType[]>([])

  const methods = useForm<{ searchPattern: string }>({ defaultValues: { searchPattern: '' } })
  const { watch } = methods
  const searchPattern = watch('searchPattern')

  const { state, handlers } = useHandlers()

  const debouncedSearchPattern = useDebounce(searchPattern, SEARCH_PATTERN_DELAY)

  const { data: contextsData, isFetching: isLoadingContexts } = useFetchAllContextQuery(
    {
      searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (contextsData) {
      setAllContexts(contextsData)
    }
  }, [contextsData])

  return {
    state: {
      ...state,
      allContexts,
      methods,
    },
    data: {
      isAllContexts,
      isLoadingContexts,
    },
    handlers: {
      ...handlers,
    },
  }
}
