import { t } from 'i18next'

import { OBJECT_FIELD_TYPE } from '@constants'

/**
 * Отображение дополнительной информации в названии значений объекта для конфигурации филдов/колонок/критериев
 * @param objectFieldName Название поля объекта
 * @param objectFieldType Его тип, на основе которого строится условие
 * @returns Изначальное название значение объекта (objectFieldName) с нужным постфиксом при выполнении условия
 */
export const getObjectValueName = (
  objectFieldName: string,
  objectFieldType: OBJECT_FIELD_TYPE,
  isPrimaryKey?: boolean
) => {
  if (isPrimaryKey) {
    return `${objectFieldName} (${t('configByObject.primaryKeyPostfix')})`
  }

  if (objectFieldType === OBJECT_FIELD_TYPE.ENUM) {
    return `${objectFieldName} (${t('configByObject.enumPostfix')})`
  }

  return objectFieldName
}
