import { useState } from 'react'
import { t } from 'i18next'
import { GridCellParams, GridSortModel } from '@mui/x-data-grid'

import { GETCriteriaType } from '@types'

import { deleteCriteriaType, fetchCriteriaType, updateVisibilityType } from '../types'

type useHandlersParams = {
  fetchCriteria: fetchCriteriaType
  deleteCriteria: deleteCriteriaType
  updateVisibility: updateVisibilityType
}

export const useHandlers = ({
  fetchCriteria,
  deleteCriteria,
  updateVisibility,
}: useHandlersParams) => {
  const [showAddSearchFilterModal, setShowAddSearchFilterModal] = useState(false)
  const [showDefaultSetting, setShowDefaultSetting] = useState(false)
  const [page, setPage] = useState(0)
  const [objectCodeState, setObjectCode] = useState<string>()
  const [sortParam, setSortParam] = useState<string>()
  const [currentSort, setCurrentSort] = useState<GridSortModel>([])
  const [editCriteria, setEditCriteria] = useState<GETCriteriaType | null>()
  const [templateTable, setCriteriaTable] = useState<GETCriteriaType[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState<number>()
  const [isEditMode, setEditMode] = useState(false)

  const handleOpenAddSearchFilterModal = (code?: string) => {
    if (code) {
      fetchCriteria(code)
        .unwrap()
        .then(res => setEditCriteria(res))
    }
    setShowAddSearchFilterModal(true)
  }

  const handleCloseAddSearchFilterModal = (isDirty?: boolean) => {
    if (isDirty && confirm(t('notifications.leave'))) {
      setShowAddSearchFilterModal(false)
      if (editCriteria) setEditCriteria(null)

      return
    }
    if (!isDirty) {
      setShowAddSearchFilterModal(false)
      if (editCriteria) setEditCriteria(null)
    }
  }

  const handleChangeObjectCode = (value: string | null) => {
    const objectCode = value || undefined
    if (objectCodeState === objectCode) {
      return
    }
    setPage(0)
    setCriteriaTable([])
    setObjectCode(objectCode)
  }

  const handleDeleteCriteria = (id: number) =>
    deleteCriteria(id)
      .unwrap()
      .then(() => {
        setCriteriaTable(prev => prev.filter(template => template.id !== id))
      })

  const handlePage = (value: number) => setPage(value)

  const handleSetSorting = (value: GridSortModel | null) => {
    const sortParam = value ? `${value[0].field},${value[0].sort}` : undefined
    const sort = value ? value : []

    setPage(0)
    setCriteriaTable([])
    setSortParam(sortParam)
    setCurrentSort(sort)
  }

  const handleVisibility = (params: GridCellParams<GETCriteriaType>, checked: boolean) => {
    updateVisibility({ id: params.row.id, visibility: checked })
      .unwrap()
      .then(data => {
        setCriteriaTable(prev =>
          prev.map(template => (template.id === params.row.id ? data : template))
        )
      })
  }

  const handleClose = () => setShowDeleteModal(false)

  const handleEdit = (params: GridCellParams<GETCriteriaType>) => {
    handleOpenAddSearchFilterModal(params.row.code)
    setEditMode(true)
  }

  const handleNew = () => {
    setEditMode(false)
    handleOpenAddSearchFilterModal()
  }

  const handleDelete = () => {
    if (currentId) {
      handleDeleteCriteria(currentId)
      setShowDeleteModal(false)
    }
  }

  const handleClickDelete = (params: GridCellParams<GETCriteriaType>) => {
    setCurrentId(params.row.id)
    setShowDeleteModal(true)
  }

  const handleChangeModelSort = (value: GridSortModel | null) => {
    if (value?.length) {
      handleSetSorting(value)

      return
    }
    handleSetSorting(null)
  }

  const handleSetEditRow = (row: GETCriteriaType) => setEditCriteria(row)

  const handleSetCriteriaTable = setCriteriaTable

  const handleShowDefaultSettings = () => {
    setShowDefaultSetting(true)
  }

  const handleCloseDefaultSettings = (isDirty?: boolean) => {
    if (isDirty && confirm(t('notifications.leave'))) {
      setShowDefaultSetting(false)

      return
    }
    if (!isDirty) {
      setShowDefaultSetting(false)
    }
  }

  return {
    state: {
      showAddSearchFilterModal,
      showDefaultSetting,
      page,
      objectCodeState,
      sortParam,
      currentSort,
      editCriteria,
      templateTable,
      showDeleteModal,
      currentId,
      isEditMode,
    },
    handlers: {
      handleOpenAddSearchFilterModal,
      handleCloseAddSearchFilterModal,
      handleChangeObjectCode,
      handleDeleteCriteria,
      handlePage,
      handleSetSorting,
      handleVisibility,
      handleClose,
      handleEdit,
      handleNew,
      handleDelete,
      handleClickDelete,
      handleChangeModelSort,
      handleSetEditRow,
      handleSetCriteriaTable,
      handleShowDefaultSettings,
      handleCloseDefaultSettings,
    },
  }
}
