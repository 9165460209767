import i18next from 'i18next'

export const executeCode = (code: string, data: Record<string, unknown>): unknown | false => {
  try {
    const func = new Function(...Object.keys(data), code)

    return func(...Object.values(data || {}))
  } catch (error) {
    console.error(i18next.t('executeCodeError'), error) // Логирование ошибки

    return false
  }
}
