import { createContext, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { PageTitle } from '@components/PageTitle'

import { DICTIONARY_TABLE_COLUMNS } from '@constants'

import { TableWithFilters } from './components/TableWithFilters'
import { useConfigDictionary } from './hooks'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const Dictionary: FC = () => {
  const { t } = useTranslation()

  const { state, data, handlers } = useConfigDictionary()

  const { categoryId } = state

  const {
    dictionariesData,
    dictionariesIsLoading,
    dictionaryCategories,
    dictionariesByCategory,
    countPage,
    tableRows,
    page,
  } = data

  const { handleSelectCategory, handleResetCategory, handlePageChange } = handlers

  const contextValue = {
    allDictionaries: dictionariesData?.data,
    dictionaryCategories,
    dictionariesByCategory,
    currentCategory: categoryId,
    selectCategory: handleSelectCategory,
    resetCategory: handleResetCategory,
    dictionariesIsLoading,
    onChangePage: handlePageChange,
    currentPage: page,
    countPage: countPage ?? 1,
  }

  return (
    <PageContext.Provider value={contextValue}>
      <Box>
        <PageTitle
          end={false}
          start={
            <>
              <Typography display={'inline'} sx={{ mr: 1, ml: 1 }} variant={'h6'}>
                {t('pageTitle.models')}
              </Typography>
              <Typography display={'inline'} variant={'subtitle2'}>
                {t('pageTitle.dictionary')}
              </Typography>
            </>
          }
        />
        <PageContentLayout>
          <TableWithFilters
            columns={DICTIONARY_TABLE_COLUMNS}
            countPage={countPage ?? 1}
            currentPage={page}
            externalOnSelectChange={handleSelectCategory}
            filterName='Categories'
            filterOptions={dictionaryCategories || []}
            loading={dictionariesIsLoading}
            placeholderInput={t('dictionary.searchPlaceholder')}
            rows={tableRows ?? []}
            onChangePage={handlePageChange}
            onRefresh={handleResetCategory}
          />
        </PageContentLayout>
      </Box>
    </PageContext.Provider>
  )
}
