import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FormGenerator, FormInputProps } from '@microservices/wiskey-react-components'

import { GENERATOR_INPUT_TYPE, REGEX } from '@constants'

type NewModelProps = {
  loading: boolean
}

export type NewModelView = {
  code: string
  title: string
}

export type NewModelForm = {
  code: string
  title: string
}

export const NewModel: FC<NewModelProps> = ({ loading }) => {
  const { t } = useTranslation()
  const { code } = useParams()
  const isEdit = !!code

  const inputsConfig: FormInputProps[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: t('viewsCreate.newModel.code.placeholder'),
      label: t('viewsCreate.newModel.code.label'),
      disabled: isEdit,
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      rules: {
        required: true,
        validate: value => value.trim().length !== 0,
      },
      formInputContainerSx: {
        mb: 0.5,
      },
    },
    {
      formInputContainerSx: {
        mb: 0,
      },
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: t('viewsCreate.newModel.title.placeholder'),
      label: t('viewsCreate.newModel.title.label'),
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
  ]

  return <FormGenerator inputs={inputsConfig} loading={loading} mainGridSpacing={0} />
}
