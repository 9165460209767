import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@microservices/wiskey-react-components'
import { Grid } from '@mui/material'

import { ParametersContext } from '@pages/Parameters/Parameters'

export const Buttons = () => {
  const { t } = useTranslation()

  const {
    isEdit,
    onCancelEdit,
    onEditOrSave,
    dirtyVisualParameters,
    methods,
    onClickResetVisualParameters,
  } = useContext(ParametersContext)

  const {
    formState: { isDirty },
  } = methods

  return (
    <Grid container item gap={0.5} justifyContent={'flex-end'}>
      <Button
        disabled={isEdit ? !dirtyVisualParameters && !isDirty : isEdit}
        variant='contained'
        onClick={onEditOrSave}
      >
        {t(`parameters.visual.${isEdit ? 'save' : 'edit'}`)}
      </Button>
      {isEdit && (
        <Button variant='outlined' onClick={onCancelEdit}>
          {t('parameters.visual.cancel')}
        </Button>
      )}
      <Button disabled={!isEdit} variant='outlined' onClick={onClickResetVisualParameters}>
        {t('parameters.resetButton')}
      </Button>
    </Grid>
  )
}
