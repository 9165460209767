import { FC, useMemo } from 'react'
import i18next from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { GENERATOR_INPUT_TYPE } from '@constants'

import { ConfigRowsType } from '../../../../types'

type IntegrationProps = {
  views?: ConfigRowsType[]
  loading: boolean
}

export const Integration: FC<IntegrationProps> = ({ views, loading }) => {
  const viewsOptions = useMemo(
    () =>
      views?.map(view => ({
        id: view.id,
        label: view.title,
      })),
    [views]
  )

  return (
    <Box>
      <FormInput
        multiple
        autocompleteOptions={viewsOptions}
        disabled={!views}
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={i18next.t('label.integrationTitle')}
        loading={loading}
        name={'viewIds'}
        placeholder={i18next.t('placeholder.selectLCs')}
        rules={{
          validate: value => value !== i18next.t('placeholder.selectLCs'),
        }}
      />
    </Box>
  )
}
