import { FC } from 'react'
import { Box } from '@mui/material'

import { AddItem } from './MenuList/AddItem'
import { MenuList } from './MenuList'

type DraggableGroupListProps = {
  isListEdit: boolean
  handleSetEdit: (value: boolean) => void
}

export const DraggableGroupList: FC<DraggableGroupListProps> = ({ isListEdit, handleSetEdit }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <MenuList isEdit={isListEdit} />
      <Box pl={2}>
        <AddItem disabled={!isListEdit} isGlobalEdit={isListEdit} onSetEdit={handleSetEdit} />
      </Box>
    </Box>
  )
}
