import { RefObject, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

import {
  ExternalTextSettings,
  GetTextParamsHandle,
  TextSettingsStateType,
} from '@components/TextSettings'

import { useCreateOrUpdateStyleSettingMutation } from '@redux/api/styleSetting.api'

import { BORDER_TYPE, ROUTES } from '@constants'

import { GeneralStyleSettingDataFormType } from '../types'

type useHandlersParams = {
  methods: UseFormReturn<GeneralStyleSettingDataFormType>
  textSettingsRef: RefObject<GetTextParamsHandle>
  initialTextSettings: TextSettingsStateType | undefined
}

export const useHandlers = ({
  methods,
  textSettingsRef,
  initialTextSettings,
}: useHandlersParams) => {
  const [createOrUpdateStyleSetting, { isLoading: isLoadingCreateOrUpdate }] =
    useCreateOrUpdateStyleSettingMutation()

  const navigate = useNavigate()
  const { id } = useParams()

  const [isDirtyTextSettings, setDirtyTextSettings] = useState<boolean>(false)
  const [skipDirty, setSkipDirty] = useState<boolean>(false)

  const { handleSubmit } = methods

  const handleCancel = () => {
    navigate(ROUTES.CONFIG_STYLE_SETTINGS)
  }

  const handleSave = handleSubmit((styleSettingTitleData: GeneralStyleSettingDataFormType) => {
    const { title, code } = styleSettingTitleData

    if (textSettingsRef.current) {
      const {
        fontFamily,
        fontSize,
        fontColor,
        alignment,
        isBold,
        isItalic,
        bgFontColor,
        borderColor,
      } = textSettingsRef.current.getTextParams()
      setSkipDirty(true)
      createOrUpdateStyleSetting({
        ...(id ? { id } : {}),
        title,
        code,
        background: bgFontColor ? bgFontColor : 'none',
        settingText: {
          fontFamily,
          fontSize: fontSize as string,
          fontColor: fontColor as string,
          alignment,
          isBold: isBold === 'true',
          isItalic: isItalic === 'true',
        },
        settingBorder: {
          // TODO: Убрать!
          type: BORDER_TYPE.ROUNDED_SQUARE,
          color: borderColor as string,
        },
      })
        .unwrap()
        .then(res => {
          if (id) {
            return
          }

          if (!res) {
            handleCancel()
          }

          navigate(`${ROUTES.CONFIG_STYLE_SETTINGS_EDIT}${res.id}`)
        })
        .finally(() => setSkipDirty(false))
        .catch(() => {
          // Ошибка обрабатывается на уровне commonAPI
        })
    }
  })

  const handleTextExternalDirty = (object: ExternalTextSettings) => {
    if (object && initialTextSettings) {
      const isDirty = !isEqual(object, initialTextSettings)

      setDirtyTextSettings(isDirty)
    }
  }

  return {
    data: {
      isLoadingCreateOrUpdate,
    },
    state: { isDirtyTextSettings, skipDirty },
    handlers: {
      handleCancel,
      handleSave,
      handleTextExternalDirty,
    },
  }
}
