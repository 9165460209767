import { useEffect } from 'react'
import { POSTCreateSearchType } from 'src/types'

import { useFetchSearchByObjectAndSAQuery } from '@redux/api'

import { usePrompt } from '@hooks'

import { FavoritesFiltersTableMode } from '../SavedFilterWindow'

import { useHandlers } from './useHandlers'

type UseSaveFilterWindowProps = {
  dialogId: string
  newSearch?: POSTCreateSearchType
  mode: FavoritesFiltersTableMode
  entityObjectCode: string | undefined
  searchAssistantId: number | string | undefined
  onChangeDirty?: (value: boolean) => void
}

export const useSaveFilterWindow = ({
  dialogId,
  mode,
  newSearch,
  entityObjectCode,
  searchAssistantId,
  onChangeDirty,
}: UseSaveFilterWindowProps) => {
  const {
    data: savedSearch,
    isLoading: isLoadingSavedSearch,
    isFetching: isFetchingSavedSearch,
  } = useFetchSearchByObjectAndSAQuery(
    {
      objectCode: entityObjectCode,
      searchAssistantId,
    },
    {
      skip: !entityObjectCode,
    }
  )

  const loadingSavedSearch = isLoadingSavedSearch || isFetchingSavedSearch

  const { handlers, state } = useHandlers({
    dialogId,
    mode,
    newSearch,
    savedSearch: savedSearch || [],
  })

  const { handleSetSearchData, handleSetTableMode, handleSetEditTitle, handleSetSelectedSearch } =
    handlers
  const { isDirty } = state

  usePrompt({ when: isDirty })

  useEffect(() => {
    if (savedSearch) {
      handleSetSearchData(savedSearch)
    }
  }, [savedSearch])

  useEffect(() => {
    onChangeDirty?.(isDirty)
  }, [isDirty])

  useEffect(() => {
    if (mode) {
      handleSetTableMode(mode)
      handleSetEditTitle(newSearch?.title || '')
      handleSetSelectedSearch(null)
    }
  }, [mode, newSearch])

  return {
    state: {
      loadingSavedSearch,
      ...state,
    },
    handlers: {
      ...handlers,
    },
  }
}
