import { FC, ReactNode, useCallback } from 'react'
import { Box } from '@mui/material'

import { FIELD_VALUE_TYPE } from '@constants'

type FormInputWrapperProps = {
  enterDisabled: boolean
  handleSetEnterDisabled: (value: boolean) => void
  valueType: FIELD_VALUE_TYPE | null
  children: ReactNode
}

export const FormInputWrapper: FC<FormInputWrapperProps> = ({
  valueType,
  children,
  enterDisabled,
  handleSetEnterDisabled,
}) => {
  const inputWrapperRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) {
        return
      }

      if (valueType !== FIELD_VALUE_TYPE.DATETIME) {
        return
      }

      const listener = () => {
        if (!enterDisabled) {
          handleSetEnterDisabled(true)
        }
      }

      const maybeInput = document.activeElement as HTMLElement & { type?: string }

      if (
        maybeInput &&
        node !== maybeInput &&
        maybeInput.type !== 'tel' && // tel - тип инпута в DOM для даты
        enterDisabled
      ) {
        setTimeout(() => {
          handleSetEnterDisabled(false)
          node.removeEventListener('click', listener)
        }, 0)

        return
      }

      node.addEventListener('click', listener)

      return node
    },
    [document.activeElement]
  )

  return <Box ref={inputWrapperRef}>{children}</Box>
}
