import { checkDirtyStoredValue } from '@helpers'
import { CriteriaType } from '@constants'

import { POSTStoredSearchFilterType } from '../../../../../types'

import { transformStoredValuesForSaving } from './transformStoredValuesForSaving'

/**
 * преобразование массива с текущими заполннными полями в сохраненные критерии для POST запроса
 * @param filters - массив с текущими  заполненными полями
 * @returns сохраненные критерии со значениями
 */
export const transformFiltersToStoredSearchFilters = (
  filters: CriteriaType[]
): POSTStoredSearchFilterType[] =>
  filters
    .map(criteria => {
      if (
        criteria.storedValues.some(({ value, searchRule }) =>
          checkDirtyStoredValue(value, searchRule)
        )
      ) {
        return {
          codeSearchFilter: criteria.codeCriteria,
          storedValues: transformStoredValuesForSaving(criteria.storedValues),
        }
      }

      return undefined
    })
    .filter(Boolean) as POSTStoredSearchFilterType[]
