import { useMemo } from 'react'

import { createAutocompleteOptions } from '@helpers'
import { ObjectShortDTO } from '@types'

type UseObjectOptionsParams = {
  objects: ObjectShortDTO[]
}

export const useObjectOptions = ({ objects }: UseObjectOptionsParams) => {
  return useMemo(
    () => createAutocompleteOptions(objects, { id: 'code', label: 'title' }),
    [objects]
  )
}
