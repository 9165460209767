import { FC } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Box } from '@mui/material'

import { FORM_DROPPABLE_TYPE } from '@constants'

import { FormTab } from '../../../../../types'

import { DraggableLineOnForm } from './DraggableLineOnForm'

type ContainerTabPanelProps = {
  tab: FormTab
  index: number
  containerId: number
}

export const ContainerTabPanel: FC<ContainerTabPanelProps> = ({ tab, containerId, index }) => {
  const tabId = tab.id

  return (
    <Droppable
      droppableId={`containerId:${containerId}_tabId:${tabId}`}
      type={FORM_DROPPABLE_TYPE.LINES}
    >
      {(provided, snapshot) => {
        return (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            {tab.lines.map((line, lineIndex) => {
              return (
                <DraggableLineOnForm
                  key={line.id}
                  containerId={containerId}
                  index={lineIndex}
                  line={line}
                  tabId={tabId}
                />
              )
            })}
            {provided.placeholder}
          </Box>
        )
      }}
    </Droppable>
  )
}
