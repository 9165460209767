import { ReactNode, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import i18next from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Divider, Typography } from '@mui/material'

import { GANTT_BIND_TYPE, GENERATOR_INPUT_TYPE } from '@constants'
import { AutocompleteOption, SelectOption } from '@types'

import { GANTT_BIND_TYPE_LIST } from '@gantt/components/GanttCreateOrEdit' //todo abstract list
import { getBindTypeOptionsByEnum } from '@gantt/helpers'

import { InputProps, ValueInputFactory } from './ValueInputFactory'

export type TextDict = Partial<Record<GANTT_BIND_TYPE_LIST, string>>

type Props = {
  isFieldsetMarkup?: boolean
  hasDivider?: boolean
  isShownBinding?: boolean
  containerName?: string
  fieldsetTitle?: string
  bindTypeTitle?: string
  blockLabel?: string
  bindTypeOptions?: SelectOption[]
  hintDict?: TextDict
  placeholderDict?: TextDict
  valueOptions?: (AutocompleteOption<string | number> & { default?: boolean })[]
  children?: ReactNode
} & InputProps

export const BindTypeInputs = ({
  isFieldsetMarkup = false,
  hasDivider = false,
  isShownBinding = false,
  containerName,
  bindTypeOptions,
  hintDict,
  fieldsetTitle,
  bindTypeTitle,
  blockLabel,
  placeholderDict,
  valueOptions,
  children,
  ...inputProps
}: Props) => {
  const { watch, setValue } = useFormContext()
  const path = (key: string) => (containerName ? `${containerName}.${key}` : `${key}`)
  const watchBinding: GANTT_BIND_TYPE_LIST = watch(path('bindType'))
  const selectOptions = bindTypeOptions || getBindTypeOptionsByEnum(GANTT_BIND_TYPE)
  const hint = useMemo(() => hintDict && hintDict[watchBinding], [hintDict, watchBinding])

  const placeholder = useMemo(
    () => placeholderDict && placeholderDict[watchBinding],
    [placeholderDict, watchBinding]
  )

  const handleClearOnChangeBinding = useCallback((bindType: string | number) => {
    setValue(path(''), { bindType })
  }, [])

  return (
    <Box component={isFieldsetMarkup ? 'fieldset' : 'div'} mb={2}>
      {fieldsetTitle && <legend>{fieldsetTitle}</legend>}
      {blockLabel && (
        <Typography pb={1} pt={1} variant={'body1'}>
          {blockLabel}
        </Typography>
      )}
      {(Boolean(selectOptions && selectOptions?.length > 1) || isShownBinding) && (
        <FormInput
          inputType={GENERATOR_INPUT_TYPE.SELECT}
          label={bindTypeTitle ? bindTypeTitle : i18next.t('label.bindType')}
          name={path('bindType')}
          selectNoItemsMessage={i18next.t('ganttCreate.common.noItems')}
          selectOptions={selectOptions}
          onChangeSelect={handleClearOnChangeBinding}
        />
      )}
      <ValueInputFactory
        getPath={path}
        hint={hint}
        options={valueOptions}
        placeholder={placeholder}
        type={watchBinding}
        {...inputProps}
      >
        {children}
      </ValueInputFactory>
      {hasDivider && <Divider sx={{ borderWidth: 1, mb: 1, mt: 1 }} />}
    </Box>
  )
}
