import { ConfigRowsType } from '@types'

import { ENTITY_TYPES_REQUEST } from './entity'

const emptyConfigRow: ConfigRowsType = {
  id: 0,
  code: '',
  title: '',
  objectCode: '',
  objectTitle: '',
  type: ENTITY_TYPES_REQUEST.VIEW,
}
const typeFields = [
  ENTITY_TYPES_REQUEST.VIEW,
  ENTITY_TYPES_REQUEST.GANTT_CHART,
  ENTITY_TYPES_REQUEST.MENU,
]

export const selectTypeFields: Array<ConfigRowsType> = typeFields.map((code, id) => ({
  ...emptyConfigRow,
  id,
  code,
}))
